
import config from 'data/config/config';

import fetchHelper from 'src/core/util/FetchHelper';
import * as ProfileService from 'src/core/profile/ProfileService';

import * as LoginPersistence from 'src/core/login/LoginPersistence';
import { getParameterByName } from 'src/core/util/JsTools';

import {
    hasLoggedOut,
    setLoginError,
    setLoginIdentification,
} from 'src/store/actions';


const LOG_PREF = '[LoginService] ';


const QUERY_PARAM_TOKEN = 'token';


export const ERRORS = {
    LOGIN  : 'LOGIN_ERROR',
    NETWORK: 'LOGIN_NETWORK_ERROR',
};


let dispatch;
export function setReduxStore(store) {
    dispatch = store.dispatch;
}



export const isTokenValid = () => (
    LoginPersistence.getToken() !== null
        && (!LoginPersistence.getTokenExpiry() || LoginPersistence.getTokenExpiry() > new Date().getTime())
);


/**
 * Inner-module responsible for AUTO-LOGIN
 */
const AutoLogin = (function() {

    let intervalId;


    function start() {
        if (intervalId) {
            // Already running
            return;
        }
        console.log(LOG_PREF+' autologin: start');
        intervalId = setInterval(relogin, config.LOGIN.autologinDelay * 1000);
    }

    function stop() {
        if (intervalId) {
            console.log(LOG_PREF+' autologin: stop');
            window.clearInterval(intervalId);
            intervalId = null;
        }
    }

    return {
        init : init,
        start: start,
        stop : stop,
    };
})();


export function onUserLogIn() {
    if (typeof config.LOGIN.autologinDelay !== 'number') {
        console.warn(LOG_PREF+`Autologin disabled ('autologinDelay' not specified or not a number)`);
    } else {
        AutoLogin.start();
    }
}


/**
 * Call login API if the user information is available
 */
function relogin() {
    if (isTokenValid()) {
        console.log(LOG_PREF+'Autologin: about to renew token');
        let account = LoginPersistence.getAccount();
        if (account) {
            login(account.username, account.password);
        }
    }
    else {
        logout();
    }
}


export function init() {
    // First look for the token in query parameters
    let userToken = getParameterByName(QUERY_PARAM_TOKEN);
    if (userToken) {
        LoginPersistence.setToken(userToken);
        ProfileService.getProfileFromAPI(userToken, _onApiCallSuccess, _onApiCallFailure);
    } else {
        // else will look in localstorage (previous sessions)
        relogin();
    }
}


function updateToken(value) {
    LoginPersistence.setToken(value);
    dispatch(setLoginIdentification(value));
}


/**
 * Call the API to log the user in
 * @param  {string}   username
 * @param  {string}   password
 */
export function login(username, password) {
    let account = {
        username: username,
        password: password
    };

    LoginPersistence.setAccount(account);

    let opts = {
        method: 'POST',
        body: JSON.stringify(account),
        headers: [{ name: 'Content-Type', value: 'application/json' }],
    };

    fetchHelper(
        config.LOGIN.URL,
        opts,
        true, // isJson
        data => { _onApiCallSuccess(data); },
        _onApiCallFailure,
        false // showModalOnError
    );
}

function _onApiCallSuccess(data) {
    console.log('data', data)
    if (data.success) {
        if (data.profile) {
            ProfileService.updateProfileFromApiData(data);
        }
        updateToken(data.identification || data.token);
        dispatch(setLoginError(null));
    }
    else {
        //dispatch(setLoginIdentification(null));
        updateToken(null);
        dispatch(setLoginError(ERRORS.LOGIN));
    }
}

function _onApiCallFailure(error, message) {
    dispatch(setLoginError(ERRORS.NETWORK));
    if (isTokenValid()) {
        dispatch(setLoginIdentification(LoginPersistence.getToken()));
    }
}



/**
 * Set the user has logged out (no API call)
 */
export function logout() {
    if (LoginPersistence.getToken() === null) {
        // Already logged out
        return;
    }

    updateToken(null);

    let account = LoginPersistence.getAccount();
    if (account) {
        account.password = '';
        LoginPersistence.setAccount(account);
    }

    AutoLogin.stop();

    dispatch(hasLoggedOut());
}


// Ability to manually trigger `logout` in DEV env
if (config.ENV === 'dev') {
    global.logout = logout;
}
