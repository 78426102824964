import React from "react";
import PropTypes from 'prop-types';
import "./index.scss";

const GenericButton = ({buttonClassName, onClick, title, children}) =>
    <button
        className={buttonClassName}
        onClick={onClick}
        title={title} 
    >
        <div className="content">
            {children}
        </div>
    </button>;

GenericButton.defaultProps = {
    buttonClassName: "buttonClassName",
    title: ""
}
GenericButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    buttonClassName: PropTypes.string,
    title: PropTypes.string
}

export default GenericButton;
