
// Libs
import React, { Component }   from 'react';
import PropTypes              from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';

// App modules
import { getBestRessource } from 'src/core/ads/AdManager';
import { getUrl }           from 'src/core/data-and-assets/DataAssetsUtil';
import Url                  from 'src/components/url/Url';
import * as actions         from 'src/store/actions';

import './Intersticiel.scss';


export const COMPONENT_KEY = 'Intersticiel';
export const CONTAINER_DOM_ID = 'intersticiel-container';


const LOG_PREF = '[Intersticiel] ';


class Intersticiel extends Component {

    close() {
        this.props.actions.hideIntersticiel();

        if (typeof this.props.next === 'function') {
            this.props.next();
        }
    }

    /**
     * Automatically CLOSE INTERSTICIEL after configured DELAY
     */
    applyAutoCloseTimeout() {
        let delay;
        if (this.props.appIsBooting && typeof this.props.config.firstTimer === 'number') {
            delay = this.props.config.firstTimer;
        }
        else if (typeof this.props.config.foregroundTimer === 'number') {
            delay = this.props.config.foregroundTimer;
        }

        if (process.env.NODE_ENV !== "production") {
            delay = 1;
        }

        if (delay) {
            if (!this.autoCloseTimeout) {
                this.autoCloseTimeout = window.setTimeout(
                    () => {
                        if (this.props.isDisplayed) {
                            this.props.actions.hideIntersticiel();
                        }
                        this.autoCloseTimeout = null;
                    },
                    delay*1000
                );
            }
        }
    }

    /**
     * Automatically SHOW CLOSE BUTTON after configured DELAY
     */
    applyShowCloseButtonTimeout() {
        if (this.props.config.closeBtnTimer > 0) {

            if (!this.showCloseBtnTimeout) {
                this.showCloseBtnTimeout = window.setTimeout(
                    () => {
                        if (this.props.isDisplayed && !this.props.isCloseButtonVisible) {
                            this.props.actions.showIntersticielCloseButton();
                        }
                        this.showCloseBtnTimeout = null;
                    },
                    this.props.config.closeBtnTimer*1000
                );
            }
        } else {
            this.props.actions.showIntersticielCloseButton();
        }
    }

    /**
     * The point is to create a new object only if value is different,
     * to avoid useless renders
     * @return {object}
     */
    getCloseSubContainerStyle(conf) {
        let bgColor = conf.btnTextBgColor;

        if (!this._closeSubContainerStyle || this._closeSubContainerStyle.backgroundColor !== bgColor) {
            this._closeSubContainerStyle = {
                backgroundColor: bgColor,
            };
        }
        return this._closeSubContainerStyle;
    }

    /**
     * The point is to create a new object only if value is different,
     * to avoid useless renders
     * @return {object}
     */
    getImageStyle(resource) {
        if (!this._imageStyle
                || this._imageStyle.width  !== resource.width
                || this._imageStyle.height !== resource.height) {

            this._imageStyle = {
                width : resource.width,
                height: resource.height,
            };
        }
        return this._imageStyle;
    }

    render() {
        console.log(LOG_PREF+'render');

        if (this.props.isDisplayed !== true
                || this.props.config === null || typeof this.props.config !== 'object'
                || this.props.files === null || typeof this.props.files !== 'object') {
            return null;
        }
        else {
            /* this.props.config:

                ".interstitiels": {
                    x  "name": "cyient",
                    x  "lang": [],
                    x  "bgcolor": "#030405",
                    x  "hasCloseBtn": true,
                    x  "btnTextBgColor": "rgba(255, 255, 255, .4)",
                    x  "btnTextColor": "#FFFFFF",
                    x  "link_fr": "",
                    x  "link_en": "",
                    -  "target": "_blank",
                    x  "first": true,
                    x  "firstTimer": 5,
                      "foreground": true,
                      "foregroundFrequency": 5,
                    x  "foregroundTimer": 2,
                    x  "closeBtnTimer": 3,
                },
            */

            if (this.props.appIsBooting && this.props.config.first !== true) {
                console.log(LOG_PREF+'No intersticiel on app startup');
                return null;
            }

            let files;
            if (Array.isArray(this.props.config.lang)
                    && this.props.config.lang.length
                    && this.props.files[this.props.lang]) {

                files = this.props.files[this.props.lang];
            } else {
                files = this.props.files;
            }

            // Get image suited to window width and height
            let resource = getBestRessource(
                                files,
                                document.documentElement.clientWidth,
                                document.documentElement.clientHeight);
            if (!resource) {
                console.error(LOG_PREF+'Cannot find a suitable resource from dimensions '
                    +document.documentElement.clientWidth+'x'+document.documentElement.clientHeight);
                return null;
            }

            let style = {};
            if (this.props.config.bgcolor) {
                style.backgroundColor = this.props.config.bgcolor;
            }

            // Automatically close intersticiel after a delay
            this.applyAutoCloseTimeout();

            // Show close button after a delay
            this.applyShowCloseButtonTimeout();


            let closeContainerStyle = {
                opacity: this.props.isCloseButtonVisible ? 1 : 0,
                color  : this.props.config.btnTextColor,
            };

            let imgContainerProps = {},
                link = this.props.config[`link_${this.props.lang}`];
            if (link) {
                imgContainerProps.href = this.props.config[`link_${this.props.lang}`];
            }

            return (
                <div style={style} >

                        { this.props.config.hasCloseBtn &&
                            <div className="interstitiel-close-container"
                                 style={closeContainerStyle}
                                 onClick={() => {
                                    // Check prop as visiblity is handled via opacity only (to have transition)
                                    if (this.props.isCloseButtonVisible) {
                                        this.close();
                                    }
                                }} >

                                <div className="interstitiel-close-sub-container" style={this.getCloseSubContainerStyle(this.props.config)}>
                                    <div className="interstitiel-close-label">{this.props.labels.intersticiel.closeLabel}</div>

                                    <div className="interstitiel-close-btn fa fa-chevron-right" />
                                </div>
                            </div>
                        }

                        <Url callback={actions.linkClicked} className="img-container" {...imgContainerProps}>
                            <img src={getUrl(resource.file)}
                                 alt=""
                                 style={this.getImageStyle(resource)} />
                        </Url>
                </div>
            );
        }
    }
}

Intersticiel.propTypes = {
    isDisplayed: PropTypes.bool.isRequired,
    isCloseButtonVisible: PropTypes.bool.isRequired,
    labels      : PropTypes.object.isRequired,
    lang        : PropTypes.string.isRequired,
    config      : PropTypes.object,
    files       : PropTypes.object,
    actions     : PropTypes.object.isRequired,
    next        : PropTypes.func,
    appIsBooting: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => state[COMPONENT_KEY];
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Intersticiel);
