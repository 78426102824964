
import { UPDATE_PROFILE } from 'src/store/actionTypes';
import { setProfile } from 'src/core/profile/ProfileService';

export default ({ dispatch, getState }) => next => action => {

    switch (action.type) {

        case UPDATE_PROFILE:
            setProfile(action.profile);
            break;

        default:
    }
    return next(action);
}