
// Libs
import React        from 'react';
import PropTypes    from 'prop-types';
import { debounce } from 'lodash';

import './SideIndex.scss';


const INDEX_ELEMENT_CLASSNAME = 'list-index-element';


const SideIndex = props => {

    let scrollTopValues;

    const getScrollTopValues = () => {
        scrollTopValues = {};

        // Read all separators offset top value
        Object.keys(props.separatorEls).forEach((indexKey) => {
            if (props.separatorEls[indexKey]) {
                scrollTopValues[indexKey] = props.separatorEls[indexKey].offsetTop - 5;
            }
        });
    };


    const goTo = (indexKey) => {
        if (!scrollTopValues) {
            getScrollTopValues();
        }
        props.scrollTo(scrollTopValues[indexKey]);
    };


    /**
     * Mouse event attached to each <li>
     * @param  {object} e
     */
    const onLiSelected = (e) => {
        let indexKey = e.target.dataset.indexkey;
        if (indexKey) {
            goTo(indexKey);
        }
    }


    /**
     * Handle touchmove event maximum once every 10ms
     * @param {object} e: event
     */
    const touchMoveHandler = debounce((e) => {

        // TODO: handle pointerEvents
        let evtPos = e.touches[0];

        // @see http://caniuse.com/#feat=element-from-point
        let el = document.elementFromPoint(evtPos.pageX, evtPos.pageY);

        if (el && el.classList.contains(INDEX_ELEMENT_CLASSNAME)) {
            goTo(el.dataset.indexkey);
        }
    }, 10);

    /**
     * Handle touchmove event on <ul>
     * @param  {object} e
     */
    const onTouchMove = (e) => {
        // @see https://facebook.github.io/react/docs/events.html
        e.persist();
        e.preventDefault();

        touchMoveHandler(e);
    };

    const getLabel = typeof props.getLabel === 'function' ? props.getLabel : value => value;

    return (
        <ul className="side-index" style={{ height: props.height }} onTouchMove={onTouchMove}>
        {
            (props.indexes || []).map(indexKey => (
                <li key={indexKey}
                    className={INDEX_ELEMENT_CLASSNAME}
                    onMouseEnter={onLiSelected}
                    onClick={onLiSelected}
                    data-indexkey={indexKey}
                    dangerouslySetInnerHTML={{ __html: getLabel(indexKey) }}
                />
            ))
        }
        </ul>
    );
};

SideIndex.propTypes = {
    indexes     : PropTypes.array.isRequired,
    separatorEls: PropTypes.object.isRequired,
    getLabel    : PropTypes.func,
    scrollTo    : PropTypes.func.isRequired,
    height      : PropTypes.number,
};

export default SideIndex;