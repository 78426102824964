import _defineProperty from "/Users/matthieugayon/Documents/development/mobilespot/mobigeo-sdk-pisc2019/app-react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { DATA_TYPE_AIRCRAFT_CATEGORIES, DATA_TYPE_AIRCRAFTS, DATA_TYPE_ANIMATIONS, DATA_TYPE_BRAND_CATEGORIES, DATA_TYPE_BRANDS, DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EVENTS, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_EXHIBITORS, DATA_TYPE_FLIGHTS_SCHEDULE, DATA_TYPE_NEWPRODUCT_CATEGORIES, DATA_TYPE_NEWPRODUCTS, DATA_TYPE_RESTAURANT_CATEGORIES, DATA_TYPE_RESTAURANTS, DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_SERVICES, DATA_TYPE_SPEAKERS, DATA_TYPE_PLACES, CATEGORIES_MAPPING, getSpeakerTitle, getSpeakerSubtitle } from './dataConfig';
import { AGENDA_PAGE_KEY, AIRCRAFT_PAGE_KEY, EVENT_PAGE_KEY, EXHIBITOR_PAGE_KEY, EXPERIENCE_ITEM_PAGE_KEY, FEDLIST_PAGE_KEY, LIST_PAGE_KEY, MAP_PAGE_KEY, NEWPRODUCT_PAGE_KEY, RESTAURANT_PAGE_KEY, SERVICE_PAGE_KEY, SPEAKER_PAGE_KEY } from 'src/pages/pagesKeys';
import { DATA_TYPE_TO_PAGE_KEY } from 'src/pages/dataToPageMapping'; // import CategoriesHack from './CategoriesHack';

import { formatDate, formatTime } from 'src/core/Lang';
import TwoColumnsModeManager from 'src/core/navigation/TwoColumnsModeManager';
import { openUrl } from 'src/core/util/JsTools';
import { sendPageView } from 'src/core/analytics/GoogleAnalyticsHelper';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { applyRedirect } from 'src/core/navigation/Router';
var LOG_PREF = '[listConfig] ';
/**
 * Style should be defined in variables.scss but sometimes it is set from JS
 */

export var ROW_BACKGROUND_COLOR_ON_CLICK = '#efefef';
/**
 * List are very simple (no fav-icon, no text-minor, ...) if row count is above (performance in mind)
 * @type {Number}
 */

export var FAST_AND_UGLY_IF_ABOVE = 4000;
/**
 * Display alphabetical index if row count if above
 * @type {Number}
 */

export var ALPHABETICAL_IF_ABOVE = 50;
/**
 * e.g for search results
 */

export var HIGHLIGHTED_PROPS = ['text', 'textMinor'];
export var DISABLE_FAVORITE_ICON = false;
/**
 * TypeBar is automatically closed when items count is higher than this
 * @see DataListDialog
 * @type {Number}
 */

export var DIALOG_TYPEBAR_CLOSED_IF_COUNT_ABOVE = 5;

function hasPlaces(row) {
  return Array.isArray(row.places) && row.places.length > 0;
}

function serviceHasContent(service) {
  return !!(service.description || service.phone || service.website || service.email);
}
/**
 * Ability to modify the side of the alphabetical index
 * @return {string}
 */


export var getSideIndexPosition = function getSideIndexPosition() {
  return (// On desktop with app displayed in two columns, put the index on the left side
    // to prevent list scrollTop to be modified when cursor moves from left column to right column.
    TwoColumnsModeManager.isEnabled() && global.isCordovaContext === false ? 'left' : 'right'
  );
};
/**
 * Define here the `props` (~ attributes) an element of a list (<ListElement />) will receive.
 * Every helper function receives as parameter the full object
 */

export var elementPropsGetters = function elementPropsGetters(dataType, pageKey) {
  var _ref, _attributes;

  switch (dataType) {
    case DATA_TYPE_PLACES:
      return {
        id: function id(place) {
          return String(place.id);
        },
        text: function text(place) {
          return String(place.memberTitle);
        },
        textMinor: function textMinor(place) {
          return String(place.label);
        },
        'data-member-type': function dataMemberType(place) {
          return String(place.memberType);
        },
        'data-member-id': function dataMemberId(place) {
          return String(place.memberId);
        },
        'data-member-original-id': function dataMemberOriginalId(place) {
          return String(place.memberOriginalId);
        }
      };

    case DATA_TYPE_ANIMATIONS:
      return {
        id: function id(anim) {
          return String(anim.id);
        },
        text: function text(anim) {
          return String(anim.title);
        },
        'data-id': function dataId(anim) {
          return String(anim.id);
        },
        'data-original-id': function dataOriginalId(anim) {
          return String(anim.original_id);
        },
        'data-contextual-place-id': function dataContextualPlaceId(anim) {
          return String(anim.contextualPlaceId);
        }
      };

    case DATA_TYPE_BRANDS:
      return {
        id: function id(brand) {
          return String(brand.id);
        },
        text: function text(brand) {
          return String(brand.title);
        },
        textMinor: function textMinor(brand) {
          return brand.lump.subtitle;
        },
        'data-id': function dataId(brand) {
          return String(brand.id);
        },
        'data-original-id': function dataOriginalId(brand) {
          return String(brand.original_id);
        }
      };

    case DATA_TYPE_SERVICES:
      return {
        id: function id(service) {
          return String(service.id);
        },
        text: function text(service) {
          return String(service.title);
        },
        textMinor: function textMinor(service) {
          return service.lump.subtitle;
        },
        // image: service => service.logo_file_name,
        'data-link': function dataLink(service) {
          return service.lump && service.lump.link || null;
        },
        'data-original-id': function dataOriginalId(service) {
          return String(service.original_id);
        },
        'data-contextual-place-id': function dataContextualPlaceId(service) {
          return service.contextualPlaceId;
        },
        'data-has-places': function dataHasPlaces(service) {
          return hasPlaces(service);
        },
        'data-no-content': function dataNoContent(service) {
          return serviceHasContent(service) !== true;
        },
        isClickable: function isClickable(service) {
          return hasPlaces(service) || serviceHasContent(service);
        } // default: true

      };

    case DATA_TYPE_EXHIBITORS:
      return {
        id: function id(exh) {
          return String(exh.id);
        },
        'data-id': function dataId(exh) {
          return String(exh.id);
        },
        'data-original-id': function dataOriginalId(exh) {
          return String(exh.original_id);
        },
        'data-contextual-place-id': function dataContextualPlaceId(exh) {
          return exh.contextualPlaceId;
        },
        text: function text(exh) {
          return String(exh.title);
        },
        //textMinor: exh => exh.placeLabel,
        textStyle: function textStyle(exh) {
          return exh.lump && exh.lump.color ? {
            color: exh.lump.color
          } : null;
        },
        className: function className(exh) {
          var classes = [];

          if (exh.lump) {
            if (exh.lump.bold === '1') {
              classes.push('emphasised');
            }
          } // to be completed


          return classes.join(' ');
        },
        image: function image(exh) {
          return exh.lump && exh.lump.logolist ? exh.logo_file_name : null;
        }
      };

    case DATA_TYPE_AIRCRAFTS:
      return {
        id: function id(aircraft) {
          return String(aircraft.id);
        },
        text: function text(aircraft) {
          return String(aircraft.title);
        },
        'data-id': function dataId(aircraft) {
          return String(aircraft.id);
        },
        'data-original-id': function dataOriginalId(aircraft) {
          return String(aircraft.original_id);
        },
        'data-contextual-place-id': function dataContextualPlaceId(aircraft) {
          return String(aircraft.contextualPlaceId);
        }
      };

    case DATA_TYPE_RESTAURANTS:
      return {
        id: function id(restau) {
          return String(restau.id);
        },
        text: function text(restau) {
          return String(restau.title);
        },
        textMinor: function textMinor(restau) {
          return restau.lump.subtitle;
        },
        'data-id': function dataId(restau) {
          return String(restau.id);
        }
      };

    case DATA_TYPE_SPEAKERS:
      return _ref = {
        text: function text(speaker) {
          return getSpeakerTitle(speaker);
        },
        textMinor: function textMinor(speaker) {
          return getSpeakerSubtitle(speaker);
        }
      }, _defineProperty(_ref, 'data-' + DATA_TYPE_EVENTS, function (speaker) {
        return speaker.lump[DATA_TYPE_EVENTS];
      }), _defineProperty(_ref, "image", function image(speaker) {
        return speaker.photo;
      }), _defineProperty(_ref, "isClickable", function isClickable(speaker) {
        return pageKey !== EVENT_PAGE_KEY && Array.isArray(speaker.lump[DATA_TYPE_EVENTS]) && speaker.lump[DATA_TYPE_EVENTS].length > 0;
      }), _ref;

    case DATA_TYPE_EVENTS:
      return {
        id: function id(event) {
          return String(event.id);
        },
        'data-id': function dataId(event) {
          return String(event.id);
        },
        'data-original-id': function dataOriginalId(event) {
          return String(event.original_id);
        },
        'data-contextual-place-id': function dataContextualPlaceId(event) {
          return String(event.contextualPlaceId);
        },
        event: function event(_event) {
          return {
            start_date: _event.start_date,
            start_time: _event.start_time,
            end_date: _event.end_date,
            end_time: _event.end_time
          };
        },
        text: function text(event) {
          return String(event.title);
        },
        textMinor: function textMinor(event) {
          return event.place !== null && typeof event.place === 'object' ? String(event.place.label) : null;
        }
      };

    case DATA_TYPE_FLIGHTS_SCHEDULE:
      return {
        id: getProp('id'),
        'data-id': getProp('id'),
        text: function text(doc) {
          return doc.aircraft && doc.aircraft.title || 'Unknown aircraft';
        },
        event: function event(doc) {
          var start = doc.time_start.split(':');
          return {
            start_date: Number(doc.date),
            start_time: start.length > 2 ? start.slice(0, 2).join(':') : start.join(':'),
            end_date: Number(doc.date),
            end_time: ''
          };
        },
        textMinor: function textMinor(doc) {
          return doc.time_end;
        },
        // aircraft desc is very long
        isClickable: function isClickable(doc) {
          return doc.hasAircraft;
        }
      };

    case DATA_TYPE_NEWPRODUCTS:
      return {
        text: function text(np) {
          return String(np.title);
        },
        textMinor: function textMinor(np) {
          return np.exhibitor ? String(np.exhibitor.title) : null;
        },
        image: function image(np) {
          return np.photo_file_name;
        }
      };

    case DATA_TYPE_AIRCRAFT_CATEGORIES:
    case DATA_TYPE_BRAND_CATEGORIES:
    case DATA_TYPE_EVENT_CATEGORIES:
    case DATA_TYPE_EXHIBITOR_CATEGORIES:
    case DATA_TYPE_RESTAURANT_CATEGORIES:
    case DATA_TYPE_SERVICE_CATEGORIES:
    case DATA_TYPE_NEWPRODUCT_CATEGORIES:
      var childrenType = CATEGORIES_MAPPING[dataType];
      var attributes = (_attributes = {
        counter: function counter(cat) {
          return cat.counter;
        },
        text: function text(cat) {
          return String(cat.title);
        },
        image: function image(cat) {
          return cat.lump && cat.lump.logo;
        }
      }, _defineProperty(_attributes, 'data-children-length', function dataChildrenLength(cat) {
        // Count children (e.g exhibitors, not subcategories)
        var children = cat.lump[childrenType];
        return Array.isArray(children) ? children.length : 0;
      }), _defineProperty(_attributes, 'data-unique-child-id', function dataUniqueChildId(cat) {
        var children = cat.lump[childrenType];
        return Array.isArray(children) && children.length === 1 ? children[0] : null;
      }), _defineProperty(_attributes, 'data-redirect', function dataRedirect(cat) {
        if (cat.lump && cat.lump.link) {
          return JSON.stringify(cat.lump.link);
        }

        return null;
      }), _defineProperty(_attributes, 'data-locate-all', function dataLocateAll(cat) {
        return cat.lump.locateAll === true;
      }), _attributes); // Special is-all category

      attributes['data-is-all'] = function (cat) {
        return cat.is_all === 1 ? true : null;
      };

      attributes.textMinor = function (cat) {
        return cat.lump.subtitle || null;
      };

      return attributes;

    default:
      console.error(LOG_PREF + 'No helpers specified for type `' + dataType + '`');
  }
};

function _showPoiOnMap(liEl, pageKey, actions, labels) {
  actions.showOnePoiOnMap({
    type: liEl.dataset.type,
    id: liEl.dataset.id,
    originalId: liEl.dataset.originalId
  });
}
/**
 * Define here what should happen when a list entry (<ListElements />) is clicked
 * @param {DOM element} liEl
 * @param {object} actions: redux actions (connected to page instance)
 * @param {object} labels
 * @param {string} pageKey (optional related page identifier)
 */


export var onClickOnLi = function onClickOnLi(liEl, actions, labels, pageKey) {
  var _liEl$dataset = liEl.dataset,
      id = _liEl$dataset.id,
      type = _liEl$dataset.type,
      originalId = _liEl$dataset.originalId;

  switch (type) {
    case DATA_TYPE_PLACES:
      var searchType = liEl.parentNode.dataset.searchType,
          textMinor = liEl.querySelector('.text-minor');
      actions.searchedPlaceSelected(searchType, {
        placeId: parseInt(id, 10),
        type: liEl.dataset.memberType,
        originalId: liEl.dataset.memberOriginalId,
        text: liEl.getAttribute('title'),
        textMinor: textMinor ? textMinor.textContent : null
      });
      break;

    case DATA_TYPE_EXHIBITORS:
      actions.navigate(EXHIBITOR_PAGE_KEY, {
        id: parseInt(id, 10)
      });
      break;

    case DATA_TYPE_EVENTS:
      actions.navigate(EVENT_PAGE_KEY, {
        id: parseInt(id, 10)
      });
      break;
    // case DATA_TYPE_NEWPRODUCTS:
    //     actions.navigate(NEWPRODUCT_PAGE_KEY, { id: parseInt(id, 10) });
    //     break;

    case DATA_TYPE_SPEAKERS:
      actions.navigate(SPEAKER_PAGE_KEY, {
        id: parseInt(id, 10)
      });
      break;
    // case DATA_TYPE_SERVICES:
    //     if (liEl.dataset.link) {
    //         const link = liEl.dataset.link
    //         if (link.indexOf('http://') > -1 || link.indexOf('https://') > -1) {
    //             openUrl(getUrl(
    //                 liEl.dataset.link,
    //                 global.isCordovaContext ? false : true, // use http
    //                 true // absolute
    //             ));
    //             sendPageView(liEl.dataset.link);
    //         }
    //         else if (link.indexOf('service_categories') > -1) {
    //             const categoryId = link.split('/')[1]
    //             actions.navigate(LIST_PAGE_KEY, {
    //                 locateAll: false,
    //                 inputs: [ CategoriesHack.NATIONAL_COMITEES ]
    //             });
    //         }
    //     }
    //     else if (liEl.dataset.noContent === 'true' && liEl.dataset.hasPlaces === 'true') {
    //         _showPoiOnMap(liEl, pageKey, actions, labels);
    //     }
    //     else {
    //         actions.navigate(SERVICE_PAGE_KEY, { id: parseInt(id, 10), });
    //     }
    //     break;

    case DATA_TYPE_EVENT_CATEGORIES:
    case DATA_TYPE_EXHIBITOR_CATEGORIES:
      //case DATA_TYPE_SERVICE_CATEGORIES:
      //case DATA_TYPE_NEWPRODUCT_CATEGORIES:
      // Special case: redirection
      if (liEl.dataset.redirect) {
        var params = JSON.parse(liEl.dataset.redirect);

        if (params.page && params.page === 'map') {
          var dataType = params.type,
              originalIds = params.originalIds;

          if (!dataType || !originalIds || Array.isArray(originalIds) !== true) {
            console.error('Missing lump.link information to show POI.', dataType, originalIds);
            return;
          }

          actions.showAllPoisOnMap(_defineProperty({}, dataType, originalIds.map(function (originalId) {
            return {
              id: originalId
            };
          })));
        } else {
          actions.navigate(LIST_PAGE_KEY, {
            locateAll: false,
            inputs: [{
              dataType: params.dataType
            }]
          });
        }

        return;
      }

      var childrenLength = parseInt(liEl.dataset.childrenLength, 10),
          childrenType = CATEGORIES_MAPPING[type];

      if (childrenLength > 0) {
        var uniqueChildId = liEl.dataset.uniqueChildId ? parseInt(liEl.dataset.uniqueChildId, 10) : null;

        if (uniqueChildId) {
          // Directly go the detail
          actions.navigate(DATA_TYPE_TO_PAGE_KEY[childrenType], {
            id: uniqueChildId
          });
        } else {
          // Display children from this category
          if (type === DATA_TYPE_EVENT_CATEGORIES) {
            // Display agenda instead of a list
            actions.navigate(AGENDA_PAGE_KEY, {
              input: {
                parentId: id,
                parentType: type,
                dataType: DATA_TYPE_EVENTS
              }
            });
          } else {
            actions.navigate(LIST_PAGE_KEY, {
              locateAll: liEl.dataset.locateAll === 'true',
              inputs: [{
                parentId: parseInt(id, 10),
                parentType: type,
                dataType: childrenType
              }]
            });
          }
        }
      } else {
        // Special 'is_all' category
        if (liEl.dataset.isAll === 'true') {
          if (type === DATA_TYPE_EVENT_CATEGORIES) {
            actions.navigate(AGENDA_PAGE_KEY, {
              input: {
                dataType: DATA_TYPE_EVENTS
              }
            });
          } else {
            actions.navigate(LIST_PAGE_KEY, {
              locateAll: false,
              inputs: [{
                dataType: childrenType
              }]
            });
          }
        } else {
          // Display sub-categories
          actions.navigate(LIST_PAGE_KEY, {
            locateAll: false,
            inputs: [{
              id: parseInt(id, 10),
              dataType: type
            }]
          });
        }
      }

      break;

    default:
      console.error(LOG_PREF + 'Nothing defined for click on type `' + type + '`');
  }
};