
import config from 'data/config/config';
import {
    get as getSearchConfig,
    DATATYPES_WITH_PLACES,
} from 'data/config/searchConfig';

import STATUS from 'src/store/fetchStatuses';

import { get as getProfile } from 'src/core/Profile';
import unicodeNormalizer from 'src/core/util/unicodeNormalizer';

import * as Db from 'src/core/data-and-assets/Db';
import { completeData } from 'src/core/query/Query';


const LOG_PREF = '[Search] ';


function _search(tables, value) {

    let searchResults = {},
        totalCount = 0;

    if (typeof value !== 'string' && value !== null && typeof value !== 'undefined') {
        console.error(LOG_PREF+'Wrong argument type supplied to \'search\': '+typeof value, value);
    }
    else {
        const normalizedInput = unicodeNormalizer(value);

        let searchConfig = getSearchConfig(getProfile());

        // Search through all configured data types
        tables.forEach(dataType => {
            try {
                let data = Db.getSortedAndTransformedData()[dataType],
                    getSearchedField = searchConfig[dataType];

                searchResults[dataType] = data.filter(item => (
                    unicodeNormalizer(getSearchedField(item)).indexOf(normalizedInput) !== -1
                ));

                // Sort results
                searchResults[dataType] = Db.sortItems(searchResults[dataType], dataType);

                totalCount += searchResults[dataType].length;

            } catch (e) {
                // @see https://github.com/olivernn/lunr.js/issues/68?_pjax=%23js-repo-pjax-container#issuecomment-280298089
                console.error(`Failed to search through ${dataType} index`, e);
            }
        });
    }

    return {
        results: searchResults,
        count: totalCount,
    };
}




/**
 * Search through all SEARCHABLE
 * @param  {string} value
 * @return {object}
 */
export function search(value) {
    let searchConfig = getSearchConfig(getProfile());

    let data = _search(Object.keys(searchConfig), value);
    return {
        status    : STATUS.FETCHED,
        data      : data.results,
        totalCount: data.count,
    };
};

if (config.ENV === 'dev') {
    global.search = search;
}



/**
 * Search through data types that have at least one place
 * NB: places data is fetched too
 * @param  {string} value
 * @return {object}
 */
export function searchPlaces(value) {
    let results = _search(DATATYPES_WITH_PLACES, value).results,
        count = 0;

    Object.keys(results).forEach(dataType => {

        // Keep only items with places
        let filtered = results[dataType].filter(item => item.places && item.places.length > 0);

        // Fetch places
        filtered = filtered.map(item => completeData(item, dataType, [ 'places' ]));

        filtered.forEach(item => {
            // Synoptic hack: remove synoptic places only if there is at least one 'real' place
            if (item.references.places.length > 1) {
                item.references.places = item.references.places.filter(place => place.tag.startsWith('Syno') === false);
            }
        });

        results[dataType] = filtered;
        count += filtered.length;
    });

    return {
        status    : STATUS.FETCHED,
        data      : results,
        totalCount: count,
    };
};

if (config.ENV === 'dev') {
    global.searchPlaces = searchPlaces;
}
