
import ons from 'onsenui';

// Conf
import { DISPLAYABLE_TYPES } from 'data/config/mapConfig';

import { MOBIGEO_USED_TABLES } from 'data/config/dataConfig';
import { MAP_PAGE_KEY } from 'src/pages/pagesKeys';

import { get as getLabels } from 'src/core/Lang';
import { isActive } from 'src/core/navigation/Router';
import * as Query from 'src/core/query/Query';
import MapContext from './MapContext';
import { USER_POSITION } from './mapUtil';
import { convertDataTypeToMobigeoType } from 'src/core/data-and-assets/Db';

import {
    DATA_UPDATED,
    MAP_ASSET_UPDATED,
    MAP_LOADED,
    // MAP_USER_LOCATED,
    // MAP_USER_UNLOCATED,
    MAP_ZOOM_ON_ZONE,
    NAVIGATE,
    NAVIGATE_BACK,
    SHOW_MAP_ITINERARY,
} from 'src/store/actionTypes';

import {
    itineraryApiCalled,
    mapReload,
    navigate,
} from 'src/store/actions';


// let hasUserAPosition = false;
let queuedActions = [],
    mapReloadConfirmDisplayed = false,
    reloadOnPageChange = false;


function _executeOrQueue(dispatch, _func) {
    if (MapContext.isLoaded() !== true) {
        // If Map page is not mounted yet, dispatch a navigate action to Map page
        // When MAP_LOADED is broadcasted, empty actions queue
        queuedActions.push(_func);
    } else {
        _func();
    }

    // Redirect to map page only if not already active
    if (isActive(MAP_PAGE_KEY) !== true) {
        dispatch(navigate(MAP_PAGE_KEY));
    }
}

/**
 * Check every POI before displaying them
 * @param  {array} pois
 * @param  {object} options (optional)
 * @param  {function} dispatch
 */
function _parseThenShowPOIs(pois, options, dispatch) {;
    let entries = [];
    Object.keys(pois).forEach(dataType => {

        // Is datatype displayable on MobiGeo ?
        if (DISPLAYABLE_TYPES.indexOf(dataType) !== -1) {
            if (Array.isArray(pois[dataType]) && pois[dataType].length > 0) {

                pois[dataType].forEach(poiCriteria => {
                    if (poiCriteria) {

                        let member;
                        if (typeof poiCriteria.id !== 'undefined') {
                            member = Query.get(poiCriteria.id, dataType, [ 'places' ]);
                        }
                        // using client id (originalId) - case of push actions (e.g pushwoosh notification)
                        else if (typeof poiCriteria.originalId !== 'undefined') {
                            member = Query.find(
                                [ item => item.original_id === poiCriteria.originalId ],
                                dataType,
                                { places: true }, // additional data to retrieve
                                true); // find one
                        }
                        if (member) {
                            entries.push({
                                id  : member.original_id,
                                type: dataType,
                            });
                        }
                    }
                });
            }
        }
    });

    if (entries.length > 0) {
        _showPOI(entries, options, dispatch);
    }
};

/**
 * Display a single POI
 * @param  {array} pois
 * @param  {object} options (optional)
 * @param  {function} dispatch
 */
function _showPOI(pois, options, dispatch) {
    _executeOrQueue(dispatch, () => {
        window.MobiGeo.Map.POI.clear();

        var poisArray = (Array.isArray(pois) ? pois : [pois]).map(poi => {
            if (poi.type.charCodeAt(0) > 96) {
                // if first letter is a lower case, data type must be converted
                poi.type = convertDataTypeToMobigeoType(poi.type);
            }
            return poi;
        });

        window.MobiGeo.Map.POI.show(poisArray, options);
    });
}

/**
 * Focus on a zone
 * @param  {number} zone
 * @param  {string} floor
 * @param  {function} dispatch
 */
function _zoomOnZone(zone, floor, dispatch) {
    _executeOrQueue(dispatch, () => {
        // To avoid confusion, remove POI icons first
        window.MobiGeo.Map.POI.clear();

        window.MobiGeo.Map.zoomOnZone(zone, floor);
    });
}



/**
 * Detect if data update impacts on MapPage
 * @param  {array} updatedTables
 * @return {boolean}
 */
function hasATableBeenUpdated(updatedTables) {

    // if value is null, we safely choose to consider that data could have been updated
    if (!updatedTables) {
        return true;
    }
    let match = false;

    for (let i=0; match === false && i<updatedTables.length; i++) {
        match = MOBIGEO_USED_TABLES.indexOf(updatedTables[i]) !== -1;
    }
    return match;
}


function _askConfirmIfNeededBeforeRestart(dispatch) {
    if (MapContext.isLoaded() === true) {
        if (isActive(MAP_PAGE_KEY) === false) {
            dispatch(mapReload());
        } else {
            // Ask confirmation to reload the map now
            if (!mapReloadConfirmDisplayed) {
                mapReloadConfirmDisplayed = true;

                ons.notification.confirm(getLabels().map.shouldReload, {
                    title: getLabels().map.title,
                    buttonLabels: [ getLabels().common.no, getLabels().common.yes ],
                    callback: status => {
                        mapReloadConfirmDisplayed = false;
                        if (status) {
                            dispatch(mapReload());
                        } else {
                            reloadOnPageChange = true;
                        }
                    },
                });
            }
        }
    }
}

let lastNavigateBackTimestamp;

export default ({ dispatch, getState }) => next => action => {

    switch (action.type) {


        case MAP_LOADED:
            while (queuedActions.length) {
                queuedActions.pop()();
            }
            break;


        case DATA_UPDATED:
            if (hasATableBeenUpdated(action.tables)) {
                if (MapContext.isLoaded()) {
                    // MobiGeo is concerned by the data update
                    console.log('Map needs to be reloaded due to data update.', action.tables);
                    _askConfirmIfNeededBeforeRestart(dispatch);
                } else {
                    dispatch(mapReload());
                }
            }
            break;

        case MAP_ASSET_UPDATED:
            if (global.isCordovaContext) {
                _askConfirmIfNeededBeforeRestart(dispatch);
            }
            break;


        // After an update involving map data/assets (see MAP_ASSET_UPDATED), if user is on MapPage
        // then confirmation is asked for immediate map reload.
        //
        // If the user refuses:
        //  - `reloadOnPageChange` is set to true
        //  - on first page navigation, map is reloaded in background
        case NAVIGATE:
            if (action.pageKey === MAP_PAGE_KEY
                    && action.options
                    // Ignore if navigation comes from a 'back' (to not apply again POI options)
                    && (!lastNavigateBackTimestamp || new Date().getTime() - lastNavigateBackTimestamp > 300)) {

                // Show one POI
                if (action.options.poi) {
                    _parseThenShowPOIs({
                        [action.options.poi.type]: [ action.options.poi ]
                    }, null, dispatch);
                }
                // Show several POIs
                if (action.options.pois) {
                    _parseThenShowPOIs(action.options.pois, null, dispatch);
                }
            }
            // fall through
        case NAVIGATE_BACK:
            lastNavigateBackTimestamp = new Date().getTime();

            if (reloadOnPageChange) {
                reloadOnPageChange = false;
                dispatch(mapReload());
            }
            break;


        // case MAP_USER_LOCATED:
        //     hasUserAPosition = true;
        //     break;

        // case MAP_USER_UNLOCATED:
        //     hasUserAPosition = false;
        //     break;


        case MAP_ZOOM_ON_ZONE:
            _zoomOnZone(action.zone, action.floor, dispatch);
            break;


        case SHOW_MAP_ITINERARY:
            let _start = action.start,
                _dest  = action.dest;

            _dest.type = convertDataTypeToMobigeoType(_dest.type);

            if (_start.type === USER_POSITION) {
                // User position to POI
                window.MobiGeo.Map.Route.goTo(_dest, null); // no callback, handle error events in MapPage
            } else {
                // POI to POI
                _start.type = convertDataTypeToMobigeoType(_start.type);
                window.MobiGeo.Map.Route.display(_start, _dest, null); // no callback, handle error events in MapPage
            }

            dispatch(itineraryApiCalled(_start, _dest));
            break;


        default:
    }
    return next(action);
};
