
import { debounce } from 'lodash';
import ons from 'onsenui';

import config from 'data/config/config';

import { getAll, isFavorite, removeAll } from './Favorites';
import fetchHelper from 'src/core/util/FetchHelper';
import { get as getLabels } from 'src/core/Lang';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import {
    connect as wsConnect,
    disconnect as wsDisconnect,
    registerEvent as wsRegisterEvent,
} from 'src/core/realtime/RealTime';

import {
    allFavoritesDeleted,
    setFavoritesSynchronizationStatus,
    showLoginDialog,
    showNotification,
    toggleFavorite,
} from 'src/store/actions';


const LOG_PREF = '[SynchronizedFavoritesService] ';


export const STATUS = {
    HIDDEN  : 'hidden',
    DISABLED: 'disabled',
    ONGOING : 'ongoing',
    FAILED  : 'failed',
    SUCCESS : 'success',
};

export const ACTIONS = {
    CREATE: 'create',
    DELETE: 'delete',
};


let dispatch;
export function setReduxStore(store) {
    dispatch = store.dispatch;
};


/**
 * Sub-mobule responsible for persistence
 * @type {Object}
 */
const persistence = (function(){
    const LOCALSTORAGE_KEY = 'synchronized-favorites';

    return {
        get: function(){
            let value = localStorage.getItem(LOCALSTORAGE_KEY)
            return value ? JSON.parse(value) : null;
        },
        set: function(value){
            let toStore = typeof value === 'object' ? JSON.stringify(value) : value;
            localStorage.setItem(LOCALSTORAGE_KEY, toStore);
        },
    };
})();



let currentStatus = STATUS.DISABLED,
    syncFavs;

/**
 * Timestamps used to detect when the user toggled a favorite while the request was pending.
 * In this case, the request result is ignored.
 **/
let lastFavoriteToggle = 0,
    lastSynchroApiCall = 0;


const save = debounce(function _save() {
    persistence.set(syncFavs);
}, 1000);



let userId;
export function setUserId(value) {

    if (!value) {
        userId = value;
        setStatus(STATUS.DISABLED);
        wsDisconnect();
    }
    else if (value !== userId) {
        // Because of login keep-alive, handle only when value is different
        userId = value;
        // synchronize(); // synchronize on WS connection (allows auto-synchronization on reconnection)
        wsConnect('userId='+userId);
    }
}


/**
 * Responsible for a synchronized favorite format
 * @param  {string} id
 * @param  {string} dataType
 * @param  {string} action
 * @param  {Date}   timestamp
 * @return {object}
 */
export const applySynchronizedFavFormat = (id, dataType, action=ACTIONS.CREATE, timestamp=new Date()) => ({
    id,
    dataType,
    action,
    timestamp: timestamp.getTime(),
});


/**
 * Call backend API to synchronize favorites with other devices
 * (debounced to batch requests)
 */
export const synchronize = debounce(instantSynchronize, config.SYNCHRONIZED_FAVORITES.DEBOUNCING);

/**
 * Synchronize instantly (useful on app startup or after login for instance)
 */
export function instantSynchronize() {
    if (userId) {

        lastSynchroApiCall = new Date().getTime();

        // Call backend
        fetchHelper(
            config.SYNCHRONIZED_FAVORITES.URL+'/sync',
            {
                method : 'POST',
                headers: [{ name: 'Content-Type', value: 'application/json' }],
                body   : JSON.stringify({
                    userId   : userId,
                    favorites: syncFavs,
                }),
            },
            true, // isJson (auto parse response)

            _onSynchroResultReceived,

            function errorCb(err) {
                console.error(LOG_PREF+'Failed to synchronize favorites', err);
                setStatus(STATUS.FAILED);
            },

            false // showModalOnError
        );

        setStatus(STATUS.ONGOING);
    }
}

function _onSynchroResultReceived(result) {
    if (lastSynchroApiCall < lastFavoriteToggle) {
        // If the user toggled a favorite since API call
        // then ignore the request result to avoid overriding local modification(s).
        return;
    }

    applySynchronizedFavorites(result);
}


function applySynchronizedFavorites(result) {
    // Note: risk of overriding user favorite actions
    // that occured while the request was pending
    syncFavs = result;
    save();


    // Update local state
    result.forEach(function(fav) {
        const isInLocalFavorites = isFavorite(fav.id, fav.dataType);

        if ((fav.action === ACTIONS.CREATE && !isInLocalFavorites)
                || (fav.action === ACTIONS.DELETE && isInLocalFavorites)) {

            dispatch(toggleFavorite(
                fav.id,
                fav.dataType,
                fav.action === ACTIONS.CREATE ? false : true,
                true // no sync, to avoid infinite loop (toggle fav -> sync -> toggle fav -> sync -> ...)
            ));
        }
    });

    setStatus(STATUS.SUCCESS);
}

/**
 * Load from localstorage, or initialize from current (non-synchronized) favorites
 */
export function init() {
    // Register websocket event
    wsRegisterEvent('synchronizedFavorites', function(data) {
        if (lastFavoriteToggle > (new Date().getTime()-config.SYNCHRONIZED_FAVORITES.DEBOUNCING)) {
            // if the user has local modifications not sent to the server
            // then skip incoming data.
            return;
        }

        applySynchronizedFavorites(data);
    });

    syncFavs = persistence.get();

    if (!syncFavs) {
        // Generate a 'synchronized' version of current favorites
        syncFavs = [];

        let currentFavorites = getAll();
        if (currentFavorites) {
            Object.keys(currentFavorites).forEach(function(dataType) {

                // Convert
                syncFavs = syncFavs.concat(
                    currentFavorites[dataType].map(id => applySynchronizedFavFormat(String(id), dataType))
                );
            });
        }
        save();
    }

    synchronize();
}

/**
 * Look if item is already present
 *
 * @param  {string} id
 * @param  {string} dataType
 * @return {number}
 */
function getItemIndex(id, dataType) {
    for (let i=0; i<syncFavs.length; i++) {
        if (syncFavs[i].id === id && syncFavs[i].dataType === dataType) {
            return i;
        }
    }
    return -1;
}


/**
 * Unitary set a favorite
 */
export function set(id, dataType, action, timestamp) {
    if (!id) {
        console.error(LOG_PREF+'Missing favorite `id`');
        return;
    }
    if (!dataType) {
        console.error(LOG_PREF+'Missing favorite `dataType`');
        return;
    }

    id = String(id);

    let item = applySynchronizedFavFormat(id, dataType, action, timestamp),
        itemIndex = getItemIndex(id, dataType);

    if (itemIndex > -1) {
        // Replace existing item
        syncFavs[itemIndex] = item;
    } else {
        // Else add item to array
        syncFavs.push(item);
    }

    lastFavoriteToggle = new Date().getTime();

    save();

    synchronize();
}

/**
 * Define current status
 */
function setStatus(value) {
    currentStatus = value;
    dispatch(setFavoritesSynchronizationStatus(value));
}


/**
 * Handle when user clicks on toolbar icon
 */
export function handleClickOnIcon() {
    switch (currentStatus) {

        case STATUS.HIDDEN:
            // ignore. should not happen (hidden icon clicked)
            break;

        case STATUS.DISABLED:
            dispatch(showLoginDialog());
            break;

        case STATUS.ONGOING:
        case STATUS.FAILED :
        case STATUS.SUCCESS:
            dispatch(showNotification({ message: getLabels().synchroFavs.status[currentStatus] }));
            break;

        default:
            console.error(LOG_PREF+'Unexpected status: '+currentStatus);
    }
}


let logoutConfirmModalDisplayed = false;

export function onLogout() {
    if (logoutConfirmModalDisplayed) {
        // modal already displayed
        return;
    }
    logoutConfirmModalDisplayed = true;

    ons.notification.confirm(getLabels().synchroFavs.logoutConfirm, {
        title: '',
        buttonLabels: [ getLabels().common.keep, getLabels().common.delete ],
        callback: status => {
            logoutConfirmModalDisplayed = false;
            if (status) {
                removeAllLocalFavorites();
            }
        },
    });
}


function removeAllLocalFavorites() {
    // delete all local favorites
    syncFavs = [];
    save();

    // Remove all favorites (genuine non-sync format)
    removeAll();

    dispatch(allFavoritesDeleted());
}


/**
 * Get appropriate icon depending on `status`
 * @see SynchronizedFavoritesService.STATUS
 *
 * @param  {string} status
 * @return {string}
 */
export function getButtonIcon(status) {
    switch (status) {
        case STATUS.HIDDEN  : /* should not happen */ return null;
        case STATUS.DISABLED: return getUrl('files/project/misc/sync-grey.svg');
        case STATUS.ONGOING : return getUrl('files/project/misc/sync-black.svg'); // +css animation to spin it
        case STATUS.FAILED  : return getUrl('files/project/misc/sync-red.svg');
        case STATUS.SUCCESS : return getUrl('files/project/misc/sync-green.svg');
        default: console.error('Unexpected synchro favorites status: '+status);
    }
}


// Ability to manually trigger `synchronize` in DEV env
if (config.ENV === 'dev') {
    global.synchronize = synchronize;
}
