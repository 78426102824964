
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
    DATA_TYPE_AIRCRAFTS,
    DATA_TYPE_EVENTS,
    DATA_TYPE_EXHIBITOR_CATEGORIES,
    DATA_TYPE_EXHIBITORS,
} from 'data/config/dataConfig';

import { DATA_TYPE_TO_PAGE_KEY } from 'src/pages/dataToPageMapping';
import { EXHIBITOR_PAGE_KEY } from 'src/pages/pagesKeys';

import { LIST_PAGE_KEY } from 'src/pages/pagesKeys';

import DetailCountry      from 'src/components/detail-country/DetailCountry';
import DetailEvents       from 'src/components/detail-events/DetailEvents';
import DetailFixedTitle   from 'src/components/detail-fixed-title/DetailFixedTitle';
import DetailNewProducts  from 'src/components/detail-newproducts/DetailNewProducts';
import DetailPlaces       from 'src/components/detail-places/DetailPlaces';
import DetailSocial       from 'src/components/detail-social/DetailSocial';
import UseRemainingHeight from 'src/components/use-remaining-height/UseRemainingHeight';
import Description        from 'src/components/fiche/Description';
import PhoneRow           from 'src/components/fiche/PhoneRow';
import EmailRow           from 'src/components/fiche/EmailRow';
import WebsiteRow         from 'src/components/fiche/WebsiteRow';
import ShareButton        from 'src/components/share-button/ShareButton';
import Url                from 'src/components/url/Url';
import CTAButton          from 'src/components/cta-button/CTAButton';
import { formatDateAndTime } from 'src/core/Lang';


class ExhibitorContent extends PureComponent {

    setScroller = ref => {
        // Used by parent component
        this.scroller = ref;
    }

    onClickOnFavIcon = () => {
        this.props.actions.toggleFavorite(
            this.props.item.id,
            DATA_TYPE_EXHIBITORS,
            this.props.isFavorite
        );
    }

    showItems(dataType) {
        let items  = this.props.item[dataType];

        // Directly go to the single item
        if (items.length === 1) {
            this.props.actions.navigate(DATA_TYPE_TO_PAGE_KEY[dataType], {
                id : items[0].id,
            });
        } else {
            // Display a list
            this.props.actions.navigate(LIST_PAGE_KEY, {
                locateAll: false,
                inputs: [{
                    parentId  : this.props.item.id,
                    parentType: DATA_TYPE_EXHIBITORS,
                    dataType  : dataType,
                }],
            });
        }
    }

    showEvents = () => {
        this.showItems(DATA_TYPE_EVENTS);
    }

    showAircrafts = () => {
        this.showItems(DATA_TYPE_AIRCRAFTS);
    }

    onClickOnCategory = exhCat => {

        let exhCatLump = exhCat.lump;

        if (Array.isArray(exhCatLump.exhibitors) && exhCatLump.exhibitors.length > 0) {
            // Display content
            this.props.actions.navigate(LIST_PAGE_KEY, {
                locateAll: false,
                inputs: [{
                    parentId  : exhCat.id,
                    parentType: DATA_TYPE_EXHIBITOR_CATEGORIES,
                    dataType  : DATA_TYPE_EXHIBITORS,
                }],
            });
        }
        else {
            // Display sub-categories
            this.props.actions.navigate(LIST_PAGE_KEY, {
                locateAll: false,
                inputs: [{
                    id: exhCat.id,
                    dataType: DATA_TYPE_EXHIBITOR_CATEGORIES,
                }],
            });
        }
    }

    sendAppointmentRequest = () => {
        this.props.actions.sendAppointmentRequest(
            this.props.item.id,
            DATA_TYPE_EXHIBITORS,
            this.props.item.original_id,
        );
    }

    render() {
        let lump = this.props.item.lump;

        const
            title       = this.props.item.title,
            logoUrl     = this.props.item.logo_file_name,
            description = this.props.item.description,

            aircraftsCount   = Array.isArray(lump.aircraft) ? lump.aircraft.length : 0,

            hasAddress = this.props.item.address || this.props.item.postal_code || this.props.item.city || this.props.item.references.country,
            hasSocial  = (lump.social && (lump.social.tw || lump.social.fb || lump.social.ln)),

            hasContactsBlock = (lump.contacts && lump.contacts.length > 0)
                                    || this.props.item.phone
                                    || this.props.item.email
                                    || this.props.item.website
                                    || hasAddress
                                    || hasSocial
                                    || (this.props.appointmentRequestStatus.isEnabled && lump.networking);
        let categories,
            categoriesLabel;

        if (this.props.item.references.activities) {
            categories = this.props.item.references.activities;
            categoriesLabel = this.props.labels.exhibitor.activities;
        } else {
            categories = this.props.item.references.categories;
            categoriesLabel = this.props.labels.exhibitor.categories;
        }

        return (
            <div className="fiche exhibitor content-font" style={{ height: this.props.height }}>

                {/* title */}
                <DetailFixedTitle
                        image={lump && lump.focus !== undefined ? lump.focus : null}
                        title={title}
                        isFav={this.props.isFavorite}
                        onFavClick={this.onClickOnFavIcon}
                        labels={this.props.labels}>

                    <ShareButton
                        name={this.props.getPageTitle(this.props.item, this.props.labels)}
                        queryString={this.props.queryString}
                        description={description}
                        image={logoUrl}
                        labels={this.props.labels}
                        actions={this.props.actions} />

                </DetailFixedTitle>

                <UseRemainingHeight ref={this.setScroller}>

                    <div className="content">
                        <div className="all-informations content-font">

                            <Description
                                logoFileName={logoUrl}
                                description={description}
                                isLogoFullWidth={this.props.isLogoFullWidth} />

                            {/* Counters */}
                            <div>
                                {/* places */}
                                <DetailPlaces
                                    labels={this.props.labels}
                                    actions={this.props.actions}
                                    id={this.props.item.id}
                                    originalId={this.props.item.original_id}
                                    entityType={DATA_TYPE_EXHIBITORS}
                                    places={this.props.item.references.places} />

                                {/* events */}
                                <DetailEvents
                                    items={this.props.item.references.events}
                                    labels={this.props.labels.events}
                                    onClick={this.showEvents} />

                                {/* aircrafts */}
                                { aircraftsCount > 0 &&
                                    <div className="fiche-related-data">
                                        <div className="prop-img">
                                            <div className="prop-left">
                                                <div className="icon-font">
                                                    <span className="link">a</span>
                                                </div>
                                            </div>
                                            <div className="prop-right aeronefs color-link-blue" onClick={this.showAircrafts}>
                                                <div><span className="count">{aircraftsCount}</span>
                                                    <span>{ this.props.labels.data.aircrafts[ aircraftsCount > 1 ? 'plural' : 'singular' ].toLowerCase() }</span>
                                                </div>
                                            </div>
                                            <div className="prop-right-fleche">
                                                <span className="fa fa-chevron-right fleche" />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                            {/* newproducts */}
                            <DetailNewProducts
                                items={this.props.item.references.newproducts}
                                actions={this.props.actions}
                                labels={this.props.labels}
                                pageKey={EXHIBITOR_PAGE_KEY} />

                            {/* contacts */}
                            { hasContactsBlock &&

                                <div>
                                    <div className="fiche-separator">{this.props.labels.exhibitor.contacts}</div>

                                    { /* Appointment request */ }
                                    { lump.networking && this.props.appointmentRequestStatus.isEnabled && !this.props.appointmentRequestStatus.date &&
                                        <CTAButton
                                            isEnabled={this.props.appointmentRequestStatus.ongoing !== true}
                                            label={this.props.labels.appointment.button}
                                            action={this.sendAppointmentRequest} />
                                    }
                                    { lump.networking && this.props.appointmentRequestStatus.isEnabled && this.props.appointmentRequestStatus.date &&
                                        <div className="appointment-request-sent">{
                                            this.props.labels.appointment.sent(
                                                formatDateAndTime(this.props.appointmentRequestStatus.date),
                                                this.props.appointmentRequestStatus.userEmail,
                                                this.props.appointmentRequestStatus.userPhone)
                                        }</div>
                                    }

                                    <DetailSocial data={lump.social} actions={this.props.actions} />

                                    { lump.contacts && lump.contacts.length > 0 &&
                                        <div className="free-row">
                                            <div className="prop-img">
                                                <div className="prop-left">
                                                    <div className="fiche-contact-icon">
                                                        <span className="fa fa-user" />
                                                    </div>
                                                </div>
                                                <div className="prop-right">
                                                    { lump.contacts.map((entry, index) => (
                                                        <div key={index}>
                                                            <span className="contactName" dangerouslySetInnerHTML={{ __html: entry.n }} />
                                                        </div>
                                                    )) }
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <PhoneRow phone={this.props.item.phone} />

                                    <WebsiteRow
                                        website={this.props.item.website}
                                        actions={this.props.actions} />

                                    <EmailRow email={this.props.item.email} />

                                    {/* address */}
                                    { hasAddress &&
                                        <div className="free-row">
                                            <div className="prop-img">
                                                <div className="prop-left">
                                                    <div className="fiche-contact-icon">
                                                        <span className="fa fa-university" />
                                                    </div>
                                                </div>
                                                <div className="prop-right">
                                                    { this.props.item.address     && <div dangerouslySetInnerHTML={{ __html: this.props.item.address }} /> }
                                                    { this.props.item.postal_code && <div dangerouslySetInnerHTML={{ __html: this.props.item.postal_code }} /> }
                                                    { this.props.item.city        && <div dangerouslySetInnerHTML={{ __html: this.props.item.city }} /> }
                                                    { this.props.item.references.country && <div dangerouslySetInnerHTML={{ __html: this.props.item.references.country.title }} /> }
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>

                            } {/* end contacts */}


                            {/* entities */}
                            { lump.entities && lump.entities.length > 0 &&
                                <div>
                                    <div className="fiche-separator">{this.props.labels.exhibitor.entities}</div>
                                    <div>
                                        <div className="prop-img">
                                            <div className="prop-left">
                                                <div className="fiche-contact-icon">
                                                    <span className="fa fa-university" />
                                                </div>
                                            </div>
                                            <div className="prop-right">
                                                { lump.entities.map((entry, index) => (
                                                    <div key={index} className="entites-content">
                                                        { entry.title   && <div className="addressName">{entry.title}</div> }
                                                        { entry.type    && <div className="typeName">{entry.type}</div> }
                                                        { entry.address && <div className="addressName">{entry.address}</div> }
                                                        { entry.phone &&
                                                            <div className="phoneName">
                                                                <a className="link color-link-blue" href={'tel:'+entry.phone}>{entry.phone}</a>
                                                            </div>
                                                        }
                                                        { entry.email &&
                                                            <div className="emailName">
                                                                <a className="link color-link-blue" href={'mailto:'+entry.email}>{entry.email}</a>
                                                            </div>
                                                        }
                                                        { entry.website &&
                                                            <div className="websiteName">
                                                                <Url callback={this.props.actions.linkClicked}
                                                                     href={entry.website}
                                                                     label={entry.website} />
                                                            </div>
                                                        }
                                                        <div className="separatorEnt"></div>
                                                    </div>
                                                )) }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/* <div className="fiche-separator">{this.props.labels.exhibitor.agentCountries}</div> */}
                            <DetailCountry country={lump.agent_countries} />

                            {/* brands */}
                            { this.props.item.brands &&
                                <div>
                                    <div className="fiche-separator">{this.props.labels.exhibitor.brands}</div>
                                    <div className="free-row">
                                        <div className="prop-img">
                                            <div className="prop-left">
                                                <div className="fiche-contact-icon">
                                                    <span className="fa fa-star-o" />
                                                </div>
                                            </div>
                                            <div className="prop-right">
                                                <span>{ this.props.item.brands }</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/* Categories or Activities(SIAE) */}
                            { Array.isArray(categories) && categories.length > 0 &&
                                <div>
                                    <div className="fiche-separator">{categoriesLabel}</div>
                                    <div className="activity free-row">
                                        <div className="prop-img">
                                            <div className="prop-left">
                                                <div className="fiche-contact-icon">
                                                    <span className="fa fa-tag" />
                                                </div>
                                            </div>
                                            <div className="prop-right activites flex-ellipsis">
                                                { categories.map(exhCat => (
                                                    <div key={exhCat.id}
                                                         onClick={() => { this.onClickOnCategory(exhCat); }}
                                                         className="activities-content color-link-blue">

                                                        <span>{ exhCat.title }</span>

                                                        { Array.isArray(exhCat.lump[DATA_TYPE_EXHIBITORS]) &&
                                                            <span className="list-el-counter"><span>{exhCat.lump[DATA_TYPE_EXHIBITORS].length}</span></span>
                                                        }

                                                        <div className="prop-right-fleche">
                                                            <span className="fa fa-chevron-right fleche" />
                                                        </div>
                                                    </div>
                                                )) }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div> {/* end of .allInformations */}
                    </div>
                </UseRemainingHeight>
            </div>
        );
    }

};

ExhibitorContent.propTypes = {
    item        : PropTypes.object,
    isFavorite  : PropTypes.bool,
    appointmentRequestStatus: PropTypes.object.isRequired,
    isLogoFullWidth: PropTypes.bool,
    // Common
    queryString : PropTypes.string.isRequired,
    getPageTitle: PropTypes.func.isRequired,
    labels      : PropTypes.object.isRequired,
    actions     : PropTypes.object.isRequired,
    height      : PropTypes.number, // prop set from AppToolbar
};

export default ExhibitorContent;
