
import React from 'react';
import PropTypes from 'prop-types';

import Url from 'src/components/url/Url';

const DetailSocial = ({ data, actions }) => (
    data && (data.tw || data.fb || data.ln)

        ?   <div className="free-row">
                <div className="prop-img">
                    <div className="prop-left">
                        <div className="fiche-contact-icon">
                            <span className="fa fa-comments-o" style={{ fontSize: '1.6em' }}/>
                        </div>
                    </div>
                    <div className="prop-right social-icons-container">
                        { data.tw && <Url className="fa fa-twitter-square"  callback={actions.linkClicked} href={data.tw} /> }
                        { data.fb && <Url className="fa fa-facebook-square" callback={actions.linkClicked} href={data.fb} /> }
                        { data.ln && <Url className="fa fa-linkedin-square" callback={actions.linkClicked} href={data.ln} /> }
                    </div>
                </div>
            </div>

        : null
);

DetailSocial.propTypes = {
    data: PropTypes.object,
    actions: PropTypes.object.isRequired,
};
export default DetailSocial;