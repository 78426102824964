
import { DATA_TYPE_SPEAKERS } from 'data/config/dataConfig';

import { AUTOSHOW_POI_ON_MAP } from 'data/config/pagesTwoColumnsConfig';

import {
    AD_CONFIG_LOADED,
    POLL_CONFIG_LOADED,
    ALL_FAVORITES_DELETED,
    DATA_UPDATED,
    ITEM_FETCHED,
    LANG_CHANGED,
    PROFILE_CHANGED,
    TOGGLE_FAVORITE,
    TOGGLE_MENU,
    UPDATE_PAGE_STATE,
} from 'src/store/actionTypes';

import {
    adConfigLoaded,
    pollConfigLoaded,
    dataUpdated,
    getPageCommonDefaultState,
    itemFetched,
    langChanged,
    profileChanged,
    setIsFavoriteFalse,
    toggleFavorite,
    toggleMenu,
    updatePageState,
} from 'src/store/reducers/commons';

import { SPEAKER_PAGE_KEY } from 'src/pages/pagesKeys';


const USED_TABLES = [ DATA_TYPE_SPEAKERS ];


const getDefaultState = () => Object.assign({}, getPageCommonDefaultState(SPEAKER_PAGE_KEY), {
    isPending: true,
});


export default (state = getDefaultState(), action) => {
    switch (action.type) {

        case AD_CONFIG_LOADED: return adConfigLoaded(state, action);
        case POLL_CONFIG_LOADED: return pollConfigLoaded(state, action);
        case ALL_FAVORITES_DELETED: return setIsFavoriteFalse(state);
        case DATA_UPDATED: return dataUpdated(state, action, USED_TABLES);
        case ITEM_FETCHED: return itemFetched(state, action, DATA_TYPE_SPEAKERS);
        case LANG_CHANGED: return langChanged(state, action);
        case UPDATE_PAGE_STATE: return updatePageState(state, action, SPEAKER_PAGE_KEY);
        case PROFILE_CHANGED: return profileChanged(state, action, SPEAKER_PAGE_KEY);
        case TOGGLE_FAVORITE: return toggleFavorite(state, action, DATA_TYPE_SPEAKERS);
        case TOGGLE_MENU: return toggleMenu(state, action, SPEAKER_PAGE_KEY);

        default: return state;
    }
};
