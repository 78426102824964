
// PROMISE
// if (typeof Promise === 'undefined') {
//   // Rejection tracking prevents a common issue where React gets into an
//   // inconsistent state due to an error, but it gets swallowed by a Promise,
//   // and the user has no idea what causes React's erratic future behavior.
//   require('promise/lib/rejection-tracking').enable();
//   window.Promise = require('promise/lib/es6-extensions.js');
// }


// FETCH
require('whatwg-fetch');


// OBJECT.ASSIGN
// MobiGeo includes a polyfill already
// Object.assign = require('object-assign');


// STRING.STARTSWITH
// require('string.prototype.startswith');


require('babel-polyfill');


// CustomEvent polyfill for IE11
(function () {

  if ( typeof window.CustomEvent === "function" ) return false;

  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
   }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();


// Promises
require('../static-lib/promise.polyfill.js');
