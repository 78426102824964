
import Pages from 'src/pages/Pages';

const DEFAULT_DELAY = 200;

/**
 * On cordova iOS there is a nasty bug on some pages (search, list) that makes all the content disappear on touch.
 * Yet you can interact with elements, type text in the input but nothing is visible...
 *
 * No clean fix could be found. A hack is to toggle visibility of the DOM container.
 */
function performHack(pageKey, delay=DEFAULT_DELAY) {
    if (Pages[pageKey].mounted === true) {
        let container = Pages[pageKey].getElement();
        if (container) {
            container.style.visibility = 'hidden';

            window.setTimeout(function() {
                container.style.visibility = 'visible';
            }, delay);
        }
    }
}

global.performHack = performHack;

export default performHack;
