// Libs
import React, { Component }   from 'react';
import ReactDOM               from 'react-dom';
import PropTypes              from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import iscroll                from 'iscroll';
import { debounce }           from 'lodash';

// Conf
import { ISCROLL_OPTIONS, REFRESH_DELAY } from 'data/config/iScrollConfig';
import {
    DATA_TYPE_EVENTS,
    DATA_TYPE_EVENT_CATEGORIES,
    DATA_TYPE_EXHIBITORS,
} from 'data/config/dataConfig';

import { AGENDA_PAGE_KEY, LIST_PAGE_KEY } from 'src/pages/pagesKeys';

// App modules
import AppToolbar    from 'src/components/app-toolbar/AppToolbar';
import Menu          from 'src/components/menu/Menu';
import AgendaContent from './AgendaContent';
import { isIOS }     from 'src/core/util/browser';

import * as actions from 'src/store/actions';


import './AgendaPage.scss';


const LOG_PREF = '[AgendaPage] ';


class AgendaPage extends Component {

    constructor(props) {
        super(props);

        this.pageKey = AGENDA_PAGE_KEY;
    }

    getPageTitle() {
        switch (this.props.input.dataType) {

            case DATA_TYPE_EXHIBITORS: return this.props.labels.data.exhibitors.plural;

            default: return this.props.labels.agenda.title;
        }
    }

    /**
     * Update string visible in browser tab/history/favorites
     */
    setDocumentContext() {
        if (this.props.isActive(this.pageKey)) {
            this.props.setDocumentContext(this.getPageTitle());
        }
    }

    componentDidMount(){
        this.fetchIfNeeded(this.props);
        this.refreshIScroll();
    }
    componentWillReceiveProps(nextProps) {
        this.fetchIfNeeded(nextProps);
    }
    componentDidUpdate(){
        this.refreshIScroll();
        this.setDocumentContext();
    }

    /**
     * Fetch data if :
     *  - data has been updated (shouldFetch: true)
     *  - or id is different
     *  - or if we have no groupedItems data and request is neither pending nor without result
     */
    fetchIfNeeded(props) {
        if (props.shouldFetch === true || this.props.input !== props.input ||
                (!props.groupedItems && props.isPending !== true && props.itemNotFound !== true)) {

            props.actions.fetchGroupedItems(props.input);
        }
    }

    onContainerRender = ref => {
        this.eventsContainer = ReactDOM.findDOMNode(ref);

        if (this.eventsContainer !== null && isIOS()) {
            if (this.iScrollInstance) {
                this.refreshIScroll();
            } else {
                this.iScrollInstance = new iscroll(this.eventsContainer, ISCROLL_OPTIONS);
            }
        }
    }

    refreshIScroll = debounce(() => {
        window.setTimeout(() => {
            if (this.iScrollInstance) {
                if (this.props.isActive(this.pageKey) !== true) {
                    this.scrollTo(0);
                }
                else if (this.iScrollInstance) {
                    this.iScrollInstance.refresh();
                    console.log(LOG_PREF+'refreshed iscroll');
                }
            }
        }, REFRESH_DELAY);
    }, 50);

    scrollTo = value => {
        if (this.iScrollInstance) {
            this.iScrollInstance.scrollTo(0, value*-1);
        } else {
            this.eventsContainer.scrollTop = value;
        }
    }

    navigateToRootCategory = () => {
        this.props.actions.navigate(LIST_PAGE_KEY, {
            locateAll: false,
            inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }]
        });
    }

    render() {
        console.log(LOG_PREF+'render');
        return (
            <Menu
                options={{ isOpen: this.props.isMenuOpen }}
                actions={this.props.actions}
                labels={this.props.labels}
                profile={this.props.profile}
                associatedPageKey={this.pageKey}
                adConfig={this.props.adConfig}
                twoColumns={this.props.twocolumns}>

                <AppToolbar
                    labels={this.props.labels}
                    isDisplayed={this.props.hasToolbar}
                    actions={this.props.actions}
                    title={this.getPageTitle()}
                    contextualTitle={this.props.contextualTitle}
                    pageKey={this.pageKey}
                    profile={this.props.profile}
                    hasBackButton={this.props.backButtonInToolbar}
                    hasHomeButton={this.props.homeButtonInToolbar}
                    hasFavButton={this.props.favButtonInToolbar}
                    hasSearchButton={this.props.searchButtonInToolbar}
                    hasMenuButton={this.props.menuButtonInToolbar}
                    rootCategoryButtonInToolbar={this.props.rootCategoryButtonInToolbar}
                    navigateToRootCategory={this.navigateToRootCategory}>

                    <AgendaContent
                        // Fetch status props
                        isPending={this.props.isPending}
                        itemNotFound={this.props.itemNotFound}
                        // Content props
                        groupedItems={this.props.groupedItems}
                        dataType={this.props.input.dataType}
                        favorites={this.props.favorites}
                        onContainerRender={this.onContainerRender}
                        scrollTo={this.scrollTo}
                        favIconDisabled={this.props.favIconDisabled}
                        ad={this.props.ad}
                        sideIndexThreshold={this.props.sideIndexThreshold}
                        // Common
                        adConfig={this.props.adConfig}
                        labels={this.props.labels}
                        actions={this.props.actions}
                        queryString={this.props.queryString} />

                </AppToolbar>
            </Menu>
        );
    }
};


AgendaPage.propTypes = {
    input       : PropTypes.object,
    groupedItems: PropTypes.object,
    favorites   : PropTypes.array,
    isPending   : PropTypes.bool,
    itemNotFound: PropTypes.bool,
    ad          : PropTypes.object,
    favIconDisabled: PropTypes.bool,
    sideIndexThreshold: PropTypes.number.isRequired,
    // Common page props
    isMenuOpen        : PropTypes.bool.isRequired,
    profile           : PropTypes.string,
    labels            : PropTypes.object.isRequired,
    actions           : PropTypes.object.isRequired,
    isActive          : PropTypes.func.isRequired,
    contextualTitle   : PropTypes.string,
    setDocumentContext: PropTypes.func.isRequired,
    adConfig          : PropTypes.object,
    // toolbar
    hasToolbar           : PropTypes.bool,
    homeButtonInToolbar  : PropTypes.bool,
    backButtonInToolbar  : PropTypes.bool,
    searchButtonInToolbar: PropTypes.bool,
    favButtonInToolbar   : PropTypes.bool,
    menuButtonInToolbar  : PropTypes.bool,
    rootCategoryButtonInToolbar: PropTypes.bool,
}

const mapStateToProps = (state, ownProps) => state[AGENDA_PAGE_KEY];
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AgendaPage);
