
// Conf
import {
    DATA_TYPE_PLACES,
    CATEGORIES_DATA_TYPE,
    NON_CATEGORIES_DATA_TYPE,
    VALID_DATA_TYPES,
} from 'data/config/dataConfig';

import { elementPropsGetters } from 'data/config/listConfig';
import { getSortConfig, groupItems } from 'data/config/sortConfig';

// App modules
import { setCurrent as setCurrentLang } from 'src/core/Lang';
import * as Db                          from 'src/core/data-and-assets/Db';
import * as Query                       from 'src/core/query/Query';
import { search, searchPlaces }         from 'src/core/search/Search';
import * as Favorites                   from 'src/core/favorites/Favorites';

import STATUS from './fetchStatuses';

import { MAP_PAGE_KEY } from 'src/pages/pagesKeys';

import {
    DATA_UPDATE_STARTED,
    DATA_UPDATED,

    UPDATER_INITIALIZED,

    MAP_ASSET_UPDATED,
    MAP_RELOAD,
    MAP_LOADED,
    MAP_USER_LOCATED,
    MAP_USER_UNLOCATED,
    SHOW_MAP_ITINERARY,
    MAP_ZOOM_ON_ZONE,
    ITINERARY_API_CALLED,
    MOBIGEO_ERROR_THROWN,
    GEOGROUP_PSEUDO_SET,
    GEOGROUP_GROUP_CREATED,
    GEOGROUP_GROUP_JOINED,
    GEOGROUP_GROUP_QUITTED,

    SHOW_SEARCH_PLACE_DIALOG,
    HIDE_SEARCH_PLACE_DIALOG,
    SET_SEARCH_PLACE_DIALOG_CANCELABLE,
    PLACE_SEARCH_PERFORMED,
    SEARCHED_PLACE_SELECTED,
    CLEAR_PLACE_RESULTS,

    NAVIGATE,
    NAVIGATE_BACK,
    HAS_NAVIGATED,

    ITEM_FETCHED,
    ITEMS_FETCHED,
    LISTS_FETCHED,
    GROUPED_ITEMS_FETCHED,

    PROFILE_CHANGED,

    LANG_CHANGED,

    SHOW_LANG_DIALOG,
    HIDE_LANG_DIALOG,

    SHOW_LOGIN_DIALOG,
    SHOW_PROFILE_DIALOG,
    HIDE_PROFILE_DIALOG,
    PERFORM_LOGIN,
    PERFORM_LOGOUT,
    HIDE_LOGIN_DIALOG,
    SET_LOGIN_IDENTIFICATION,
    SET_LOGIN_ERROR,
    UPDATE_PROFILE,
    HAS_LOGGED_OUT,

    UPDATE_PAGE_STATE,

    TOGGLE_FAVORITE,
    ALL_FAVORITES_DELETED,
    FETCH_FAVORITES,
    SET_FAVORITES_SYNCHRONIZATION_STATUS,
    SYNCHRO_FAVORITES_ICON_CLICKED,

    WINDOW_RESIZED,

    SEARCH_PERFORMED,
    CLEAR_SEARCH_RESULTS,

    TOGGLE_MENU,

    SHOW_DATA_LIST_DIALOG,
    HIDE_DATA_LIST_DIALOG,

    AD_CONFIG_UPDATED,
    AD_CONFIG_LOADED,

    POLL_CONFIG_UPDATED,
    POLL_CONFIG_LOADED,
    SET_POLL_ID,
    SET_POLL_CODE,
    VALIDATE_POLL,
    VALIDATE_POLL_CODE,
    SHOW_POLL_DIALOG,
    HIDE_POLL_DIALOG,
    GO_TO_NEXT_POLL_STEP,
    GO_TO_PREVIOUS_POLL_STEP,
    SUBMIT_POLL,
    SET_POLL_STEP,
    SET_POLL_ERROR,
    SET_POLL_PAGE,
    SET_POLL_MARK,
	SET_POLL_COMMENT,
	SET_POLL_CHOICE,
    SET_POLL_MULTIPLE,

    FETCH_CONTRIBUTIONS_FEED,
    CONTRIBUTIONS_FEED_LOADED,
    SET_CONTRIBUTIONS_FEED_ERROR,
    ACTIVATE_CONTRIBUTIONS_REFRESH,

    FETCH_SOCIAL_FEED,
    SOCIAL_FEED_LOADED,
    SET_SOCIAL_FEED_ERROR,
    ACTIVATE_SOCIAL_FEED_REFRESH,

    SHOW_INTERSTICIEL,
    HIDE_INTERSTICIEL,
    SHOW_INTERSTICIEL_CLOSE_BUTTON,
    HIDE_INTERSTICIEL_CLOSE_BUTTON,

    SHOW_FULL_LOADER,
    HIDE_FULL_LOADER,

    LINK_CLICKED,

    SHOW_NOTIF,
    REMOVE_NOTIF,

    FLIGHT_CLICKED,

    SEND_APPOINTMENT_REQUEST,
    APPOINTMENT_REQUEST_SENT,
    APPOINTMENT_REQUEST_SEND_RESULT,

    REAL_TIME_CONNECTED,
    REAL_TIME_DISCONNECTED,

} from 'src/store/actionTypes'


const SIMULATED_FETCH_LATENCY = 0;

const LOG_PREF = '[actions] ';


/**
 * Navigate forward
 * @type {String}
 */
export const navigate = (pageKey, pageProps, transition, pushToHistory) => dispatch => {
    dispatch({
        type: NAVIGATE,
        pageKey: pageKey,
        options: pageProps,
        transition: transition,
        pushToHistory: pushToHistory,
    });
};

/**
 * Navigate backward
 * @type {String}
 */
export const navigateBack = () => ({
    type: NAVIGATE_BACK,
});

/**
 * Navigation is done
 */
export const hasNavigated = (pageKey, pageProps) => ({
    type     : HAS_NAVIGATED,
    pageKey  : pageKey,
    pageProps: pageProps,
});


/**
 * Change language
 * @param  {string} lang
 */
export const setLanguage = lang => {
    let labels = setCurrentLang(lang);
    return {
        type    : LANG_CHANGED,
        language: lang,
        labels  : labels,
    };
};


/**
 * Data and assets update is about to start
 * @type {String}
 */
export const dataUpdateStarted = () => ({
    type  : DATA_UPDATE_STARTED,
});

/**
 *  - Main data file (e.g db_[lang].json) has been loaded
 *  - Main data file has been reloaded due to language change
 *  - Data diff from backend has been merged
 * @type {String}
 */
export const dataUpdated = (tables) => ({
    type  : DATA_UPDATED,
    tables: tables,
});


/**
 * Updater has initialized, so assets versions are ready to be queried
 */
export const updaterInitialized = () => ({
    type  : UPDATER_INITIALIZED,
});


/**
 * Additional data file (e.g graph.json, svg, etc) has been loaded
 * @type {String}
 */
export const mapAssetUpdated = (assets) => ({
    type  : MAP_ASSET_UPDATED,
    assets: assets,
});


/**
 * MogiGeo has successfully loaded and displayed the map for a dataset
 * @type {String}
 */
export const mapLoaded = () => ({
    type: MAP_LOADED,
});

/**
 * Must reload the map
 */
export const mapReload = () => ({
    type: MAP_RELOAD,
});

/**
 * User has a position
 */
export const mapUserLocated = () => ({
    type: MAP_USER_LOCATED,
});

/**
 * User has a no position
 */
export const mapUserUnlocated = () => ({
    type: MAP_USER_UNLOCATED,
});

/**
 * Display an itinerary to a POI
 */
export const showMapItinerary = (start, dest) => ({
    type : SHOW_MAP_ITINERARY,
    start: start,
    dest : dest,
});


/**
 * Display a modal allowing the search for a POI (to show on map or to compute a route)
 */
export const showSearchPlaceDialog = type => ({
    type: SHOW_SEARCH_PLACE_DIALOG,
    searchType: type,
});

/**
 * Hide the modal allowing the user to choose start/destination
 */
export const hideSearchPlaceDialog = () => ({
    type: HIDE_SEARCH_PLACE_DIALOG,
});

// @see searchPlaceDialogMiddleware
export const setSearchPlaceDialogCancelable = value => ({
    type : SET_SEARCH_PLACE_DIALOG_CANCELABLE,
    value: value,
});

/**
 * Show POIs on map
 *
 * NB: used to be a specific action `SHOW_ALL_POIS_ON_MAP`.
 * Now that the url must be aware of the POI to show, `navigate` action is used
 */
export const showAllPoisOnMap = pois => navigate(MAP_PAGE_KEY, { pois: pois });

/**
 * Show a single POI on map
 *
 * NB: used to be a specific action `SHOW_ONE_POI_ON_MAP`.
 * Now that the url must be aware of the POI to show, `navigate` action is used
 */
export const showOnePoiOnMap = poi => navigate(MAP_PAGE_KEY, { poi: poi });

/**
 * Route to map to focus on a specific zone and floor
 */
export const mapZoomOnZone = (zone, floor) => ({
    type : MAP_ZOOM_ON_ZONE,
    zone : zone,
    floor: floor,
});


export const itineraryApiCalled = destination => ({
    type: ITINERARY_API_CALLED,
    destination: destination,
});

export const mobigeoErrorThrown = (errorCode, mobigeoModuleName, additionalInfo) => ({
    type     : MOBIGEO_ERROR_THROWN,
    errorCode: errorCode,
    module   : mobigeoModuleName,
    additionalInfo: additionalInfo,
});


/**
 * GEOGROUP actions
 */

export const geogroupPseudoSet = user => ({
    type: GEOGROUP_PSEUDO_SET,
    user: user,
});

export const geogroupGroupCreated = group => ({
    type : GEOGROUP_GROUP_CREATED,
    group: group,
});

export const geogroupGroupJoined = group => ({
    type : GEOGROUP_GROUP_JOINED,
    group: group,
});

export const geogroupGroupQuitted = group => ({
    type : GEOGROUP_GROUP_QUITTED,
    group: group,
});



const itemFetchStatus = (id, dataType, item, status) => ({
    type      : ITEM_FETCHED,
    id        : id,
    dataType  : dataType,
    item      : item,
    status    : status,
    isFavorite: Favorites.isFavorite(id, dataType),
});

/**
 * Fetch a single item
 * @param  {number} id
 * @param  {string} dataType
 * @param  {array} relatedDataToSet
 * @return {object/promise}
 */
export const fetchItem = (id, dataType, relatedDataToSet) => dispatch => {
    let status,
        item;

    // Check dataType
    if (VALID_DATA_TYPES.indexOf(dataType) === -1) {
        console.error(LOG_PREF+'Cannot fetch data for type: '+dataType);
        dispatch(itemFetchStatus(id, dataType, item, STATUS.NO_RESULT));
        return;
    }

    if (Db.isDataReady() !== true) {
        status = STATUS.PENDING;
    } else {
        item = Query.get(id, dataType, relatedDataToSet);

        if (item) {
            status = STATUS.FETCHED;
        } else {
            status = STATUS.NO_RESULT;
        }
    }

    window.setTimeout(() => {
        dispatch(itemFetchStatus(id, dataType, item, status));
    }, SIMULATED_FETCH_LATENCY);
};


const itemsFetchStatus = (ids, dataType, items, status) => ({
    type     : ITEMS_FETCHED,
    ids      : ids,
    dataType : dataType,
    items    : items,
    status   : status,
    favorites: Favorites.getAll(),
});

/**
 * Fetch a single item
 * @param  {array|string} ids (string if there is a single id)
 * @param  {string} dataType
 * @param  {array} relatedDataToSet
 * @return {object/promise}
 */
export const fetchItems = (ids, dataType, relatedDataToSet) => dispatch => {
    let status,
        items;

    // Check dataType
    if (VALID_DATA_TYPES.indexOf(dataType) === -1) {
        console.error(LOG_PREF+'Cannot fetch data for type: '+dataType);
        dispatch(itemsFetchStatus(ids, dataType, items, STATUS.NO_RESULT));
        return;
    }

    if (Db.isDataReady() !== true) {
        status = STATUS.PENDING;
    } else {
        let _ids;
        // Handle various input type
        if (Array.isArray(ids)) {
            _ids = ids;
        } else if (typeof ids === 'string') {
            _ids = ids.split(',');
        } else if (typeof ids === 'number') {
            _ids = [ ids ];
        } else {
            console.error(LOG_PREF+'Unexpected type of argument `ids`', ids);
            _ids = [];
        }
        items = _ids.map((id) => Query.get(id, dataType, relatedDataToSet)).filter(item => item);

        if (items.length > 0) {
            items = Db.sortItems(items, dataType);
            status = STATUS.FETCHED;
        } else {
            status = STATUS.NO_RESULT;
        }
    }

    window.setTimeout(() => {
        dispatch(itemsFetchStatus(ids, dataType, items, status));
    }, SIMULATED_FETCH_LATENCY);
};


const listsFetchStatus = (inputs, items, status, contextualTitle, header) => ({
    type     : LISTS_FETCHED,
    inputs   : inputs,
    items    : items,
    status   : status,
    favorites: Favorites.getAll(),
    contextualTitle: contextualTitle,
    header   : header,
});

/**
 * Fetch a list of items
 * @param  {object} inputs @see ListPage propTypes for description
 * @return {array/promise}
 */
export const fetchLists = inputs => {
    let status = {},
        items  = {},
        contextualTitles = [],
        header;

    (inputs || []).forEach(input => {
        let result;
        if (NON_CATEGORIES_DATA_TYPE.indexOf(input.dataType) !== -1) {

            if (input.parentId !== null && typeof input.parentId !== 'undefined') {
                // Fetch from parent category
                result = fetchContentOfCategory(input.parentId, input.parentType, input.dataType);
            } else {
                // Fetch all
                result = fetchAll(input.dataType);
            }

        } else if (CATEGORIES_DATA_TYPE.indexOf(input.dataType) !== -1) {
            result = fetchSubCategories(input.id, input.dataType);

        } else {
            // fatal error
            console.error(LOG_PREF+'Cannot fetch data for type: '+input.dataType);
            return;
        }

        status[input.dataType] = result.status;
        items[input.dataType] = result.items;
        if (result.contextualTitle) {
            contextualTitles.push(result.contextualTitle);
        }
        header = result.header;
    });

    return listsFetchStatus(inputs, items, status, contextualTitles.join(' & '), header);
};

function fetchAll(dataType) {
    let status,
        items;

    if (Db.isDataReady() !== true) {
        status = STATUS.PENDING;

    } else {
        items = Query.getAll(dataType);

        if (!items || items.length === 0) {
            status = STATUS.NO_RESULT;
        } else {
            status = STATUS.FETCHED;
        }
    }
    return {
        items   : items,
        status  : status,
        dataType: dataType,
    };
}

function fetchContentOfCategory(parentId, parentType, childrenDataType) {
    let status,
        items,
        contextualTitle,
        header,
        ad;

    if (Db.isDataReady() !== true) {
        status = STATUS.PENDING;

    } else {
        // Get parent
        let parent = Query.get(parentId, parentType);

        if (parent) {

            contextualTitle = parent.title;
            header = parent.lump ? parent.lump.header : null;
            ad = parent.lump ? parent.lump.ad : null;

            // Get children ids
            let ids = parent.lump[childrenDataType];
            if (Array.isArray(ids)) {

                // Get items
                items = ids.map(id => Query.get(id, childrenDataType));

                // Sort items
                items = Db.sortItems(items, childrenDataType);
            }
        }

        if (Array.isArray(items) !== true || items.length === 0) {
            status = STATUS.NO_RESULT;
        } else {
            status = STATUS.FETCHED;
        }
    }

    return {
        items   : items,
        status  : status,
        dataType: childrenDataType,
        contextualTitle: contextualTitle,
        header  : header,
        ad      : ad,
    };
}


/**
 * Fetch a category's content
 * @param  {number} id
 * @param  {string} dataType
 * @return {array/promise}
 */
function fetchSubCategories(id, dataType) {
    let status,
        items,
        contextualTitle,
        header;

    if (Db.isDataReady() !== true) {
        status = STATUS.PENDING;

    } else if (!Db.getData()[dataType] || Db.getData()[dataType].length === 0) {
        status = STATUS.NO_RESULT;

    } else {
        let subCategories;

        if (id === null || typeof id === 'undefined' || id === '') {
            // Get root categories
            items = Query.find([ cat => cat.parent_id === null ], dataType);

        } else {
            // Get sub-catagories of a category
            let category = Query.get(id, dataType);
            if (category) {
                contextualTitle = category.title;
                header = category.lump.header;

                subCategories = category.lump.cats;
                if (Array.isArray(subCategories) === true && subCategories.length > 0) {
                    items = subCategories.map(catId => Query.get(catId, dataType));
                }
            }
        }

        // Ignore empty categories
        if (Array.isArray(items)) {
            items = items.filter(item => item && item.counter > 0);
        }

        if (Array.isArray(items) !== true || items.length === 0) {
            status = STATUS.NO_RESULT;
        } else {
            status = STATUS.FETCHED;
        }
    }

    return {
        items,
        status,
        dataType,
        contextualTitle,
        header,
    };
}



export const groupedItemsFetched = (groupedItems, status, dataType, contextualTitle, ad) => ({
    type: GROUPED_ITEMS_FETCHED,
    groupedItems: groupedItems,
    status: status,
    ad: ad,
    contextualTitle: contextualTitle,
    favorites: Favorites.get(dataType),
});

export const fetchGroupedItems = input => dispatch => {
    let status,
        contextualTitle,
        ad,
        items,
        groupedItems,
        dataType = input.dataType;

    if (Db.isDataReady() !== true) {
        status = STATUS.PENDING;

    } else if (!Db.getData()[dataType] || Db.getData()[dataType].length === 0) {
        status = STATUS.NO_RESULT;

    } else {
        let parentId;
        if (input && input.parentId) {
            parentId = typeof input.parentId === 'number' ? input.parentId : parseInt(input.parentId, 10);
        }

        if (!input || !parentId) {
            // Get all items
            items = Query.getAll(dataType);
        }
        else {
            // Get items belonging to a specific category
            let result = fetchContentOfCategory(parentId, input.parentType, dataType);
            items = result.items;
            contextualTitle = result.contextualTitle;
            ad = result.ad;
        }

        if (items.length > 0) {
            // Execute group configuration
            groupedItems = groupItems(items, dataType);

            // Sort items in each group
            let sortConfig = getSortConfig()[dataType];
            if (sortConfig) {
                Object.keys(groupedItems).forEach(groupKey => {
                    groupedItems[groupKey].items = Db.sortItems(groupedItems[groupKey].items, dataType, sortConfig);
                });
            }
            status = STATUS.FETCHED;
        }
        else {
            status = STATUS.NO_RESULT;
        }
    }

    dispatch(groupedItemsFetched(groupedItems, status, dataType, contextualTitle, ad));
};


export const profileChanged = newProfile => ({
    type   : PROFILE_CHANGED,
    profile: newProfile,
});

export const showChooseLangDialog = () => ({
    type: SHOW_LANG_DIALOG,
});
export const hideChooseLangDialog = () => ({
    type: HIDE_LANG_DIALOG,
});

export const showLoginDialog = (username, password) => ({
    type: SHOW_LOGIN_DIALOG,
});
export const hideLoginDialog = (username, password) => ({
    type: HIDE_LOGIN_DIALOG,
});

export const startLogin = (username, password) => ({
    type: PERFORM_LOGIN,
    username: username,
    password: password,
});

export const performLogout = (username, password) => ({
    type: PERFORM_LOGOUT,
    username: username,
    password: password,
});

export const setLoginIdentification = (identification) => ({
    type: SET_LOGIN_IDENTIFICATION,
    identification: identification,
});

export const setLoginError = (error) => ({
    type: SET_LOGIN_ERROR,
    error: error,
});

export const hasLoggedOut = () => ({
    type: HAS_LOGGED_OUT,
});

export const showProfileDialog = () => ({
    type: SHOW_PROFILE_DIALOG,
});
export const hideProfileDialog = () => ({
    type: HIDE_PROFILE_DIALOG,
});

export const updateProfile = updatedProfile => ({
    type: UPDATE_PROFILE,
    profile: updatedProfile,
});

export const updatePageState = (pageKey, props) => ({
    type   : UPDATE_PAGE_STATE,
    pageKey: pageKey,
    props  : props,
});



export function toggleFavorite(id, dataType, isFav, noSync=false) {

    // Perform action
    const favListUpdated = Favorites.toggle(id, dataType, isFav);

    let result = {
        type: TOGGLE_FAVORITE,
        id,
        dataType,
        favListUpdated: true, // forced to true due to 'synchronize favorites' feature
        isFav,
        noSync,
    };

    if (favListUpdated) {
        result.favorites = JSON.parse(JSON.stringify(Favorites.getAll()));
    }
    return result;
};


export const allFavoritesDeleted = () => ({
    type: ALL_FAVORITES_DELETED,
});


export function fetchFavorites() {

    const favorites = Favorites.getAll();

    // Get full data for each entry
    let status,
        data = {};

    if (Db.isDataReady() !== true) {
        status = STATUS.PENDING;
    } else {
        for (let dataType in favorites) {
            if (favorites.hasOwnProperty(dataType) === true && VALID_DATA_TYPES.indexOf(dataType) !== -1) {
                data[dataType] = [];
                favorites[dataType].forEach((id) => {
                    let entry = Query.get(id, dataType);
                    if (!entry) {
                        console.warn('Could not find entry '+id+' of type: '+dataType);
                    } else {
                        data[dataType].push(entry);
                    }
                });

                // Sort
                data[dataType] = Db.sortItems(data[dataType], dataType);
            }
        }
        status = STATUS.FETCHED;
    }
    return {
        type     : FETCH_FAVORITES,
        favorites: favorites,
        data     : data,
        status   : status,
    };
};


export const setFavoritesSynchronizationStatus = status => ({
    type: SET_FAVORITES_SYNCHRONIZATION_STATUS,
    status,
});

export const synchroFavoritesIconClicked = () => ({
    type: SYNCHRO_FAVORITES_ICON_CLICKED,
});


export const windowResized = () => ({
    type: WINDOW_RESIZED,
    timestamp: new Date().getTime(),
});


// Search anything

export const performSearch = value => {
    const searchResult = search(value);
    return {
        type      : SEARCH_PERFORMED,
        searched  : value,
        status    : searchResult.status,
        results   : searchResult.data,
        totalCount: searchResult.totalCount,
        favorites : Favorites.getAll(),
    };
};

export const clearSearchResults = () => ({
    type  : CLEAR_SEARCH_RESULTS,
    status: STATUS.FETCHED,
});


// Place search

export function performPlaceSearch(string, searchType) {
    const searchResult = searchPlaces(string);
    return {
        type      : PLACE_SEARCH_PERFORMED,
        searched  : string,
        searchType: searchType,
        status    : searchResult.status,
        results   : searchResult.data,
        totalCount: searchResult.totalCount,
    };
};

export function searchedPlaceSelected(searchType, entry) {
    if (!entry.text) {
        let _elementPropsGetters = elementPropsGetters(entry.type),
            item = Query.get(entry.id, entry.type);
        if (item && _elementPropsGetters) {
            entry.text = _elementPropsGetters.text(item);
            entry.textMinor = _elementPropsGetters.textMinor(item);
        }
    }
    return {
        type      : SEARCHED_PLACE_SELECTED,
        searchType: searchType,
        entry     : entry,
    };
}

export const clearPlaceResults = type => ({
    type      : CLEAR_PLACE_RESULTS,
    status    : STATUS.FETCHED,
    searchType: type,
});



function toggleMenu(pageKey, isOpen) {
    if (!pageKey || typeof pageKey !== 'string') {
        console.error(LOG_PREF+'Cannot '+(isOpen ? 'open' : 'close')+' menu, missing page key', pageKey);
        return;
    }
    return {
        type   : TOGGLE_MENU,
        pageKey: pageKey,
        isOpen : isOpen,
    };
};
export const openMenu  = pageKey => toggleMenu(pageKey, true);
export const closeMenu = pageKey => toggleMenu(pageKey, false);


export function showDataListDialog(idsByType, placeId, pageKey) {

    let items = {};
    Object.keys(idsByType).forEach(dataType => {
        items[dataType] = idsByType[dataType].map(id => Query.get(id, dataType));
        items[dataType].forEach(item => {
            item.contextualPlaceId = placeId;
        });
        items[dataType] = Db.sortItemsForDataListDialog(items[dataType], dataType);
    });

    let title, place;
    if (typeof placeId === 'number') {
        place = Query.get(placeId, DATA_TYPE_PLACES);
        if (place) {
            title = place.label;
        }
    }

    return {
        type     : SHOW_DATA_LIST_DIALOG,
        items    : items,
        placeId  : placeId,
        title    : title,
        pageKey  : pageKey,
        favorites: Favorites.getAll(),
    };
};


export const hideDataListDialog = () => ({
    type: HIDE_DATA_LIST_DIALOG,
});


export const adConfigUpdated = () => ({
    type: AD_CONFIG_UPDATED,
})

export const adConfigLoaded = adConfig => ({
    type: AD_CONFIG_LOADED,
    adConfig: adConfig,
});

export const pollConfigUpdated = () => ({
    type: POLL_CONFIG_UPDATED,
})

export const pollConfigLoaded = pollConfig => ({
    type: POLL_CONFIG_LOADED,
    pollConfig: pollConfig,
});

export const validatePollCode = (poll_id, poll_code) => ({
    type: VALIDATE_POLL_CODE,
    poll_id,
    poll_code
})

export const validatePoll = (poll_id, poll_code) => ({
    type: VALIDATE_POLL,
    poll_id,
    poll_code
})

export const setPollId = (poll_id) => ({
    type: SET_POLL_ID,
    poll_id
})

export const setPollCode = (poll_code) => ({
    type: SET_POLL_CODE,
    poll_code
})

export const showPollDialog = (poll_id) => ({
    type: SHOW_POLL_DIALOG,
    poll_id
});

export const hidePollDialog = () => ({
    type: HIDE_POLL_DIALOG
});

export const goToNextPollStep = (question_id) => ({
    type: GO_TO_NEXT_POLL_STEP,
    question_id
});

export const goToPreviousPollStep = (question_id) => ({
    type: GO_TO_PREVIOUS_POLL_STEP,
    question_id
});

export const submitPoll = (poll_id) => ({
    type: SUBMIT_POLL,
    poll_id
})

export const setPollStep = (poll_id, question_id, data) => ({
    type: SET_POLL_STEP,
    poll_id,
    question_id,
    data
})

export const setPollError = (error) => ({
    type: SET_POLL_ERROR,
    error
})

export const setPollPage = (page) => ({
    type: SET_POLL_PAGE,
    page
})

export const setPollMark = (value) => ({
    type: SET_POLL_MARK,
    value
})

export const setPollComment = (value) => ({
    type: SET_POLL_COMMENT,
    value
})

export const setPollChoice = (value) => ({
    type: SET_POLL_CHOICE,
    value
})

export const setPollMultiple = (value) => ({
    type: SET_POLL_MULTIPLE,
    value
})

export const fetchContributionsFeed = (userAction) => ({
    type: FETCH_CONTRIBUTIONS_FEED,
    userAction
})

export const contributionsFeedLoaded = (payload) => ({
    type: CONTRIBUTIONS_FEED_LOADED,
    payload
})

export const setContributionsFeedError = (error) => ({
    type: SET_CONTRIBUTIONS_FEED_ERROR,
    error
})

export const activateContributionsRefresh = (activate) => ({
    type: ACTIVATE_CONTRIBUTIONS_REFRESH,
    activate
})

export const fetchSocialFeed = (payload) => ({
    type: FETCH_SOCIAL_FEED,
    payload
})

export const socialFeedLoaded = (platform, posts, initialPayload) => ({
    type: SOCIAL_FEED_LOADED,
    platform,
    posts,
    initialPayload
})

export const setSocialFeedError = (error) => ({
    type: SET_SOCIAL_FEED_ERROR,
    error
})

export const activateSocialFeedRefresh = (activate) => ({
    type: ACTIVATE_SOCIAL_FEED_REFRESH,
    activate
})

export const showIntersticiel = (appIsBooting, next) => ({
    type: SHOW_INTERSTICIEL,
    appIsBooting: appIsBooting,
    next: next,
});

export const hideIntersticiel = () => ({
    type: HIDE_INTERSTICIEL,
});

export const showIntersticielCloseButton = () => ({
    type: SHOW_INTERSTICIEL_CLOSE_BUTTON,
});

export const hideIntersticielCloseButton = () => ({
    type: HIDE_INTERSTICIEL_CLOSE_BUTTON,
});


export const showFullLoader = () => ({
    type: SHOW_FULL_LOADER,
});

export const hideFullLoader = () => ({
    type: HIDE_FULL_LOADER,
});


export const flightScheduleClicked = id => ({
    type: FLIGHT_CLICKED,
    data: id
});


export const linkClicked = url => ({
    type: LINK_CLICKED,
    url : url,
});


export const showNotification = opts => Object.assign({ type: SHOW_NOTIF }, opts);

export const removeNotification = notif => ({
    type: REMOVE_NOTIF,
    notification: notif,
});


export const sendAppointmentRequest = (dataId, dataType, dataOriginalId) => ({
    type: SEND_APPOINTMENT_REQUEST,
    dataId,
    dataType,
    dataOriginalId,
});
export const appointmentRequestSent = (dataId, dataType) => ({
    type: APPOINTMENT_REQUEST_SENT,
    dataId,
    dataType,
});
export const appointmentRequestSendResult = (success, dataOriginalId, dataType, dataId, status) => ({
    type: APPOINTMENT_REQUEST_SEND_RESULT,
    success,
    dataOriginalId,
    dataType,
    dataId,
    status,
});

export const realTimeConnected = () => ({
    type: REAL_TIME_CONNECTED,
});
export const realTimeDisconnected = () => ({
    type: REAL_TIME_DISCONNECTED,
});
