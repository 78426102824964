
import React, { PureComponent  } from 'react';
import PropTypes from 'prop-types';
import config from 'data/config/config';

import Button         from 'src/components/button';
import List           from 'src/components/list/List';
import TypeBar        from 'src/components/type-bar/TypeBar';
import NoResult       from 'src/components/no-result/NoResult';
import AllOnMapButton from 'src/components/all-on-map-button/AllOnMapButton';
import {stripToIds}   from 'src/pages/map/mapUtil';
import { get as getProfile } from 'src/core/Profile';


class FavoritesContent extends PureComponent {

    loginButton(){
        if(getProfile() !== config.DEFAULT_PROFILE) return null;
        return (
            <div>
                <Button onClick={() => { this.props.actions.showLoginDialog(); }} >
                    {[
                        <span key="text">{this.props.isLoggedIn ? this.props.labels.login.logout : this.props.labels.login.title}</span>,
                        <span key="icon" className={'fa fa-user ' + (this.props.isLoggedIn ? 'connected' : '')}
                            style={{ fontSize: "1.6em", marginLeft: ".5em" }} />
                    ]}
                </Button>
            </div>
        )
    }

    render() {
        let typedLists = [];

        if (this.props.favorites) {
            Object.keys(this.props.favorites).forEach(dataType => {
                let entries = this.props.favorites[dataType],
                    items = this.props.data[dataType];

                // Ignore this dataType if it has no data
                if (entries.length && items && items.length) {
                    const
                        count = items.length,
                        listProps = {
                            items    : items,
                            dataType : dataType,
                            favorites: entries,
                            isPending: this.props.isPending,
                            actions  : this.props.actions,
                            labels   : this.props.labels,
                            pageKey  : this.pageKey,
                            displayFavorites: true,
                        };
                    typedLists.push(
                        <TypeBar
                            key={ dataType }
                            label={ this.props.labels.data[dataType][count > 1 ? 'plural' : 'singular'] }
                            count={ count }>

                            <List { ...listProps } />
                        </TypeBar>
                    );
                }
            });
        }

        return (
            <div className="favorites-content content-font" style={{ height: this.props.height }}>

                {this.props.isSynchroFavFeatureEnabled === true &&
                    <div className="favorites-synchro-notice">
                        { this.props.isLoggedIn ? this.props.labels.synchroFavs.favoritesPageNoticeLoggedIn : this.props.labels.synchroFavs.favoritesPageNotice }
                        { this.loginButton() }
                    </div>
                }

                { typedLists.length === 0
                    ? <NoResult labels={this.props.labels} customLabel={this.props.labels.favorites.noResults}/>

                    : <div>
                        <AllOnMapButton
                            labels={this.props.labels}
                            actions={this.props.actions}
                            data={stripToIds(this.props.data)} />

                        <div>
                            { typedLists }
                        </div>
                      </div>
                }
            </div>
        );
    }
}

FavoritesContent.propTypes = {
    data     : PropTypes.object,
    favorites: PropTypes.object,
    isPending: PropTypes.bool,
    isSynchroFavFeatureEnabled: PropTypes.bool,
    isLoggedIn : PropTypes.bool,
    // Common
    labels : PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    height : PropTypes.number, // prop set from AppToolbar
};

export default FavoritesContent;