
// Libs
import React, { Component } from 'react';
import PropTypes    from 'prop-types';
import ReactDOM     from 'react-dom';
import iscroll      from 'iscroll';
import { debounce } from 'lodash';

// conf
import { elementPropsGetters, getSideIndexPosition } from 'data/config/listConfig';
import { VALID_DATA_TYPES } from 'data/config/dataConfig';
import { ISCROLL_OPTIONS, REFRESH_DELAY } from 'data/config/iScrollConfig';

// App modules
import NoResult          from 'src/components/no-result/NoResult';
import List              from 'src/components/list/List';
import ListLoaderElement from 'src/components/list/ListLoaderElement';
import SideIndex         from 'src/components/side-index/SideIndex';
import { isIOS }         from 'src/core/util/browser';

import './AlphabeticalList.scss';


const LOG_PREF = '[AlphabeticalList] ';

const letterRegExp = /[a-zA-Z]/;


class AlphabeticalList extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    setComponentEl = el => {
        this.componentEl = el;
    }

    setListContainerEl = el => {
        this.listContainerEl = el;

        // Init or refresh iScroll
        if (this.listContainerEl !== null && isIOS()) {
            if (this.iScrollInstance) {
                this.refreshIScroll();
            } else {
                this.iScrollInstance = new iscroll(this.listContainerEl, ISCROLL_OPTIONS);
            }
        }
    }

    scrollTo = value => {
        if (this.iScrollInstance) {
            this.iScrollInstance.scrollTo(0, value*-1);
        } else {
            this.listContainerEl.scrollTop = value;
        }
    }

    refreshIScroll = debounce(() => {
        if (this.iScrollInstance) {
            window.setTimeout(() => {
                this.iScrollInstance.refresh();
                console.log(LOG_PREF+'refreshed iscroll');
            }, REFRESH_DELAY);
        }
    }, 50);

    componentDidMount(){
        this.refreshIScroll();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.dataType !== nextProps.dataType) {
            this.setState({
                height: 'auto',
            });
        }
    }

    componentDidUpdate(prevProps) {
        this.refreshIScroll();

        // If datatype or array length have changed, reset scroll to 0
        if (this.props.dataType !== prevProps.dataType
                || typeof this.props.items !== typeof prevProps.items
                || (Array.isArray(this.props.items) === true && this.props.items.length !== prevProps.items.length)) {

            window.setTimeout(this.scrollTo, 100 , 0);
        }
    }

    getListContainerClassNames() {
        let classNames = ['alphalist-container'];

        // Determine side index position (left or right, default: right)
        let sideIndexPosition = getSideIndexPosition();
        if (sideIndexPosition === 'left') {
            classNames.push('alphalist-container-right');
        }

        return classNames.join(' ');
    }

    /**
     * Iterate on every entry to:
     *   - identify all different first letters
     *   - group entries by their first letter
     *
     * @return {object}
     */
    parseIndexes(items, dataType, indexLetterGetter) {

        let separatorsAdded = {},
            helpers = elementPropsGetters(dataType),
            itemsWithSeparators = [],
            separatorEls = {};

        items.forEach((item, index) => {
            let char,
                defaultText = helpers.text(item);

            if (typeof indexLetterGetter === 'function') {
                char = indexLetterGetter(item, defaultText);
            }
            else {
                // default: extract first character of the string
                if (!defaultText) {
                    return;
                }
                char = defaultText.slice(0,1).toUpperCase();
            }


            if (!letterRegExp.test(char)) {
                // Non letters (numbers, special characters, etc)
                char = '#';
            }

            if (typeof separatorsAdded[char] === 'undefined') {
                // Insert a separator object in items array
                itemsWithSeparators.push({
                    isSeparator: true,
                    text: char,
                    ref : instance => {
                        separatorEls[char] = ReactDOM.findDOMNode(instance);
                    },
                });
                separatorsAdded[char] = true;
            }

            itemsWithSeparators.push(item);
        });

        return {
            alphabeticalIndex: Object.keys(separatorsAdded),
            itemsWithSeparators,
            separatorEls,
        };
    }

    render() {
        console.log(LOG_PREF+'render');

        if (!this.props.dataType) {
            console.error(LOG_PREF+'Missing `dataType` property');
            return;
        }
        // Pending request
        if (this.props.isPending === true) {
            return <ul className="list-component"><ListLoaderElement labels={this.props.labels} /></ul>;
        }
        // Nothing found
        if ((!this.props.items || !this.props.items.length) && this.props.isPending !== true) {
            return <NoResult labels={this.props.labels} />;
        }


        // Iterate on labels to determine indexes
        let parseResult = this.parseIndexes(
                                    this.props.items,
                                    this.props.dataType,
                                    this.props.indexLetterGetter);

        return (
            <div className="alphalist-component" ref={this.setComponentEl}>
                <div
                    ref={this.setListContainerEl}
                    className={this.getListContainerClassNames()}
                    style={{ height: this.props.height }}>

                    <List
                        items={parseResult.itemsWithSeparators}
                        dataType={this.props.dataType}
                        favorites={this.props.favorites}
                        actions={this.props.actions}
                        labels={this.props.labels}
                        isPending={this.props.isPending}
                        isFastAndUgly={this.props.isFastAndUgly}
                        displayFavorites={this.props.displayFavorites}
                        pageKey={this.props.associatedPageKey} />
                </div>

                <SideIndex
                    indexes={parseResult.alphabeticalIndex}
                    height={this.state.height}
                    separatorEls={parseResult.separatorEls}
                    scrollTo={this.scrollTo} />

            </div>
        );
    }
};

AlphabeticalList.propTypes = {
    items            : PropTypes.array,
    dataType         : PropTypes.oneOf(VALID_DATA_TYPES).isRequired,
    favorites        : PropTypes.array,
    isPending        : PropTypes.bool,
    displayFavorites : PropTypes.bool,
    isFastAndUgly    : PropTypes.bool,
    height           : PropTypes.number,
    associatedPageKey: PropTypes.string.isRequired,

    // i18n labels (set for current language)
    labels: PropTypes.object.isRequired,

    // if specified, any occurence of this string will be emphasised
    highlight: PropTypes.string,

    clickOnTypeBar: PropTypes.func,
};

export default AlphabeticalList;
