'use strict';

var defaultConf = require('./config-default');

var NODE_BACKEND_DOMAIN = 'pisc-node-backend-dev.mobile-spot.com'; // const NODE_BACKEND_LOCAL_DOMAIN = 'localhost:3084';

var BO_URL = 'pisc-data-dev.mobile-spot.com';
module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: BO_URL,
  BO_PATH: '/home/www/pisc/pisc2019/pisc-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_SERVER_ALIAS: 'kim1',
  DEPLOY_PATH: '~/pisc/pisc2019/pisc-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: '',
  FACEBOOK_APP_ID: '652773898485294',
  // GCM/FCM send id
  projectId: '1010219384570',
  // pushwoosh appid
  appId: '63EA1-CD495',
  LOGIN: {//  URL: `http://${NODE_BACKEND_DOMAIN}/auth/token`,
    // expiryHours: 0.5, //Infinite if not present
    // autologinDelay: 120, //delay in seconds
  },
  POLL: {
    URL: "http://".concat(NODE_BACKEND_DOMAIN, "/poll")
  },
  CONTRIBUTIONS_FEED: {//  URL: `http://${BO_URL}/misc/contributions.json`
  },
  SOCIAL: {
    FEATURE_ENABLED: true,
    FACEBOOK: {
      POST_PER_PAGE: 5
    },
    TWITTER: {
      POST_PER_PAGE: 10
    },
    INSTAGRAM: {
      POST_PER_PAGE: 5
    },
    YOUTUBE: {
      POST_PER_PAGE: 5
    },
    LINKEDIN: {
      POST_PER_PAGE: 10
    },
    URL: "http://".concat(NODE_BACKEND_DOMAIN, "/social")
  }
});