
import config from 'data/config/config';

import fetchHelper from 'src/core/util/FetchHelper';

import {
    VALIDATE_POLL_CODE,
    SUBMIT_POLL
} from 'src/store/actionTypes';

import {
	setPollError,
	setPollPage,
	setPollId,
	setPollCode,
    showPollDialog,
	showLoginDialog,
	hidePollDialog
} from 'src/store/actions';

import { pollSubmitted } from 'src/core/polls/PollManager'

export const ERRORS = {
	SUBMITTED: 'SUBMITTED',
	OUTDATED_BEFORE: 'OUTDATED_BEFORE',
	OUTDATED_AFTER: 'OUTDATED_AFTER',
	FILLED: 'FILLED',
	CODE: 'CODE',
	NO_CODE: 'NO_CODE',
	NO_ID: 'NO_ID',
    LOGIN  : 'LOGIN',
    NETWORK: 'NETWORK',
	SERVER: 'SERVER'
};

let dispatch;
export function setReduxStore(store) {
    dispatch = store.dispatch;
}

export function setError(error, detail) {
	dispatch(setPollError(error, detail));
}

export function setPage(page) {
	dispatch(setPollPage(page))
}

export function setId(poll_id) {
	dispatch(setPollId(poll_id))
}

export function setCode(poll_code) {
	dispatch(setPollCode(poll_code))
}

export function showDialog(poll_id) {
    dispatch(showPollDialog(poll_id))
}

export const ERROR_MANAGEMENT_TABLE = {
	NO_ID: () => setPage('list'),
	NO_CODE: () => setPage('form'),
	CODE: () => setPage('form'),
	LOGIN  : () => {
		dispatch(hidePollDialog())
		dispatch(showLoginDialog())
	},
	SUBMITTED: () => setPage('submit'),
	FILLED: (context) => {
		if (context === VALIDATE_POLL_CODE) {
			setPage('formValid')
		}
		else if (context !== SUBMIT_POLL) {
			setPage('submit')
		}
	},
	OUTDATED_BEFORE: () => setPage('error'),
	OUTDATED_AFTER: () => setPage('error'),
    NETWORK: () => setPage('error'),
	SERVER: () => setPage('server')
}

/**
 * Call the API to submit the poll
 * @param  {object}   data
 */
export function submit(poll, poll_id) {
    let opts = {
        method: 'POST',
        body: JSON.stringify(poll),
        headers: [{ name: 'Content-Type', value: 'application/json' }],
    };

    fetchHelper(
        config.POLL.URL,
        opts,
        true, // isJson
        data => { _onApiCallSuccess(data, poll_id); },
		() => { _onApiCallFailure(poll)},
        false // showModalOnError
    );
}

function _onApiCallSuccess(data, poll_id) {
    if (data.success) {
        pollSubmitted(poll_id)
	    dispatch(setPollPage('submitted'));
    }
    else {
		// No need to switch page since the server error is handled by the submit page
		dispatch(setPollError(ERRORS.SERVER));
    }
}

function _onApiCallFailure(poll) {
	// No need to switch page since the network error is handled by the submit page
    dispatch(setPollError(ERRORS.NETWORK));
}
