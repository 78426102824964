
import {
    SET_LOGIN_IDENTIFICATION,
    SET_LOGIN_ERROR,
    PERFORM_LOGIN,
    HAS_LOGGED_OUT,
} from 'src/store/actionTypes';

import { login, onUserLogIn } from './LoginService';


let isLoggedIn = false;

export default ({ dispatch, getState }) => next => action => {

    switch (action.type) {

        case SET_LOGIN_IDENTIFICATION:
            if (action.identification) {
                // Logged in
                if (isLoggedIn !== true) {
                    onUserLogIn();
                }
                isLoggedIn = true;
            } else {
                isLoggedIn = false;
            }
            break;

        case SET_LOGIN_ERROR:
            isLoggedIn = false;
            break;

        case PERFORM_LOGIN:
            login(action.username, action.password);
            break;

        case HAS_LOGGED_OUT:
            isLoggedIn = false;
            break;


        default: // for linter
    }

    return next(action);
}