
// conf
import config from 'data/config/config';

// app modules
import { navigate } from 'src/store/actions';

const LOG_PREF = '[ConfigItemFactory] ';


let reduxStore;

export const setReduxStore = (store) => {
    reduxStore = store;
};


export const TYPES = {
    // Execute a random function
    ACTION: 'ACTION',

    // Navigate to a page (= ACTION where function is `reduxStore.dispatch(navigate(...))`)
    ROUTE : 'ROUTE',
};


function _itemFactory(style, label, type, func, entryOptions) {

    // Check arguments
    if (typeof label !== 'string') {
        console.error(LOG_PREF+'Unexpected label of type '+typeof label);
    }
    if (typeof TYPES[type] === 'undefined') {
        console.error(LOG_PREF+'Unexpected item type '+type+', supported values are:', TYPES);
    }
    if (typeof func !== 'function') {
        console.error(LOG_PREF+'Unexpected action of type '+typeof func+' for '+label);
    }

    return {
        style : style,
        label : label,
        type  : type,
        action: (associatedPageKey, storeActions) => {
            // Applying timeout here seems less error prone than setting it in each menu/*MenuItem.js
            window.setTimeout(() => {
                console.log('storeActions', storeActions)
                func(storeActions);
            }, config.DELAY_BEFORE_CLICK_ACTION);
        },
        options: entryOptions,
    };
};


export const createItem = (style, label, action, entryOptions) => (
    _itemFactory(style, label, TYPES.ACTION, action, entryOptions)
);


export const createItemRoute = (style, label, pageKey, options, entryOptions) => (
    _itemFactory(
        style,
        label,
        TYPES.ROUTE,
        () => { reduxStore.dispatch(navigate(pageKey, options)); },
        entryOptions
    )
);
