
import { SEND_APPOINTMENT_REQUEST } from 'src/store/actionTypes';

import { get as getLabels } from 'src/core/Lang';

import { sendRequest } from './AppointmentRequestService';
import * as ProfileService from 'src/core/profile/ProfileService';

import {
    appointmentRequestSent,
    showNotification,
    showProfileDialog,
} from 'src/store/actions';


export default ({ dispatch, getState }) => next => action => {
    switch (action.type) {

        case SEND_APPOINTMENT_REQUEST:
            let profile = ProfileService.getProfile();

            if (ProfileService.isProfileEnoughSet(profile)) {
                // Profile OK, send request
                sendRequest(action.dataOriginalId, action.dataType, action.dataId, profile);
                dispatch(appointmentRequestSent(action.dataId, action.dataType));
            }
            else {
                // Modale indicating to set missing `profile` value
                dispatch(showNotification({
                    message: getLabels().appointment.setProfileFirst,
                }));

                // Show `profile` modale
                dispatch(showProfileDialog());
            }
            break;

        default:
    }

    return next(action);
};
