
import ons from 'onsenui';

import getInAppBrowserOptions from 'data/config/inAppBrowserOptions';
import { isAndroid } from 'src/core/util/browser';
import * as Lang from 'src/core/Lang';
import { parseUrl, queuedNavigation } from 'src/core/navigation/Router';

import { navigateBack } from 'src/store/actions';

const LOG_PREF = '[CordovaHelper] ';


export function init(store) {

    if (global.isCordovaContext !== true) {
        return;
    }

    ons.ready(() => {
        // ANDROID BACK BUTTON BEHAVIOUR
        ons.setDefaultDeviceBackButtonListener(e => {
            store.dispatch(navigateBack());
        }, false);
    });


    // IN APP BROWSER
    if (cordova.InAppBrowser) {
        window.open = function(url, target) {
            let options = getInAppBrowserOptions(
                isAndroid() ? 'android' : 'ios',
                Lang.get(),
            );
            cordova.InAppBrowser.open(url, target, options);
        };
    }

    /**
     * Handle when app is opened via an url
     * e.g http://ph-dev.mobile-spot.com/?/service&originalId=5c442084-9ee7-e811-80d9-005056ae0696&lang=fr&profile=default
     */
    if (typeof window.MobiGeoCordova.getIntent === 'function') {
        window.MobiGeoCordova.getIntent(
            function getIntentSuccessCb(intentUri) {
                if (intentUri) {
                    console.log(LOG_PREF+'App started with intent url: ' + intentUri);
                    queuedNavigation.set(() => parseUrl(intentUri));
                }
            },
            function getIntentFailureCb(...args) {
                console.error(LOG_PREF+'Failed to execute window.MobiGeoCordova.getIntent', args);
            }
        );
    }
};

