
import { HAS_NAVIGATED } from 'src/store/actionTypes';

import { LIST_PAGE_KEY } from 'src/pages/pagesKeys';

import { isIOS } from 'src/core/util/browser';
import performWithPageHack from 'src/core/util/iosWhitePageHack';


export default ({ dispatch, getState }) => next => action => {
    let result = next(action);

    switch (action.type) {

        case HAS_NAVIGATED:
            if (action.pageKey === LIST_PAGE_KEY && global.isCordovaContext && isIOS()) {
                // iOS white page hack
                performWithPageHack(LIST_PAGE_KEY);
            }
            break;

        default: // for linter
    }
    return result;
}