import {
	FETCH_SOCIAL_FEED,
	SOCIAL_FEED_LOADED,
	SET_SOCIAL_FEED_ERROR,
    HAS_NAVIGATED,
    AD_CONFIG_LOADED
} from 'src/store/actionTypes';

import {
	showNotification,
    fetchSocialFeed
} from 'src/store/actions';

import {
	fetch,
	persistence
} from 'src/core/social/SocialService';

import {
	MEDIAS_PAGE_KEY
} from 'src/pages/pagesKeys';




export default ({
	dispatch,
	getState
}) => next => action => {
	const state = getState()
	const mediasPageState = state[MEDIAS_PAGE_KEY]
	const labels = mediasPageState ? mediasPageState.labels : null
    const socialMediaConfig = mediasPageState ? mediasPageState.socialMediaConfig : null
    const enabledPlatforms = socialMediaConfig ? Object.keys(socialMediaConfig) : []

	switch (action.type) {
        case AD_CONFIG_LOADED:
            enabledPlatforms.forEach(platform => {
                dispatch(fetchSocialFeed({ type: platform, initial: true }))
            })
            break;
        case HAS_NAVIGATED:
            if (action.pageKey === MEDIAS_PAGE_KEY) {
                enabledPlatforms.forEach(platform => {
                    dispatch(fetchSocialFeed({ type: platform, initial: true }))
                })
            }
            break;
    	case FETCH_SOCIAL_FEED:
    		fetch(action.payload);
    		break;

    	case SOCIAL_FEED_LOADED:
    		persistence.set(action.payload);
    		break;

    	case SET_SOCIAL_FEED_ERROR:
    		if (labels) {
    			dispatch(showNotification({
    				message: labels.common.noNetworkTitle
    			}))
    		}
    		break;

    	default:
	}
	return next(action);
};
