import { DATA_TYPE_EXHIBITORS, DATA_TYPE_NEWPRODUCT_CATEGORIES, DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_SPEAKERS, DATA_TYPE_SERVICE_CATEGORIES } from './dataConfig';
import { DEFAULT_PROFILE } from './config';
import CategoriesHack from './CategoriesHack';
import { createItem, createItemLang, createItemRoute, createItemLogin, createItemProfile } from 'src/core/util/ConfigItemFactory';
import * as Db from 'src/core/data-and-assets/Db';
import { startUpdate } from 'src/core/data-and-assets/Updater';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import config from 'data/config/config';
import { AGENDA_PAGE_KEY, FAVORITES_PAGE_KEY, HOME_PAGE_KEY, INBOX_PAGE_KEY, LIST_PAGE_KEY, MAP_PAGE_KEY, MEDIAS_PAGE_KEY, SEARCH_PAGE_KEY, CHOOSE_PROFILE_PAGE_KEY } from 'src/pages/pagesKeys';
import { openUrl } from 'src/core/util/JsTools';
var LOG_PREF = '[menuConfig] '; // @see https://onsen.io/v2/docs/react/SplitterSide.html

export var ONS_MENU_OPTIONS = {
  side: 'right',
  width: 210,
  swipeTargetWidth: '100%',
  collapse: true,
  swipeable: true
};
var ICON_SIZE = '70%';

var getItemRouteHome = function getItemRouteHome(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/home.svg') + ')',
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  labels.menu.home, // page key:
  HOME_PAGE_KEY);
};

var getItemRouteExhibitors = function getItemRouteExhibitors(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/Sponsors.svg') + ')',
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  labels.menu.exhibitors, // page key:
  AGENDA_PAGE_KEY, // page props:
  {
    input: {
      dataType: DATA_TYPE_EXHIBITORS
    }
  });
};

var getItemRouteNewproducts = function getItemRouteNewproducts(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/nouveautés.svg') + ')',
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  labels.menu.newproducts, // page key:
  LIST_PAGE_KEY, // page props:
  {
    locateAll: false,
    inputs: [{
      dataType: DATA_TYPE_NEWPRODUCT_CATEGORIES
    }]
  });
};

var getItemRouteServices = function getItemRouteServices(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/infos-pratiques.svg') + ')',
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  labels.menu.services, // page key:
  LIST_PAGE_KEY, // page props:
  {
    locateAll: false,
    inputs: [{
      dataType: DATA_TYPE_SERVICE_CATEGORIES
    }]
  });
};

var getItemRouteMap = function getItemRouteMap(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/Plan.svg') + ')',
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  labels.menu.map, // page key:
  MAP_PAGE_KEY);
};

var getItemRouteSocialMedia = function getItemRouteSocialMedia(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/SocialMedia.svg') + ')',
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  labels.menu.socialmedia, // page key:
  MEDIAS_PAGE_KEY);
};

var getItemRouteProgram = function getItemRouteProgram(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/Programme.svg') + ')',
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  labels.menu.events, // page key:
  LIST_PAGE_KEY, // page props:
  {
    locateAll: false,
    inputs: [{
      dataType: DATA_TYPE_EVENT_CATEGORIES
    }]
  });
};

var getItemRouteSpeakers = function getItemRouteSpeakers(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/Intervenants.svg') + ')',
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  labels.menu.speakers, // page key:
  LIST_PAGE_KEY, // page props:
  {
    contextualTitle: "",
    inputs: [{
      dataType: DATA_TYPE_SPEAKERS
    }]
  });
};

var getItemRouteSearch = function getItemRouteSearch(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/search.svg') + ')',
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  labels.search.title, // page key:
  SEARCH_PAGE_KEY);
};

var getItemRouteInbox = function getItemRouteInbox(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/inbox.svg') + ')',
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  labels.menu.inbox, // page key:
  INBOX_PAGE_KEY);
};

var getItemLang = function getItemLang(labels, actions) {
  return createItem({
    icon: {
      className: 'flag-icon flag-' + labels.id
    }
  }, labels.menu.language, actions.showChooseLangDialog);
};

var getItemLogin = function getItemLogin(labels, actions) {
  return createItem(null, labels.menu.login, actions.showLoginDialog);
};

var getItemProfile = function getItemProfile(labels, actions) {
  return createItem({
    icon: {
      className: 'fa fa-user',
      style: {
        fontSize: '1.5em',
        lineHeight: '1.3em',
        color: 'grey'
      }
    }
  }, labels.menu.profile, actions.showProfileDialog);
};

var getItemRouteFavorites = function getItemRouteFavorites(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/favourites.svg') + ')',
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  labels.favorites.title, // page key:
  FAVORITES_PAGE_KEY);
};

var getAdMenuButton = function getAdMenuButton(conf, labels, actions) {
  return !conf ? null : createItem( // style:
  {
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl(conf['img_' + labels.id]) + ')',
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  conf['name_' + labels.id], // action:
  function () {
    var url = conf['link_' + labels.id];

    if (openUrl(url)) {
      actions.linkClicked(url);
    }
  });
};

var getActionNetworkingSwapcard = function getActionNetworkingSwapcard(labels, actions, adConfig) {
  return getAdMenuButton(adConfig && adConfig.menubuttons && adConfig.menubuttons.menubutton1, labels, actions);
};

var getActionCGU = function getActionCGU(labels, actions, adConfig) {
  return getAdMenuButton(adConfig && adConfig.menubuttons && adConfig.menubuttons.menubutton2, labels, actions);
};

var createItemPollButton = function createItemPollButton(labels, actions) {
  return createItem( // style:
  {
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/polls.svg') + ')',
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  labels.menu.poll, // Action to perform on click
  actions.showPollDialog);
};
/**
 * Menu configuration
 */


var getConfig = function getConfig(actions, labels, adConfig, twoColumns, profile) {
  var conf = {
    default: [getItemRouteHome(labels), // getItemRouteNewproducts(labels),
    getItemRouteProgram(labels), getItemRouteSpeakers(labels), getItemRouteExhibitors(labels), // getItemRouteServices(labels),
    getItemRouteMap(labels), getItemRouteSocialMedia(labels), // createItemPollButton(labels, actions),
    getItemRouteSearch(labels), getItemRouteFavorites(labels)]
  };

  if (config.PROFILE.FEATURE_ENABLED === true) {
    conf.default.push(getItemProfile(labels, actions));
  }

  conf.default.push(getActionCGU(labels, actions, adConfig)); // if (adConfig && adConfig.menubuttons) {
  //     Object.keys(adConfig.menubuttons).forEach(menuBtnKey => {
  //         conf.default.push( getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions) );
  //     });
  // }
  // Filter any empty entry

  Object.keys(conf).forEach(function (profile) {
    conf[profile] = conf[profile].filter(function (menuItemConfig) {
      return menuItemConfig;
    });
  });
  return conf;
};
/**
 * Return menu configuration
 * @param  {string} profile
 * @return {object}
 */


export var get = function get() {
  var profile = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DEFAULT_PROFILE;
  var page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'default';
  var actions = arguments.length > 2 ? arguments[2] : undefined;
  var labels = arguments.length > 3 ? arguments[3] : undefined;
  var adConfig = arguments.length > 4 ? arguments[4] : undefined;
  var twoColumns = arguments.length > 5 ? arguments[5] : undefined;
  var conf = getConfig(actions, labels, adConfig, twoColumns, profile); // Return config depending on page

  if (conf) {
    return conf[page] || conf.default;
  } // else undefined is returned

};