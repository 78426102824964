

import { windowResized } from 'src/store/actions';


let reduxStore,
    hold = false;


const applyResize = () => {
    reduxStore.dispatch(windowResized());
    hold = false;
};

const start = () => {
    if (hold !== true) {
        hold = true;
        window.requestAnimationFrame(applyResize);
    }
};


export const setReduxStore = (store) => {
    reduxStore = store;
};


export const init = () => {
    window.addEventListener('resize', start);
};