export default {
  id: 'fr',
  common: {
    appTitle: 'Pisc 2019',
    colon: ': ',
    noResult: 'Aucun résultat',
    yes: 'Oui',
    no: 'Non',
    ok: 'OK',
    confirm: 'Valider',
    cancel: 'Annuler',
    close: 'Fermer',
    keep: 'Conserver',
    delete: 'Effacer',
    chooseLang: 'Choix de la langue',
    addToFavorites: 'Ajouter aux favoris',
    defaultPlaceLabel: 'Plan',
    noNetworkTitle: 'Réseau indisponible',
    noNetwork: 'Nous vous prions de réessayer plus tard',
    loading: 'Veuillez patienter...',
    showAll: 'Afficher sur la carte',
    noPlacesToShowOnMap: 'Aucun emplacement à afficher sur la carte',
    termsOfUse: 'Conditions d\'utilisation',
    information: 'Informations',
    contact: 'Contact',
    display: 'Affichage',
    switch2Columns: 'Basculer sur un mode d\'affichage adapté à la largeur de votre écran ? (conseillé)',
    share: 'Partager',
    goBack: 'Retour',
    goToHome: 'Revenir à l\'accueil',
    goToRootCategory: 'Revenir à l\'accueil de la rubrique',
    login: 'Identification',
    goToFavorites: 'Voir vos favoris',
    goToInbox: 'Accéder aux messages',
    goToSearch: 'Aller à la page de recherche',
    openMenu: 'Ouvrir le menu',
    relatedContent: 'Contenu lié',
    contributions: 'Contributions',
    feedback: 'Retour',
    rateThisSession: 'Évaluez cette session'
  },
  disclaimer: {
    text: 'Des cookies sont utilisés sur ce site web pour effectuer des statistiques anonymes d’utilisation.'
  },
  share: {
    mail: 'Partager par email',
    facebook: 'Partager via Facebook',
    linkedin: 'Partager via LinkedIn',
    twitter: 'Partager via Twitter',
    shareFail: 'Echec du partage',
    linkedinShareDone: 'Page partagée sur votre profil LinkedIn',
    linkedinShareFail: 'Echec du partage via LinkedIn',
    facebookShareDone: 'Page partagée sur votre profil Facebook',
    facebookShareFail: 'Echec du partage via Facebook'
  },
  data: {
    exhibitors: {
      singular: 'Exposant',
      plural: 'Exposants',
      title: 'Exposants'
    },
    exhibitor_categories: {
      singular: 'Catégorie d\'exposants',
      plural: 'Catégories d\'exposants',
      title: 'Exposants'
    },
    services: {
      singular: 'Service',
      plural: 'Services',
      title: 'Services'
    },
    service_categories: {
      singular: 'Catégorie de services',
      plural: 'Catégories de services',
      title: 'Services'
    },
    events: {
      singular: 'Évènement',
      plural: 'Évènements',
      title: 'Évènements',
      subevents: 'Interventions'
    },
    event_categories: {
      singular: 'Événement',
      plural: 'Évènements',
      title: 'Évènements'
    },
    speakers: {
      singular: 'Conférencier',
      plural: 'Conférenciers',
      title: 'Conférenciers',
      moderators: 'Modérateurs'
    },
    newproducts: {
      singular: 'Nouveauté',
      plural: 'Nouveautés',
      title: 'Nouveautés'
    },
    newproduct_categories: {
      singular: 'Catégorie de nouveautés',
      plural: 'Catégories de nouveautés',
      title: 'Nouveautés'
    }
  },
  dataListDialog: {
    defaultTitle: 'todo default title'
  },
  size: {
    bytes: ' octets',
    kilobytes: ' ko',
    megabytes: ' mo'
  },
  update: {
    modalTitle: 'Mise à jour',
    update_detected: 'Mettre à jour les données ?',
    cancel_update: 'Annuler la mise à jour',
    update_done: 'Mise à jour terminée',
    new_version_detected: 'Nouvelle version détectée',
    timeout: 'Délai dépassé'
  },
  lang: {
    fr: 'français',
    en: 'anglais'
  },
  menu: {
    toggleProfile: 'Changer de profil',
    profile: 'Profil',
    home: 'Accueil',
    inbox: 'Messages',
    exhibitors: 'Exposants',
    map: 'Plan',
    newproducts: 'Nouveautés',
    events: 'Évènements',
    services: 'Infos pratiques',
    socialmedia: 'Réseaux sociaux',
    poll: 'Vote & sondages',
    language: 'Langue'
  },
  home: {
    title: 'Accueil',
    home: 'Accueil',
    exhibitors: 'EXPOSANTS',
    newproducts: 'NOUVEAUTÉS',
    events: 'ÉVÈNEMENTS',
    services: 'INFOS PRATIQUES',
    map: 'PLAN',
    socialmedia: 'RÉSEAUX SOCIAUX',
    poll: 'VOTE & SONDAGES'
  },
  profile: {
    title: 'Choix du profil',
    mainLabel: 'Sélectionnez votre profil',
    pro: 'PRO',
    gp: 'GRAND PUBLIC',
    exposant: 'EXPOSANT',
    visiteur: 'VISITEUR',
    presse: 'PRESSE'
  },
  brand: {},
  exhibitor: {
    contacts: 'contacts',
    entities: 'entités',
    agentCountries: 'pays agents',
    brands: 'marques',
    activities: 'activités',
    categories: 'catégories'
  },
  placeSearch: {
    editStart: 'Modifier départ',
    setDestination: 'Choisir la destination',
    itinerary: 'Itinéraire',
    start: 'Choisissez votre point de départ',
    destination: 'Destination',
    poi: 'Point d\'intérêt'
  },
  newproducts: {
    launchDate: 'Mise sur le marché '
  },
  events: {
    to: 'à',
    access: 'Accès',
    publics: 'Public'
  },
  map: {
    title: 'Carte',
    goTo: 'Y aller',
    isOnThisFloor: ' est à cet étage',
    shouldReload: 'Les données du plan ont été mises à jour, souhaitez-vous le recharger maintenant ?'
  },
  favorites: {
    title: 'Favoris'
  },
  agenda: {
    title: 'Évènements'
  },
  medias: {
    title: 'Social Media',
    label: 'Suivez nous sur'
  },
  pmr: {
    title: 'PMR'
  },
  search: {
    title: 'Recherche',
    placeholderSuffix: '... (min. 2 car.)',
    submitLabel: 'Ok'
  },
  hours: {
    title: 'Horaires d\'ouverture'
  },
  intersticiel: {
    closeLabel: 'Skip Ad'
  },
  promoMs: 'App développée par Mobile-Spot',
  inbox: {
    title: 'Messages',
    empty: 'Vous n\'avez pas de message',
    dismiss: 'Ignorer',
    confirmNotif: 'OK'
  },
  poll: {
    listTile: 'Sondages disponibles',
    noPolls: 'Aucun sondage disponible',
    sessionTitle: 'Session rating',
    sessionCodeError: 'CODE INVALIDE',
    fillTheFields: 'Merci d\'entrer votre code',
    code: 'code',
    enterCode: 'Merci d\'entrer le code de session',
    pleaseRate: 'Merci d\'évaluer',
    back: 'Retour',
    next: 'Suivant',
    commentPlaceholder: 'Votre commentaire',
    thank: 'Merci',
    submit: 'Soumettre',
    submitted: 'Déjà soumis',
    done: 'Terminé',
    noNetwork: 'Réseau indisponible. Vous pouvez annuler et soumettre plus tard. Vos réponses ont été sauvegardées.',
    willOpen: 'Ce sondage sera ouvert le',
    willClose: 'Ce sondage sera clos le',
    isClosed: 'Ce sondage est clos',
    requireAuthentication: 'Merci de vous authentifier.',
    started: 'Commencé',
    notStarted: 'Non commencé',
    serverError: 'Erreur Serveur. Vous pouvez annuler et soumettre plus tard. Vos réponses ont été sauvegardées.'
  }
};