
//import { combineReducers } from 'redux'

// Conf
import Pages from 'src/pages/Pages';

import dataListDialogReducer                     from 'src/components-standalone/data-list-dialog/dataListDialogReducer';
import { COMPONENT_KEY as dataListDialogKey }    from 'src/components-standalone/data-list-dialog/DataListDialog';

import disclaimerReducer                         from 'src/components-standalone/disclaimer/disclaimerReducer';
import { COMPONENT_KEY as disclaimerKey }        from 'src/components-standalone/disclaimer/Disclaimer';

import fullLoaderReducer                         from 'src/components-standalone/full-loader/fullLoaderReducer';
import { COMPONENT_KEY as fullLoaderKey }        from 'src/components-standalone/full-loader/FullLoader';

import intersticielReducer                       from 'src/components-standalone/intersticiel/intersticielReducer';
import { COMPONENT_KEY as intersticielKey }      from 'src/components-standalone/intersticiel/Intersticiel';

import langDialogReducer                         from 'src/components-standalone/choose-lang-dialog/langDialogReducer';
import { COMPONENT_KEY as langDialogKey }        from 'src/components-standalone/choose-lang-dialog/ChooseLangDialog';

import loginDialogReducer                        from 'src/components-standalone/login-dialog/loginDialogReducer';
import { COMPONENT_KEY as loginDialogKey }       from 'src/components-standalone/login-dialog/LoginDialog';

import notificationsReducer                      from 'src/components-standalone/notifications/notificationsReducer';
import { COMPONENT_KEY as notificationsKey }     from 'src/components-standalone/notifications/Notifications';

import pollDialogReducer                         from 'src/components-standalone/poll-dialog/pollDialogReducer';
import { COMPONENT_KEY as pollDialogKey }        from 'src/components-standalone/poll-dialog/PollDialog';


import profileDialogReducer                      from 'src/components-standalone/profile-dialog/profileDialogReducer';
import { COMPONENT_KEY as profileDialogKey }     from 'src/components-standalone/profile-dialog/ProfileDialog';

import searchPlaceDialogReducer                  from 'src/components-standalone/search-place-dialog/searchPlaceDialogReducer';
import { COMPONENT_KEY as searchPlaceDialogKey } from 'src/components-standalone/search-place-dialog/SearchPlaceDialog';

import * as Cordova                              from 'src/core/cordova/cordovaReducerAndMiddleware';


/**
 * Simple generic function to add a reducer
 * @param {object}   reducers
 * @param {string}   key
 * @param {function} reducer]
 */
const addReducer = (reducers, key, reducer) => {
    if (typeof reducers[key] !== 'undefined') {
        console.error('Overriding reducer ' + key);
    }
    reducers[key] = reducer;
    return reducers;
};


export default (state = {}, action) => {
    let reducers = {};

    // Add reducer for each page
    Object.keys(Pages).forEach((pageKey) => {
        reducers = addReducer(reducers, pageKey, Pages[pageKey].getReduxReducer(state, action));
    });

    reducers = addReducer(
        reducers,
        'Cordova',
        Cordova.reducer(state.Cordova, action)
    );

    // Add reducer for standalone components
    reducers = addReducer(reducers, dataListDialogKey, dataListDialogReducer(state[dataListDialogKey], action));
    reducers = addReducer(reducers, disclaimerKey, disclaimerReducer(state[disclaimerKey], action));
    reducers = addReducer(reducers, fullLoaderKey, fullLoaderReducer(state[fullLoaderKey], action));
    reducers = addReducer(reducers, intersticielKey, intersticielReducer(state[intersticielKey], action));
    reducers = addReducer(reducers, langDialogKey, langDialogReducer(state[langDialogKey], action));
    reducers = addReducer(reducers, loginDialogKey, loginDialogReducer(state[loginDialogKey], action));
    reducers = addReducer(reducers, notificationsKey, notificationsReducer(state[notificationsKey], action));
    reducers = addReducer(reducers, pollDialogKey, pollDialogReducer(state[pollDialogKey], action));
    reducers = addReducer(reducers, profileDialogKey, profileDialogReducer(state[profileDialogKey], action));
    reducers = addReducer(reducers, searchPlaceDialogKey, searchPlaceDialogReducer(state[searchPlaceDialogKey], action));

    return reducers;
};
