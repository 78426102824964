
/**
 * What MobiGeo needs to display a POI is (@see MobiGeo API doc) :
 * {
 *   id: id,
 *   originalId: originalId,
 *   type: dataType,
 *   placeId: optional,
 * }
 *
 * Since the app should be able to display one/several POI from a single URL, only the needed information is transmitted.
 *  - cleaner url
 *  - avoid url length limit (which occured several times)
 *  - less data to process/parse
 *
 *
 * So this function converts array of objects to a lightweight version holding only the needed data.
 *
 * e.g, from:
 *  {
 *     exhibitors: array of full objects,
 *     newproducts: ...
 *     etc
 *  }
 * is returned:
 *  {
 *      exhibitors: array of objects such as { id: id, originalId: originalId }, `type` attribute is added by mapMiddleware
 *      newproducts: ...
 *      etc
 *  }
 *
 * @param  {object} data
 * @return {object}
 */
export function stripToIds(data) {
    let formatted = {};

    Object.keys(data).forEach(dataType => {

        formatted[dataType] = data[dataType].map(item => ({
            id        : item.id,
            originalId: item.original_id,
        }));
    });

    return formatted;
};


export const USER_POSITION = 'user-position';
