
// Libs
import React, { Component }   from 'react';
import PropTypes              from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';

// Conf
import { DATA_TYPE_EXHIBITORS } from 'data/config/dataConfig';
import { EXHIBITOR_PAGE_KEY } from 'src/pages/pagesKeys';

// App modules
import AppToolbar       from 'src/components/app-toolbar/AppToolbar';
import Menu             from 'src/components/menu/Menu';
import FetchResult      from 'src/components/fetch-result/FetchResult';
import ExhibitorContent from './ExhibitorContent';

import * as actions from 'src/store/actions';

import '../common-fiche.scss';
import './ExhibitorPage.scss';

const LOG_PREF = '[ExhibitorPage] ';


function getPageTitle(item, labels) {
    let exhibitorName = item ? item.title : '';

    return labels.data[DATA_TYPE_EXHIBITORS].singular + labels.common.colon + exhibitorName;
}


class ExhibitorPage extends Component {

    constructor(props) {
        super(props);

        this.pageKey = EXHIBITOR_PAGE_KEY;
        this.exhibitorId = props.id;

        this.state = {};
    }

    /**
     * Update string visible in browser tab/history/favorites
     */
    setDocumentContext() {
        if (this.props.isActive(this.pageKey)) {
            this.props.setDocumentContext(getPageTitle(this.props.item, this.props.labels));
        }
    }


    componentDidMount() {
        this.fetchIfNeeded(this.props);
    }
    componentWillReceiveProps(nextProps) {
        this.fetchIfNeeded(nextProps);

        // Auto show POI on map if:
        //  - two columns mode is enabled
        //  - for this page, configuration indicates to automatically show the POI
        //  - item has been fetched
        //  - no item had been fetched yet or `id` is different
        if (this.props.twocolumns === true
                && this.props.autoShowPoi
                && nextProps.item
                && (!this.props.item || nextProps.item.id !== this.props.item.id)) {

            this.props.actions.showOnePoiOnMap({
                id        : nextProps.item.id,
                originalId: nextProps.item.original_id,
                type: DATA_TYPE_EXHIBITORS,
            });
        }
    }

    /**
     * Fetch data if :
     *  - data has been updated (shouldFetch: true)
     *  - or id is different
     *  - or if we have no exhibitor data and request is neither pending nor without result
     */
    fetchIfNeeded(props) {
        if (props.id !== this.exhibitorId) {
            this.idHasChanged = true;
        }
        if (props.shouldFetch === true || this.idHasChanged || (!props.item && props.isPending !== true && props.itemNotFound !== true)) {
            this.exhibitorId = props.id;
            props.actions.fetchItem(props.id, DATA_TYPE_EXHIBITORS, [ 'country', 'cats', 'acts', 'newproducts', 'places', 'events' ]);
        }
    }

    componentDidUpdate() {
        if (this.idHasChanged === true) {
            this.idHasChanged = false;

            // child ref not available on componentDidUpdate
            window.setTimeout(this.resetScroll, 100, this);
        }
        this.setDocumentContext();
    }

    resetScroll(_this) {
        let context = _this || this;
        if (context.contentComponent && context.contentComponent.scroller) {
            context.contentComponent.scroller.scrollTo(0);
        }
    }

    render() {
        console.log(LOG_PREF+'render');
        return (
            <Menu
                options={{ isOpen: this.props.isMenuOpen }}
                actions={this.props.actions}
                labels={this.props.labels}
                profile={this.props.profile}
                associatedPageKey={this.pageKey}
                adConfig={this.props.adConfig}
                twoColumns={this.props.twocolumns}>

                <AppToolbar
                    labels={this.props.labels}
                    isDisplayed={this.props.hasToolbar}
                    actions={this.props.actions}
                    title={this.props.labels.data[DATA_TYPE_EXHIBITORS].singular}
                    pageKey={this.pageKey}
                    profile={this.props.profile}
                    hasBackButton={this.props.backButtonInToolbar}
                    hasHomeButton={this.props.homeButtonInToolbar}
                    hasFavButton={this.props.favButtonInToolbar}
                    hasSearchButton={this.props.searchButtonInToolbar}
                    hasMenuButton={this.props.menuButtonInToolbar}>

                    <FetchResult
                        contentRef={ref => { this.contentComponent = ref; }}
                        Component={ExhibitorContent}
                        isPending={this.props.isPending}
                        itemNotFound={this.props.itemNotFound}
                        appointmentRequestStatus={this.props.appointmentRequestStatus}
                        // Content props
                        item={this.props.item}
                        isFavorite={this.props.isFavorite}
                        isLogoFullWidth={this.props.isLogoFullWidth}
                        // Common
                        labels={this.props.labels}
                        actions={this.props.actions}
                        queryString={this.props.queryString}
                        getPageTitle={getPageTitle} />

                </AppToolbar>
            </Menu>
        );
    }
};

ExhibitorPage.propTypes = {
    id          : PropTypes.number.isRequired,
    item        : PropTypes.object,
    isFavorite  : PropTypes.bool,
    isPending   : PropTypes.bool,
    itemNotFound: PropTypes.bool,
    appointmentRequestStatus: PropTypes.object.isRequired,
    // Common page props
    autoShowPoi       : PropTypes.bool,
    isMenuOpen        : PropTypes.bool.isRequired,
    profile           : PropTypes.string,
    labels            : PropTypes.object.isRequired,
    actions           : PropTypes.object.isRequired,
    isActive          : PropTypes.func.isRequired,
    queryString       : PropTypes.string.isRequired,
    setDocumentContext: PropTypes.func.isRequired,
    isLogoFullWidth   : PropTypes.bool,
    // toolbar
    hasToolbar           : PropTypes.bool,
    homeButtonInToolbar  : PropTypes.bool,
    backButtonInToolbar  : PropTypes.bool,
    searchButtonInToolbar: PropTypes.bool,
    favButtonInToolbar   : PropTypes.bool,
    menuButtonInToolbar  : PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => state[EXHIBITOR_PAGE_KEY];
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExhibitorPage);
