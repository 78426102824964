
// Libs
import { createStore, applyMiddleware } from 'redux';
import createLogger    from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

// Config
import config from 'data/config/config';

// Middlewares
import adManagerMiddleware   from 'src/core/ads/adManagerMiddleware';
import pollManagerMiddleware from 'src/core/polls/pollManagerMiddleware';
import contributionsFeedManagerMiddleware from 'src/core/contributions-feed/contributionsFeedManagerMiddleware';
import analyticsMiddleware    from 'src/core/analytics/analyticsMiddleware';
import bootMiddleware         from 'src/core/bootMiddleware';
import dataAssetsMiddleware   from 'src/core/data-and-assets/dataAssetsMiddleware';
import fullLoaderMiddleware   from 'src/components-standalone/full-loader/fullLoaderMiddleware';
import inboxMiddleware        from 'src/pages/inbox/inboxMiddleware';
import intersticielMiddleware from 'src/components-standalone/intersticiel/intersticielMiddleware';
import listPageMiddleware     from 'src/pages/list/listPageMiddleware';
import mapMiddleware          from 'src/pages/map/mapMiddleware';
import searchPageMiddleware   from 'src/pages/search/searchPageMiddleware';
import searchPlaceDialogMiddleware from 'src/components-standalone/search-place-dialog/searchPlaceDialogMiddleware';
import routerMiddleware       from 'src/core/navigation/routerMiddleware';
import loginMiddleware        from 'src/core/login/LoginMiddleware';
import profileMiddleware      from 'src/core/profile/profileMiddleware';
import socialMiddleware      from 'src/core/social/socialMiddleware';
import appointmentRequestMiddleware from 'src/core/appointment-request/appointmentRequestMiddleware';
import synchronizedFavoritesMiddleware from 'src/core/favorites/synchronizedFavoritesMiddleware';
import { middleware as flightScheduleMiddleware } from 'src/pages/flights_schedule/flightsReducer';

// App modules
import * as AdManager                from 'src/core/ads/AdManager';
import * as PollManager              from 'src/core/polls/PollManager';
import * as PollService              from 'src/core/polls/PollService';
import * as ContributionsFeedManager from 'src/core/contributions-feed/ContributionsFeedManager';
import * as ContributionsFeedService from 'src/core/contributions-feed/ContributionsFeedService';

import * as AppointmentRequestService from 'src/core/appointment-request/AppointmentRequestService';
import * as ConfigItemFactory         from 'src/core/util/ConfigItemFactory';
import * as Cordova                   from 'src/core/cordova/cordovaReducerAndMiddleware';
import * as Db                        from 'src/core/data-and-assets/Db';
import * as GoogleAnalyticsHelper     from 'src/core/analytics/GoogleAnalyticsHelper';
import * as LoginService              from 'src/core/login/LoginService';
import * as Profile                   from 'src/core/Profile';
import * as ProfileService            from 'src/core/profile/ProfileService';
import * as SocialService            from 'src/core/social/SocialService';
import * as PushWooshManager          from 'src/core/PushWooshManager';
import * as RealTime                  from 'src/core/realtime/RealTime';
import * as ResizeHandler             from 'src/core/util/ResizeHandler';
import * as browser                   from 'src/core/util/browser';
import * as Router                    from 'src/core/navigation/Router';
import * as SynchronizedFavoritesService from 'src/core/favorites/SynchronizedFavoritesService';
import * as Updater                   from 'src/core/data-and-assets/Updater';


import rootReducer from './reducers';


/**
 * Give a `store` reference to not connected modules
 * @param  {object} store
 */
function giveStoreReferenceToNonReactModules(store) {

    [
        Db,
        Updater,
        Router,
        Profile,
        ResizeHandler,
        ConfigItemFactory,
        AdManager,
        PollManager,
        config.POLL.FEATURE_ENABLED ? PollService : null,
        ContributionsFeedManager,
        ContributionsFeedManager.isEnabled() ? ContributionsFeedService : null,
        PushWooshManager,
        config.LOGIN.FEATURE_ENABLED ? LoginService : null,
        config.APPOINTMENT_REQUEST.FEATURE_ENABLED ? AppointmentRequestService : null,
        config.SYNCHRONIZED_FAVORITES.FEATURE_ENABLED ? SynchronizedFavoritesService : null,
        RealTime,
        ProfileService,
        SocialService,
        browser
    ].forEach(module => { module && module.setReduxStore(store) });
}


export default function() {

    // Declare store middlewares
    let middlewares = [ thunkMiddleware ];

    if (config.ENV === 'dev') {
        middlewares.push(createLogger);
    }
    middlewares.push(listPageMiddleware);
    middlewares.push(searchPageMiddleware);
    middlewares.push(routerMiddleware);
    middlewares.push(dataAssetsMiddleware);
    middlewares.push(intersticielMiddleware);
    middlewares.push(bootMiddleware);
    middlewares.push(fullLoaderMiddleware);
    middlewares.push(adManagerMiddleware);
    middlewares.push(searchPlaceDialogMiddleware);
    middlewares.push(mapMiddleware);
    middlewares.push(PushWooshManager.middleware);
    middlewares.push(inboxMiddleware);
    middlewares.push(Cordova.createMiddleware(global.isCordovaContext ? cordova : null));
    middlewares.push(profileMiddleware);

    if (config.SOCIAL.FEATURE_ENABLED) {
        middlewares.push(socialMiddleware);
    }
    if (config.POLL.FEATURE_ENABLED) {
        middlewares.push(pollManagerMiddleware);
    }
    if (config.FLIGHTS_SCHEDULE.FEATURE_ENABLED) {
        middlewares.push(flightScheduleMiddleware);
    }
    if (config.LOGIN.FEATURE_ENABLED) {
        middlewares.push(loginMiddleware);
    }
    if (config.APPOINTMENT_REQUEST.FEATURE_ENABLED === true) {
        middlewares.push(appointmentRequestMiddleware);
    }
    if (config.SYNCHRONIZED_FAVORITES.FEATURE_ENABLED === true) {
        middlewares.push(synchronizedFavoritesMiddleware);
    }
    if (GoogleAnalyticsHelper.isEnabled()) {
        middlewares.push(analyticsMiddleware);
    }
    if (ContributionsFeedManager.isEnabled()) {
        middlewares.push(contributionsFeedManagerMiddleware);
    }

    // Create store
    let store = createStore(
        rootReducer,
        applyMiddleware( ...middlewares )
    );

    giveStoreReferenceToNonReactModules(store);

    return store;
}
