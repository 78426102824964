
import React     from 'react';
import PropTypes from 'prop-types';

import { openUrl, removeLinkProtocol } from 'src/core/util/JsTools';


const Url = ({ label, href, target, onClick, className, children, callback, noCache, ...props }) => {

    if (typeof onClick === 'function') {
        console.error('Do not override \'onClick\' property', props);
    }

    const handleClick = (e) => {
        e.preventDefault();
        if (href) {
            const url = noCache ? href + `?rnd=${new Date().getTime()}` : href;
            openUrl(url, function(result) {
                if (result === true && typeof callback === 'function') {
                    callback(href);
                }
            }, target);
        }
    };

    return <a className={`link color-link-blue ${!href ? 'empty-link' : ''} ${className || ''}`}
              onClick={handleClick}
              {...props}>{removeLinkProtocol(label) || children}</a>
};

Url.propTypes = {
    href     : PropTypes.string,
    target   : PropTypes.string,
    label    : PropTypes.string, // not required as this component can wrap an image for instance
    className: PropTypes.string,
    callback : PropTypes.func,
};

export default Url;