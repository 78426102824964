import { DATA_TYPE_EVENTS, DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EXHIBITORS, DATA_TYPE_SPEAKERS } from './dataConfig';
import CategoriesHack from './CategoriesHack';
import { AGENDA_PAGE_KEY, LIST_PAGE_KEY, MAP_PAGE_KEY, MEDIAS_PAGE_KEY } from 'src/pages/pagesKeys'; // app modules

import Banner from 'src/components/banner/Banner';
import AdBanner from 'src/components/ad-banner/AdBanner';
import HomeToolbar from 'src/pages/home/HomeToolbar';
import HomeTiles from 'src/pages/home/HomeTiles';
import MobilespotBar from 'src/components/mobilespot-bar/MobilespotBar';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { openUrl } from 'src/core/util/JsTools';
import { createItem, createItemRoute } from 'src/core/util/ConfigItemFactory';
var LOG_PREF = '[homeConfig] '; // NB: used for ChooseProfile page too !

export var getStyle = function getStyle() {
  return {
    background: 'url(home-background.jpg) no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  };
};
export var BASE_COLUMN_COUNT = {
  landscape: 3,
  portrait: 3
};
export var MAX_COLUMN_SIZE = {
  landscape: 190,
  // 2 columns mode => less width available
  portrait: 250
};
/**
 *
 */

export var GENERAL_TILE_OPTIONS = {
  height: 1.2,
  // rowspan
  width: 1 // colspan

};

var createItemPollButton = function createItemPollButton(lang) {
  return createItem( // Style
  {
    container: {
      className: 'centered-labels home-btn-poll'
    },
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/polls.svg') + ')'
      }
    }
  }, // Label (i18n)
  'home.poll', // Action to perform on click
  function (actions) {
    actions.showPollDialog();
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getDefault = function getDefault(lang, adConfig, orientation) {
  //let hasAdButtons = adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
  return [{
    component: Banner,
    props: {
      getBannerUrl: function getBannerUrl(lang) {
        return 'files/project/ads/banners/pisc/header.svg';
      }
    }
  }, {
    component: HomeToolbar
  }, {
    component: HomeTiles,
    props: {
      baseColumnCount: BASE_COLUMN_COUNT[orientation],
      maxColumnSize: MAX_COLUMN_SIZE[orientation],
      tiles: [// // NEWPRODUCTS
      // createItemRoute(
      //     // Style
      //     {   container: {
      //             className: 'centered-labels home-btn-newproducts',
      //         },
      //         icon: {
      //             style: {
      //                 backgroundImage: 'url('+getUrl('files/project/home/nouveautés.svg')+')',
      //             },
      //         },
      //     },
      //     // Label (i18n)
      //     'home.newproducts',
      //     // Page & props
      //     LIST_PAGE_KEY,
      //     { locateAll: false,
      //       inputs: [{ dataType: DATA_TYPE_NEWPRODUCT_CATEGORIES }]},
      //     // Tile options
      //     GENERAL_TILE_OPTIONS,
      // ),
      // PROGRAMME DU SALON
      createItemRoute( // Style
      {
        container: {
          className: 'centered-labels home-btn-events'
        },
        icon: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/Programme.svg') + ')'
          }
        }
      }, // Label (i18n)
      'home.events', // Page & props
      LIST_PAGE_KEY, {
        locateAll: false,
        inputs: [{
          dataType: DATA_TYPE_EVENT_CATEGORIES
        }]
      }, // Tile options
      GENERAL_TILE_OPTIONS), // INTERVENANTS
      createItemRoute( // Style
      {
        container: {
          className: 'centered-labels home-tile-row1'
        },
        icon: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/Intervenants.svg') + ')'
          },
          className: 'home-icon-intervenants'
        }
      }, // Label (i18n)
      'home.speakers', // Page & props
      LIST_PAGE_KEY, {
        contextualTitle: "",
        inputs: [{
          dataType: DATA_TYPE_SPEAKERS
        }]
      }, // Tile options
      GENERAL_TILE_OPTIONS), // EXPOSANTS
      createItemRoute( // Style
      {
        container: {
          className: 'centered-labels home-btn-exhibitors'
        },
        icon: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/Sponsors.svg') + ')'
          }
        }
      }, // Label (i18n)
      'home.exhibitors', // Page & props
      AGENDA_PAGE_KEY, {
        input: {
          dataType: DATA_TYPE_EXHIBITORS
        }
      }, // Tile options
      GENERAL_TILE_OPTIONS), // // SERVICES
      // createItemRoute(
      //     // Style
      //     {   container: {
      //             className: 'centered-labels home-btn-services',
      //         },
      //         icon: {
      //             style: {
      //                 backgroundImage: 'url('+getUrl('files/project/home/infos-pratiques.svg')+')',
      //             },
      //         },
      //     },
      //     // Label (i18n)
      //     'home.services',
      //     // Page & props
      //     LIST_PAGE_KEY,
      //     { locateAll: false,
      //       inputs: [{ dataType: DATA_TYPE_SERVICE_CATEGORIES }]},
      //     // Tile options
      //     GENERAL_TILE_OPTIONS,
      // ),
      // PLAN
      createItemRoute( // Style
      {
        container: {
          className: 'centered-labels home-btn-map'
        },
        icon: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/Plan.svg') + ')'
          }
        }
      }, // Label (i18n)
      'home.map', // Page & props
      MAP_PAGE_KEY, null, // Tile options
      GENERAL_TILE_OPTIONS), // SOCIAL MEDIAS
      createItemRoute( // Style
      {
        container: {
          className: 'centered-labels home-btn-socialmedia'
        },
        icon: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/SocialMedia.svg') + ')'
          }
        }
      }, // Label (i18n)
      'home.socialmedia', // Page & props
      MEDIAS_PAGE_KEY, null, // Tile options
      GENERAL_TILE_OPTIONS)]
    }
  }, {
    component: AdBanner,
    props: {
      forcedAdKey: 'home'
    }
  }];
};
/**
 * Return home configuration
 * @param  {string} profile
 * @param  {string} lang
 * @param  {object} adConfig
 * @param  {string} orientation
 * @return {object}
 */


export var get = function get(profile, lang, adConfig, orientation) {
  return getDefault(lang, adConfig, orientation);
};