
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { AlertDialog } from 'react-onsenui';

import config from 'data/config/config';

import { removeHtml } from 'src/core/util/DomTools';
import cordovaShare from 'src/core/share/CordovaShare';
import fbShare from 'src/core/share/FacebookWebShare';
import liShare from 'src/core/share/LinkedInWebShare';

import './ShareButtons.scss';



class ShareButton extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isDialogOpen: false,
        };
    }

    /**
     * When main button is clicked, display modal with a list of social networks
     * @param {object} e
     */
    onShareButtonClick = e => {
        if (typeof cordova !== 'undefined' && navigator.share) {
            this.share(cordovaShare);
        } else {
            // Display onsen modal containing share buttons
            this.setState({ isDialogOpen: true });
        }
    }

    share(func) {
        this.setState({ isDialogOpen: false });
        func(
            this.name,
            removeHtml(this.props.description),
            this.getWebUrl(),
            this.props.image || (config.WEB_URL + config.DEFAULT_SHARE_IMAGE),
            this.props.actions,
            this.props.labels
        );
    }
    shareOnFacebook = () => {
        this.share(fbShare);
    }
    shareOnLinkedIn = () => {
        this.share(liShare);
    }

    /**
     * The url redirects to deployed web app
     */
    getWebUrl = () => config.WEB_URL + this.props.queryString;

    render() {
        if (config.SHARE_FEATURE_ENABLED !== true) {
            return null;
        }

        if (!config.WEB_URL) {
            console.error('Skip share button display because WEB_URL is not declared in configuration');
            return null;
        }

        this.encodedUrl  = encodeURIComponent(this.getWebUrl());
        this.name        = this.props.labels.common.appTitle + config.APP_TITLE_SEPARATOR + this.props.name;
        this.encodedName = encodeURIComponent(this.name);

        return (
            <div>
                <div className="share-btn fa fa-share-alt"
                     title={this.props.labels.common.share}
                     onClick={this.onShareButtonClick} />

                <AlertDialog
                        isOpen={this.state.isDialogOpen}
                        onCancel={() => { this.setState({ isDialogOpen: false }); }}>

                    <div className='share-buttons-title'>{this.props.labels.common.share}</div>

                    <div className="share-buttons">

                        {/* Email */}
                        <a href={`mailto:?subject=${this.encodedName}&body=${this.encodedName}%0A${this.encodedUrl}`}
                             className="share-btn-mail"
                             title={this.props.labels.share.mail}>
                           <span className="fa fa-envelope-square"></span>
                        </a>

                        {/* Facebook */}
                        { config.FACEBOOK_APP_ID &&
                            <a onClick={this.shareOnFacebook}
                                 className="share-btn-facebook"
                                 title={this.props.labels.share.facebook}>
                               <span className="fa fa-facebook-square"></span>
                            </a>
                        }

                        {/* LinkedIn */}
                        { config.LINKEDIN_API_KEY &&
                            <a onClick={this.shareOnLinkedIn}
                                 className="share-btn-linkedin"
                                 title={this.props.labels.share.linkedin}>
                               <span className="fa fa-linkedin-square"></span>
                            </a>
                        }

                        {/* Twitter */}
                        <a href={`https://twitter.com/share?url=${this.encodedUrl}&text=${this.encodedName}&hashtags=`}
                             target="_blank"
                             rel="noopener noreferrer"
                             className="share-btn-twitter"
                             title={this.props.labels.share.twitter}>
                            <span className="fa fa-twitter-square"></span>
                        </a>

                    </div>
                </AlertDialog>
            </div>
        );
    }
}


ShareButton.propTypes = {
    name       : PropTypes.string.isRequired,
    queryString: PropTypes.string.isRequired,
    description: PropTypes.string,
    image      : PropTypes.string,
    labels     : PropTypes.object.isRequired,
    actions    : PropTypes.object.isRequired,
};

export default ShareButton;