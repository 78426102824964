
import config from 'data/config/config';

import { STATUS as SYNCHRO_FAV_STATUS } from 'src/core/favorites/SynchronizedFavoritesService';

import {
    AD_CONFIG_LOADED,
    POLL_CONFIG_LOADED,
    ALL_FAVORITES_DELETED,
    DATA_UPDATED,
    FETCH_FAVORITES,
    LANG_CHANGED,
    PROFILE_CHANGED,
    SET_FAVORITES_SYNCHRONIZATION_STATUS,
    TOGGLE_FAVORITE,
    TOGGLE_MENU,
    UPDATE_PAGE_STATE,
    SET_LOGIN_IDENTIFICATION,
} from 'src/store/actionTypes';

import {
    adConfigLoaded,
    pollConfigLoaded,
    getPageCommonDefaultState,
    langChanged,
    profileChanged,
    setFavoritesSynchronizationStatus,
    toggleMenu,
    updateObject,
    updatePageState,
} from 'src/store/reducers/commons';

import STATUS from 'src/store/fetchStatuses';

import { FAVORITES_PAGE_KEY } from 'src/pages/pagesKeys';


function getDefaultState() {
    let isSynchroFavFeatureEnabled = config.SYNCHRONIZED_FAVORITES.FEATURE_ENABLED === true;

    return Object.assign({}, getPageCommonDefaultState(FAVORITES_PAGE_KEY), {
        isSynchroFavFeatureEnabled: isSynchroFavFeatureEnabled,
        synchroFavBtnStatus: !isSynchroFavFeatureEnabled ? SYNCHRO_FAV_STATUS.HIDDEN : SYNCHRO_FAV_STATUS.DISABLED,
        // Add any additional default state property
    });
}



// Set `shouldFetch`: true so the page will refresh its content
const __setShouldFetchTrue = state => updateObject(state, { shouldFetch: true });

const _allFavoritesDeleted = state => __setShouldFetchTrue(state);

const _toggleFavorite = (state, action) => (
    action.favListUpdated === true
        ? __setShouldFetchTrue(state)
        : state
);

const _dataUpdated = (state, action) => __setShouldFetchTrue(state);


/**
 * Initial action for this page
 * @param  {object} state
 * @param  {object} action
 * @return {object}
 */
const _fetchFavorites = (state, action) => {
    return updateObject(state, {
        favorites  : action.favorites,
        data       : action.data,
        isPending  : action.status === STATUS.PENDING,
        shouldFetch: false,
    });
};

const _setLoginIdentification = (state, action) => {
    let isLoggedIn = !!action.identification;
    if (state.isLoggedIn !== isLoggedIn) {
        return updateObject(state, { isLoggedIn: isLoggedIn });
    }
    return state;
}

export default (state = getDefaultState(), action) => {
    switch (action.type) {

        case AD_CONFIG_LOADED : return adConfigLoaded(state, action);
        case ALL_FAVORITES_DELETED: return _allFavoritesDeleted(state);
        case DATA_UPDATED     : return _dataUpdated(state, action);
        case FETCH_FAVORITES  : return _fetchFavorites(state, action);
        case LANG_CHANGED     : return langChanged(state, action);
        case POLL_CONFIG_LOADED : return pollConfigLoaded(state, action);
        case PROFILE_CHANGED  : return profileChanged(state, action, FAVORITES_PAGE_KEY);
        case SET_FAVORITES_SYNCHRONIZATION_STATUS: return setFavoritesSynchronizationStatus(state, action);
        case SET_LOGIN_IDENTIFICATION: return _setLoginIdentification(state, action);
        case TOGGLE_FAVORITE  : return _toggleFavorite(state, action);
        case TOGGLE_MENU      : return toggleMenu(state, action, FAVORITES_PAGE_KEY);
        case UPDATE_PAGE_STATE: return updatePageState(state, action, FAVORITES_PAGE_KEY);

        default: return state;
    }
};
