import { AGENDA_PAGE_KEY } from 'src/pages/pagesKeys.js';

import AgendaPage        from 'src/pages/agenda/AgendaPage';
import agendaPageReducer from 'src/pages/agenda/agendaPageReducer';

export default {
    key      : AGENDA_PAGE_KEY,
    path     : '/agenda',
    component: AgendaPage,
    elId     : 'agenda-page',
    getReduxReducer: (state, action) => agendaPageReducer(state[AGENDA_PAGE_KEY], action),
    // NB: `toggleMode` should not be set as 'visibility' because when value is 'none', carousel elements are still visible over other pages
};
