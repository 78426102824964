import React from 'react';
import PropTypes from 'prop-types';
import InstagramEmbed from 'react-instagram-embed';

class InstagramStory extends React.PureComponent {
    render() {
        const { id } = this.props

        return (
            <div className='media-instagram-post'>
                <InstagramEmbed
                    url={id}
                    maxWidth={500}
                    hideCaption={false}
                    protocol=''
                    injectScript
                />
            </div>
        );
    }

}

InstagramStory.propTypes = {
    id: PropTypes.string.isRequired
};

export default InstagramStory;
