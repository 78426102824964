import React, { Component } from 'react';
import Tab from '@material-ui/core/Tab';

import Url from '../../components/url/Url';

class MediaTabItem extends Component {

    shouldComponentUpdate(nextProps) {
        return this.props.active !== nextProps.active
            || JSON.stringify(this.props.config) !== JSON.stringify(nextProps.config)
    }

    render() {
        const { config, active, ...props } = this.props

        return (
            <Tab
                disableRipple
                icon={
                    <div
                        style={{
                            width: '30px',
                            height: '30px',
                            fill: config.color,
                            backgroundImage: `url(${config.logo})`,
                            backgroundSize: 'contain',
                            filter: `grayscale(${active ? '0' : '100%'})`
                        }}
                    />
                }
                {...props}
            />
        );
    }

}

export default MediaTabItem;
