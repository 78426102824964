
// Libs
import React     from 'react';
import PropTypes from 'prop-types';

import config from 'data/config/config';

// App modules
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';

import './Banner.scss';


const Banner = props => (

    <div className="banner">
        <img src={ getUrl(props.getBannerUrl(props.lang)) } />
    </div>
);

Banner.propTypes = {
    lang        : PropTypes.string.isRequired,
    getBannerUrl: PropTypes.func.isRequired,
    // `profile` can't be required because `Banner` component is used on `ChooseProfilePage` (when user has not chosen a profile yet)
    // profile     : PropTypes.oneOf(config.SUPPORTED_PROFILES),
};

export default Banner;