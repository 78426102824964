// NB: to build a cordova app, relative path is needed here
var _require = require('../../src/pages/pagesKeys'),
    HOME_PAGE_KEY = _require.HOME_PAGE_KEY,
    LIST_PAGE_KEY = _require.LIST_PAGE_KEY;

var dataConfig = require('./dataConfig');

var projectConfig = require('../../../data/config.js'); // import CategoriesHack from './CategoriesHack';


var CategoriesHack = require('./CategoriesHack'); // Only export a default object, to be able to
// override properties depending on target environment


module.exports = {
  // Misc UI
  DELAY_BEFORE_CLICK_ACTION: 50,
  //ms
  SHOW_TERMS_OF_USE: false,
  // App profiles
  SUPPORTED_PROFILES: ['default'],
  DEFAULT_PROFILE: 'default',
  // Routing (for two columns mode, this is the (default) page on left column)
  getHomePage: function getHomePage(profile) {
    switch (profile) {
      case 'default':
        return {
          pageKey: HOME_PAGE_KEY,
          props: null
        };

      default:
        console.error('Bad configuration, unexpected profile: ' + profile);
    }
  },
  APP_TITLE_SEPARATOR: ' - ',
  ROUTE_SEPARATOR: '?',
  // Lang
  SUPPORTED_LANGS: ['en'],
  DEFAULT_LANG: 'en',
  // Backend / Updates
  UPDATE_ENABLED: true,
  BO_SSH_USER: 'www',
  AGENDA_SHOW_SIDEINDEX_IF_ABOVE: 20,
  // Map
  MOBIGEO_API_KEY: 'elaiP2Soo2go',
  // Mobile-Spot link
  MOBILESPOT_WWW: 'http://www.mobile-spot.com',
  // SHARE:
  DEFAULT_SHARE_IMAGE: '/static-img/icon-512-android.png',
  //  on LinkedIn
  LINKEDIN_API_KEY: '',
  //  on Facebook
  FACEBOOK_APP_ID: '652773898485294',
  WEB_URL: projectConfig.web.url,
  // In web mode, this fallback url can be used if the user's
  // browser does not meet some requirements (eg IE<11)
  WEB_FALLBACK: '',

  /**
   * FEATURES
   */
  LOGIN: {
    FEATURE_ENABLED: false,
    // webservice url is defined per environment
    forgottenPasswordURL: null,
    createAccountURL: null,
    tosURL: 'http://kim1.mobile-spot.com/xxx-cgu/cgu.html'
  },
  SHARE_FEATURE_ENABLED: true,
  POLL: {
    FEATURE_ENABLED: false,
    REQUIRE_AUTHENTICATION: false
  },
  APPOINTMENT_REQUEST: {
    FEATURE_ENABLED: false
  },
  FLIGHTS_SCHEDULE: {
    FEATURE_ENABLED: false
  },
  PROFILE: {
    FEATURE_ENABLED: false
  },
  SYNCHRONIZED_FAVORITES: {
    FEATURE_ENABLED: false
  },
  // real time contributions feed
  CONTRIBUTIONS_FEED: {
    FEATURE_ENABLED: false,
    INTERVAL: 1000 * 60,
    // 1 min,
    NUMBER_OF_DISPLAYED_CONTRIBUTIONS: 3
  },
  SOCIAL: {
    FEATURE_ENABLED: false
  }
};