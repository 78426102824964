
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';


function onSuccess(result) {
    // console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
    // console.log("Shared to app: " + result.app); // On Android result.app is currently empty. On iOS it's empty when sharing is cancelled (result.completed=false)
}

function onError(message, actions, labels) {
    console.error('Failed to share', message);

    actions.showNotification({
        message: labels.share.shareFail + (message ? ' ('+message+')' : ''),
        level: NotificationLevels.ERROR,
    });
}


/**
 * Let the user choose from a list of apps provided by OS (android/ios)
 *
 * @param  {string} name
 * @param  {string} description
 * @param  {string} url
 * @param  {string} image
 * @param  {object} actions   store actions (connected)
 * @param  {object} labels    labels for current lang
 */
function share(name, description, url, image, actions, labels) {
    var options = {
        message: name + ' - ' + description, // not supported on some apps (Facebook, Instagram)
        subject: name, // fi. for email
        // files: ['', ''], // an array of filenames either locally or remotely
        url: url,
        chooserTitle: labels.common.share, // Android only, you can override the default share sheet title
    };
    window.plugins.socialsharing.shareWithOptions(options, onSuccess, message => {
        onError(message, actions, labels);
    });
}

export default share;
