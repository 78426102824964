/* Cannot use ES6 here :/

import {
    DATA_TYPE_EVENT_CATEGORIES,
    DATA_TYPE_EVENTS,
    DATA_TYPE_SERVICE_CATEGORIES,
    DATA_TYPE_SERVICES,
} from './dataConfig';

export default {
    SERVICES: { parentId: 99991, parentType: DATA_TYPE_SERVICE_CATEGORIES, dataType: DATA_TYPE_SERVICES },
    PRACTICAL_INFO: { parentId: 99992, parentType: DATA_TYPE_SERVICE_CATEGORIES, dataType: DATA_TYPE_SERVICES },
    CONFERENCES: { parentId: 99991, parentType: DATA_TYPE_EVENT_CATEGORIES, dataType: DATA_TYPE_EVENTS },
    PROGRAM: { parentId: 99992, parentType: DATA_TYPE_EVENT_CATEGORIES, dataType: DATA_TYPE_EVENTS },
};
*/
var dataConfig = require('./dataConfig');

module.exports = {};