
import React     from 'react';
import PropTypes from 'prop-types';

import Url from 'src/components/url/Url';


const WebsiteRow = props => (
    !props.website ? null :
        <div className="free-row">
            <div className="prop-img">
                <div className="prop-left"><span className="fa fa-link" style={{ fontSize: '1.4em' }} /></div>
                <div className="prop-right ">
                    <Url callback={props.actions.linkClicked}
                         href={props.website}
                         label={props.website} />
                </div>
            </div>
        </div>
);

WebsiteRow.propTypes = {
    website: PropTypes.string,
    actions: PropTypes.object.isRequired,
};

export default WebsiteRow;