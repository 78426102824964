
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-onsenui';
import { GestureDetector } from 'onsenui';

import { LIST_PAGE_KEY, FLIGHTS_SCHEDULE_PAGE_KEY } from 'src/pages/pagesKeys';

import {
    CATEGORIES_MAPPING,
    DATA_TYPE_FLIGHTS_SCHEDULE,
} from 'data/config/dataConfig';

import { FAST_AND_UGLY_IF_ABOVE, ALPHABETICAL_IF_ABOVE } from 'data/config/listConfig';

import STATUS from 'src/store/fetchStatuses';


import AdBanner         from 'src/components/ad-banner/AdBanner';
import AllOnMapButton   from 'src/components/all-on-map-button/AllOnMapButton';
import AlphabeticalList from 'src/components/alphabetical-list/AlphabeticalList';
import DetailFixedTitle from 'src/components/detail-fixed-title/DetailFixedTitle';
import List             from 'src/components/list/List';
import Loader           from 'src/components/loader/Loader';
import TypeBar          from 'src/components/type-bar/TypeBar';
import Url              from 'src/components/url/Url';
import { stripToIds }   from 'src/pages/map/mapUtil';
import { latinise }     from 'src/core/util/StringUtil';



class ListPageContent extends React.Component {

    pageKey = LIST_PAGE_KEY

    indexLetterGetter(item, rowText) {
        let value = item.lastname || rowText;

        if (typeof value === 'string' && value.length > 0) {
            let firstLetter = value[0];

            return latinise(firstLetter.toUpperCase());
        }
        // else undefined is returned ('#' character will be used)
    }

    getDetail() {
        let types = Object.keys(this.props.items);
        return types.map(dataType => {

            let items = this.props.items[dataType];
            let listProps = {
                key              : dataType,
                actions          : this.props.actions,
                labels           : this.props.labels,
                favorites        : this.props.favorites[dataType],
                isPending        : this.props.status[dataType] === STATUS.PENDING,
                dataType         : dataType,
                items            : items,
                isFastAndUgly    : items && items.length > FAST_AND_UGLY_IF_ABOVE,
                displayFavorites : this.props.favIconDisabled !== true,
                indexLetterGetter: this.indexLetterGetter,
                height           : this.props.height,
                pageKey          : this.pageKey,
            };

            let listComp;
            if (items && items.length > ALPHABETICAL_IF_ABOVE) {
                listComp = <AlphabeticalList associatedPageKey={this.pageKey} {...listProps} />;
            } else {
                listComp = <List {...listProps} />;
            }

            if (types.length > 1) {
                return (
                    <TypeBar
                        key={dataType}
                        label={this.props.labels.data[CATEGORIES_MAPPING[dataType]].plural}>
                        {listComp}
                    </TypeBar>
                );
            } else {
                return listComp;
            }
        });
    }

    containerElementDidRender = el => {
        // @see https://onsen.io/v2/docs/guide/js/#adding-page-content  § Gesture detector
        if (el) {
            GestureDetector(el).dispose();
        }
    }

    render() {
        let hasItems = this.props.items !== null && typeof this.props.items === 'object';

        return (
            <div className={this.props.className}
                 style={{ height: this.props.height }}
                 ref={this.containerElementDidRender}>

                { this.props.isOnGeneralAircraftList &&
                    <FlightsScheduleHeader actions={this.props.actions} labels={this.props.labels} />
                }

                { this.props.header &&
                    <DetailFixedTitle
                        title={this.props.header}
                        hideFav={true}
                        labels={this.props.labels} />
                }

                { this.props.isOnProgrammeRootPage &&
                    <HeaderProgramme labels={this.props.labels} />
                }

                { this.props.hasShowOnMapButton &&
                    <AllOnMapButton
                        labels={this.props.labels}
                        actions={this.props.actions}
                        data={ stripToIds(this.props.items) } />
                }

                { this.props.hasDisplayPdfButton &&
                    <div className="cta-btn-container">
                        <Url
                            callback={this.props.actions.linkClicked}
                            href={this.props.agendaAsPdfUrl}
                            noCache // disable cache
                            target="_blank" className="cta-btn"
                            alt={this.props.labels.common.openAgendaAsPdf}>

                                {this.props.labels.common.openAgendaAsPdf}
                        </Url>
                    </div>
                }

                <div className="list-page-content">
                    { hasItems
                        ? this.getDetail()
                        : <Loader labels={this.props.labels} />
                    }
                </div>

                <AdBanner
                    actions={this.props.actions}
                    associatedPageKey={this.pageKey}
                    listInputs={this.props.inputs}
                    adConfig={this.props.adConfig}
                    labels={this.props.labels} />
            </div>
        );
    }


}


export function HeaderProgramme ({ labels }) {
    return (
        <section
            style={{
                padding: "1em 0.7em",
                fontSize: '0.7em',
                color: '#007e4f',
                borderBottom: '1px solid lightgrey'
            }}>
            {labels.contributions.programmeSlogan}
        </section>
    );
}

export function FlightsScheduleHeader({ actions, labels }) {
    return (
        <section
            style={{
                textAlign: "center",
                padding: "1em 0"
            }}>

            <Button
                className="show-all-btn"
                onClick={() => actions.navigate(FLIGHTS_SCHEDULE_PAGE_KEY)}>

                {labels.data[DATA_TYPE_FLIGHTS_SCHEDULE].title}
            </Button>
        </section>
    );
}

ListPageContent.props = {
    className: PropTypes.string,
    isOnGeneralAircraftList: PropTypes.bool,
    isOnProgrammeRootPage: PropTypes.bool,
    hasShowOnMapButton: PropTypes.bool,
    hasDisplayPdfButton: PropTypes.bool,
    agendaAsPdfUrl: PropTypes.string,
    inputs: PropTypes.object.isRequired,
    items: PropTypes.object,
    adConfig: PropTypes.object,
    header: PropTypes.string,
    favorites: PropTypes.object,
    status: PropTypes.object,
    favIconDisabled: PropTypes.bool,
    actions: PropTypes.object.isRequired,
    labels: PropTypes.object.isRequired,
    height: PropTypes.number, // prop set from AppToolbar
};

export default ListPageContent;
