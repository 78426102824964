
// conf
import config from 'data/config/config';
import {
    DATA_TYPE_COUNTRIES,
    DATA_TYPE_EXHIBITORS,
    DATA_TYPE_PLACES,
    DATA_TYPE_SPEAKERS,
} from 'data/config/dataConfig';

// App modules
import * as Db from 'src/core/data-and-assets/Db';


const LOG_PREF = '[Query] ';


/**
 * @param  {object} item
 * @param  {string} dataType
 * @param  {array} relatedData (e.g ['places', 'newproducts', 'country'])
 * @return {object}
 */
export function completeData(item, dataType, relatedData) {
    let references = {};

    relatedData.forEach(dataName => {

        switch (dataName) {

            case 'places':
                references.places = [];
                if (Array.isArray(item.places)) { // warning item.lump.places -> item.places migration
                    item.places.forEach(placeData => {
                        if (placeData.place_id) {
                            references.places.push( get(placeData.place_id, DATA_TYPE_PLACES) );
                        }
                    });
                }
                break;

            case 'placeLabel':
                references.placeLabel = getPlaceLabel(item.places); // warning item.lump.places -> item.places migration
                break;

            case 'country':
                if (item.country_id) {
                    references.country = get(item.country_id, DATA_TYPE_COUNTRIES);
                }
                break;

            case 'cats':
                if (Array.isArray(item.lump.cats)) {
                    references.categories = item.lump.cats.map(id => get(id, Db.getCategoryDatatype(dataType)));
                }
                break;

            case 'acts':
                if (Array.isArray(item.lump.acts)) {
                    references.activities = item.lump.acts.map(id => get(id, Db.getCategoryDatatype(dataType)));
                }
                break;

            case 'exhibitor':
                if (item.exhibitor_id) {
                    references.exhibitor = get(item.exhibitor_id, DATA_TYPE_EXHIBITORS);
                }
                break;

            case 'moderators':
                if (Array.isArray(item.lump.moderators)) {
                    references.moderators = item.lump.moderators.map(moderatorId => get(moderatorId, DATA_TYPE_SPEAKERS));
                }
                break;

            default:
                // default: expect to find `item.lump[dataType]`
                if (Array.isArray(item.lump[dataName])) {
                    references[dataName] = item.lump[dataName].map(id => get(id, dataName));

                }/* else {
                    console.error(LOG_PREF+'Unexpected related data: '+dataName);
                }*/
        }
    });

    return Object.assign(item, { references: references });
}

/**
 * Get label of the first placeId
 * @param  {array of numbers} placeIds
 * @return {string}
 */
function getPlaceLabel(placeIds) {
    if (Array.isArray(placeIds) && placeIds.length > 0) {

        var labels = [];
        placeIds.forEach(placeId => {
            if (typeof placeId === 'number') {
                let place = get(placeId, DATA_TYPE_PLACES);

                // Skip places corresponding to synoptic view
                if (place && place.tag.startsWith('Syno') === false) {
                    labels.push(place.label);
                }
            }
        });

        // Filter out empty or duplicated labels
        labels = labels.reduce(function(newArray, label) {
            if (label && newArray.indexOf(label) === -1) {
                newArray.push(label);
            }
            return newArray;
        }, []);

        return labels.sort().join(', ');
    }
    // default: undefined is returned
}


/**
 * Get by id
 * @param {number} id
 * @param {string} dataType  (@see VALID_DATA_TYPES)
 * @param {array} relatedDataToSet : related data to retrieve
 */
export const get = (id, dataType, relatedDataToSet) => {

    if (id === null || typeof id === 'undefined') {
        console.error(LOG_PREF+'Missing `id` argument');
        return;
    }
    /*if (!dataType) {
        console.error(LOG_PREF+'Missing data `dataType` argument', dataType);
        return;
    }
    if (typeof Db.getData()[dataType] === 'undefined') {
        console.error(LOG_PREF+'Unsupported data `dataType`', dataType);
        return;
    }*/

    return find([ item => item.id === id ], dataType, relatedDataToSet, true);

    // return completeData(Db.factory(Db.getData()[dataType].data[id]), dataType, relatedDataToSet);
};
if (config.ENV === 'dev') {
    global.queryGet = get;
}


/**
 * Get all items of a data type
 * @param  {string} dataType
 * @return {array}
 */
export function getAll(dataType) {
    return Db.getSortedAndTransformedData()[dataType];
}
if (config.ENV === 'dev') {
    global.queryGetAll = getAll;
}


/**
 * Get original id from id
 * @param  {string} originalId
 * @param  {string} dataType
 * @return {number}
 */
export function getIdFromOriginalId(originalId, dataType) {
    let item = find([ item => item.original_id === originalId ], dataType, null, true);

    if (item) {
        return item.id;
    } else {
        console.error(LOG_PREF+'Could not find item of type \'' + dataType + '\' matching original_id value: ' + originalId);
    }
}


/**
 * @param  {array} criterias: array of functions
 * @param  {string} dataType
 * @param  {array} relatedDataToSet
 * @param  {boolean} findOne  stop when first occurence is found
 * @return {array}
 */
export const find = (criterias, dataType, relatedDataToSet, findOne) => {
    if (!dataType) {
        console.error(LOG_PREF+'Missing data `dataType` argument', dataType);
        return;
    }
    if (typeof Db.getData()[dataType] === 'undefined') {
        console.error(LOG_PREF+'Unsupported data `dataType`', dataType);
        return;
    }
    if (!criterias || Array.isArray(criterias) !== true || criterias.length === 0) {
        console.error(LOG_PREF+'Missing criterias to look for '+dataType+' items.');
        return;
    }

    let data = getAll(dataType);

    // Apply criteria functions. Return true is all criterias match.
    function applyCriterias(item) {
        let match = true;

        for (let i=0; i<criterias.length && match; i++) {
            match = match && criterias[i](item);
        }
        return match;
    }

    let matches;
    if (findOne) {
        let match = data.find(applyCriterias);
        matches = match ? [ match ] : [];
    } else {
        matches = data.filter(applyCriterias);
    }

    // Fetch related data
    if (Array.isArray(relatedDataToSet)) {
        matches = matches.map(item => completeData(item, dataType, relatedDataToSet));
    }

    if (findOne) {
        return matches.length > 0 ? matches[0] : null;
    }
    return matches;
};
if (config.ENV === 'dev') {
    global.queryFind = find;
}
