module.exports = {
  name: 'pisc',
  client: 'pisc',
  description: 'App for PISC (2019)',
  pluginId: 'mobigeo-plugin-pisc',
  version: '1.0.0',
  validLangs: ['fr', 'en'],
  native: {
    name: {},
    // by env
    version: '1',
    id: '',
    // by env
    iosBuildTarget: '10.0',
    androidFadeSplashScreenDuration: 750,
    // 'ionic/www', 'simple', 'test', 'demo', 'app-react'
    app: 'app-react',
    backgroundColor: '0xffffff'
  },
  // List the folders of the data bundles to include for this project
  data: {
    default: 'elaiP2Soo2go'
  },
  // 'location', 'location.geogroup', 'location.geopush', 'map.route', 'assets.update', 'poi.aroundme', 'poi.states'
  features: [''],
  location: {
    mapping: {},
    registering: {
      location: false,
      geofencing: false
    }
  },
  undeliveredFolders: ['source', 'exports'],
  cordova: true,
  crypto: false,
  // @see doc on google drive: R&D/Migration WK webview: https://docs.google.com/spreadsheets/d/1sIfm3LgBq1Zgp7LH2-mKSD1vrL6VphNR1mkiHZ73zHM/edit?usp=drive_web&ouid=101003723454836243359
  // @see cordova/bin/addIosCustomUrlScheme.js
  // @see app-react/src/core/data-and-assets/FileSystemHelper.js `getPath`
  // @see app-react/src/core/util/FetchHelper.js
  customFileUrlScheme: 'msfile',
  cordova_plugins: {
    mobigeo_plugin_dependencies: [{
      id: 'cordova-plugin-file-transfer'
    }],
    app_dependencies: [{
      id: 'cordova-plugin-whitelist'
    }, {
      id: 'cordova-plugin-inappbrowser'
    }, {
      id: 'cordova-plugin-splashscreen'
    }, {
      id: 'pushwoosh-cordova-plugin@7.9.4'
    }, {
      id: 'cordova-plugin-wkwebview-engine'
    }, {
      id: 'cordova-plugin-wkwebview-file-xhr'
    }]
  },
  geoGroup: {},
  web: {}
};