
import { INBOX_PAGE_KEY } from 'src/pages/pagesKeys';

import { parseAction } from 'src/store/reducers/utils';

import { prefixWith } from 'src/store/reducers/utils';

import {
    AD_CONFIG_LOADED,
    POLL_CONFIG_LOADED,
    LANG_CHANGED,
    PROFILE_CHANGED,
    TOGGLE_MENU,
    UPDATE_PAGE_STATE,
} from 'src/store/actionTypes';

import {
    adConfigLoaded,
    pollConfigLoaded,
    getPageCommonDefaultState,
    langChanged,
    profileChanged,
    toggleMenu,
    updatePageState,
} from 'src/store/reducers/commons';

import { CORDOVA_ACTIVE as ACTIVE } from 'src/store/actionTypes';


export const prefix = prefixWith('inbox');

export const NOOP = prefix('noop');

// dispatch a message received from whatever origin
export const ADD_MESSAGE = prefix('add-message');

// notif that a notification inside the app was shown
export const MESSAGE_DISPLAYED = prefix('message-displayed');

// notif that a notification inside the app was shown
export const MESSAGE_DISMISSED = prefix('message-dismissed');

// notif that a notification inside the app was shown
export const MESSAGE_ACTIONED = prefix('message-actioned');

// dispatch bulk messages, on startup mostly
export const ADD_BULK_MESSAGES = prefix('add-bulk-messages');

// there was an error on loading
export const ADD_BULK_MESSAGE_ERROR = prefix('add-bulk-messages-error');

// to empty the inbox in one fell swoop
export const EMPTY_INBOX = prefix('empty-inbox');

// for duplicates messages
export const ADD_DUPLICATE_MESSAGE = prefix('add-duplicate-message');

// INCOMING MESSAGES
// used in the **meta** key of actions
export const MESSAGES_TYPES = {
    position: prefix('message-pi-position'),
    notification: prefix('message-pi-notification'),
    debug: prefix('message-debug'),
    push: prefix('push')
};

// db config will be stored under this key at startup will compare
// for equality if not then will wipe the entire db at previous config settings
export const DB_CONFIG_ENDPOINT = prefix('db-config');

// key used to set / get items in the localforage DB
export const DB_ENDPOINT = prefix('db-db01');

function id(x) {
    return x;
}



/**
 * NORMALIZED MESSAGES
 * message = {
 *  id: String,
 *  content: String,
 *  date: Numberl,
 *  read: boolean,
 *  title?: String,
 *  action: {
 *      type: String,
 *      value: String
 *  } || null
 * }
 *
 */
export function byId(state = {}, action) {
    switch (action.type) {
        case ADD_MESSAGE:
            return {
                ...state,
                [action.data.id]: action.data
            };
        case ADD_BULK_MESSAGES:
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }
}

/**
 * Sorts the message by date.
 * Stores the entire message in the array
 * Does not update the array
 * *Will not check if the message is already in the list*
 * @param state {[Message]}
 * @param action
 * @return state
*/
export function byDate(state = [], action) {

    switch (action.type) {

        case ADD_DUPLICATE_MESSAGE:
        case ADD_MESSAGE: {

            const message = action.data;
            let i = -1;
            const length = state.length;
            while (++i < length) {
                if ( message.date >= state[i].date ) {
                    return [
                        ...state.slice(0, i),
                        message,
                        ...state.slice(i)
                    ];
                }
            }
            return [ ...state, message ];
        }

        default:
            return state;
    }
}

/**
 * This reducer is dedicated to handling the behaviour of
 * the notification by keeping track of the context within
 * which each notification is received : whether the app should
 * display it as a popin inside the app or as a notification
 * wia the OS api
 *
 * state = null | messageId
 *
 * If the state is null, do nothing, if it is non null and
 * nothing is displayed then the component should display
 * the popin in the app
 *
 * The info is in action.meta.hasNotifAbility and is the value
 * of the related cordova plugin. If TRUE then the notif is
 * scheduled inside the middleware, if FALSE then it is shown
 * in the app
 *
 * @param state {Object}
 * @param action {Object}
 * @returns state
 */
export function notif(state = null, action) {

    const { meta } = action;

    switch (action.type) {

        case ADD_DUPLICATE_MESSAGE:
        case ADD_MESSAGE:
            if (state === null && typeof meta === 'object') {

                // test if you can display it through app inbox
                if (meta.hasNotifAbility === true && !meta.onStart) {
                    return action.data.id;

                } else {
                    return state;
                }

            }
            return state;

        case MESSAGE_DISPLAYED:
        case MESSAGE_DISMISSED:
        case MESSAGE_ACTIONED:
            return null;

        default:
            return state;
    }

}


const getDefaultState = () => Object.assign({}, getPageCommonDefaultState(INBOX_PAGE_KEY), {
    byId   : byId(undefined, NOOP),
    byDate : byDate(undefined, NOOP),
    notif  : notif(undefined, NOOP),
    isReady: false,
});


// Inbox reducer
export default function inbox(state = getDefaultState(), action) {

    switch (action.type) {

        case AD_CONFIG_LOADED : return adConfigLoaded(state, action);
        case POLL_CONFIG_LOADED: return pollConfigLoaded(state, action);
        case LANG_CHANGED     : return langChanged(state, action);
        case PROFILE_CHANGED  : return profileChanged(state, action, INBOX_PAGE_KEY);
        case TOGGLE_MENU      : return toggleMenu(state, action, INBOX_PAGE_KEY);
        case UPDATE_PAGE_STATE: return updatePageState(state, action, INBOX_PAGE_KEY);


        case ADD_MESSAGE:
            return {
                ...state,
                // check if message is already there before inserting it
                byDate: state.byId[action.data.id] ?
                        state.byDate :
                        byDate(state.byDate, action),
                // a new version of the message will overwrite, ids should be uniques !
                // and data immutable
                byId: byId(state.byId, action),
                notif: notif(state.notif, action)
            };


        case ADD_DUPLICATE_MESSAGE:
            return {
                ...state,
                byDate: byDate(state.byDate, action),
                notif: notif(state.notif, action)
            };


        case ADD_BULK_MESSAGES:{
            const _byId = byId(state.byId, action);
            const _byDate = action.data.byDate.reduce(( list, message ) => {

                return byDate(
                    list,
                    {
                        type: ADD_MESSAGE,
                        data: message
                    }
                );

            }, state.byDate);

            return {
                ...state,
                byId: _byId,
                byDate: _byDate,
                isReady: true
            };
        }


        case ADD_BULK_MESSAGE_ERROR:
            return {
                ...state,
                isReady: true
            };


        case MESSAGE_DISPLAYED:
        case MESSAGE_DISMISSED:
        case MESSAGE_ACTIONED:{
            return {
                ...state,
                notif: notif(state.notif, action)
            };
        }


        case EMPTY_INBOX:
            return {
                ...state,
                byId: {},
                byDate: [],
                notif: null
            };


        default:
            return state;
    }
}

export function getNotifAbility ( cordova ) {
    return cordova.status === ACTIVE;
}


/**
 * creates an handler for the MobigeoGeoPush alert subscription
 * @param {Object} options
 * @param {Store} options.store - current app store
 * @param {Number} options.throttle - when dispatching a message if a message
 *                                    with the same id is already present in the store
 *                                    and it was received before the throttle time
 *                                    elapsed then do nothing
 * @returns {Function} handler - function to pass to GeoPush.on(alert, _)
 *
 * This function encapsulate the following behaviour :
 *
 * - receiving a message from PlanetIntus
 * - normalizing this message
 * - dispatching tthe message with the correct action and context
 *   to the app store
 *
 * SIDE EFFECTS :
 * - adds a date field to all message with the current epoch
 *
 */
export function createNotificationHandler({
    store,
    throttle = 10 * 60 * 1000,
    selector = id
} = {}) {

    return function notificationHandler(alert) {

        const { title, content, action,  } = alert;

        const id = ( Math.random() * 100000 ) | 0;
        console.log('alert received', alert);

        // prevent invalid message dispatch here
        if (title === 'MESSAGE' || (typeof content === 'string' && content.length === 0)) {
            console.debug('message deemed invalid because of title or length, id : ' + id);
            return;
        }

        // proceed if id is 'useable' : if number then not NaN, if string, not 0 length
        const idType = typeof id;
        if ( (idType === 'number' && !isNaN(id)) || (idType === 'string' && id.length > 0)) {

            const actionType = typeof selector(store.getState()).byId[id] === 'object' ? ADD_DUPLICATE_MESSAGE : ADD_MESSAGE;

            let _action;
            try {
                _action = parseAction(action);

            } catch ( e ) {
                _action = {
                    type: 'inbox'
                };
                console.error('could not parse action', e);
            }

            store.dispatch({
                type: actionType,
                data: {
                    action: _action,
                    title: title || 'MESSAGE',
                    content,
                    id,
                    date: Date.now()
                },
                meta: {
                    hasNotifAbility: getNotifAbility(store.getState().Cordova),
                    type: MESSAGES_TYPES.notification,
                }
            });
        }
    };

}
