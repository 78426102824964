import React from 'react';
import PropTypes from 'prop-types';

class FacebookPost extends React.PureComponent {

    componentDidMount() {
        window.FB.XFBML.parse(window.document.getElementById(this.getPostId()))
    }

    getPostId = () => {
        const { href } = this.props
        const cleanbedHref = href.endsWith('/') ? href.slice(0, -1) : href
        const uriParts = cleanbedHref.split('/')
        return uriParts[uriParts.length - 1]
    }

    render() {
        const { href } = this.props

        return (
            <div id={this.getPostId()} className='media-facebook-post' >
                <div className="fb-post" data-href={href} data-show-text="false" />
            </div>
        );
    }

}

FacebookPost.propTypes = {
    href: PropTypes.string.isRequired
};

export default FacebookPost;
