
// Libs
import React from 'react';
import PropTypes from 'prop-types';

// Config
import { SEARCH_MIN_CHARS } from 'data/config/searchConfig';

import './SearchField.scss';


const LOG_PREF = '[SearchField] ';


class SearchField extends React.PureComponent {

    value = ''
    inputIsVoid = true
    inputEl = null
    state = {}

    getClearIconStyle = () => ({
        opacity: this.inputIsVoid ? .3 : 1,
    })

    onInputChange = e => {
        this.setValue(e.target.value);
    }

    /**
     * Handle search value change
     * @param {string} newValue
     */
    setValue(newValue) {
        if (this.value === newValue) {
            return;
        }
        this.value = newValue;

        // Empty input
        if (!this.value || this.value.length === 0) {
            this.inputIsVoid = true;
            this.props.clearResults();
            return;
        }
        // Non-empty input
        else {
            this.inputIsVoid = false;
            if (this.value.length > SEARCH_MIN_CHARS-1) {
                this.props.perform(this.value);
            }
        }
    }

    clear = () => {
        // Should be set
        if (this.inputEl) {
            this.inputEl.value = '';
            this.setValue('');
            this.focus();
        }
    }

    focus() {
        if (this.inputEl) {
            this.inputEl.focus();
        }
    }

    componentDidMount() {
        this.focus();
    }

    // componentDidUpdate() {
        // this.focus();
    // }

    setInputElement = el => {
        this.inputEl = el;
    }

    render() {
        console.log(LOG_PREF+'render');

        return (
            <div className="search-field-container">
                <input
                    ref={ this.setInputElement }
                    onChange={ this.onInputChange }
                    onBlur={ this.props.onBlur }
                    placeholder={ this.props.placeholder } />

                <span
                    className="search-field-clear fa fa-times"
                    style={ this.getClearIconStyle() }
                    onClick={ this.clear } />
            </div>
        );
    }
};

SearchField.propTypes = {
    placeholder : PropTypes.string.isRequired,
    perform     : PropTypes.func.isRequired,
    clearResults: PropTypes.func.isRequired,
    onBlur      : PropTypes.func,
};

export default SearchField;
