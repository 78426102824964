import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';

import MediaTabItem from './MediaTabItem';
import MediaTab from './MediaTab';

import config from '../../../data/config/config';

import AdBanner from 'src/components/ad-banner/AdBanner';
import { openUrl } from 'src/core/util/JsTools';

import { MEDIAS_PAGE_KEY } from 'src/pages/pagesKeys';

const tabHeight = 48

class MediasPageContent extends Component {
    state = {
        value: 0,
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (JSON.stringify(this.props) !== JSON.stringify(nextProps)) || nextState.value !== this.state.value
    }

    handleChange = (event, value) => {
        const { socialMediaConfig } = this.props

        this.setState({ value })

        const mediaKeys = Object.keys(socialMediaConfig)
        const platformClicked = mediaKeys[value]
        const platformConfig = socialMediaConfig[platformClicked]

        console.log('platformClicked', platformClicked)

        if (platformConfig.link) {
            openUrl(platformConfig.link, '_system');
        }
    }

    handleChangeIndex = index => {
        this.setState({ value: index })
    }

    render() {
        const { socialMediaConfig, actions } = this.props
        const mediaKeys = Object.keys(socialMediaConfig)

        return (
            <div className="media-content-container content-font" style={{ height: this.props.height }}>

                <div className="media-timeline" id="media-timeline">
                    <Tabs
                        value={this.state.value}
                        onChange={this.handleChange}
                        variant="fullWidth"
                        indicatorColor='transparent'
                    >
                        {mediaKeys.map((platform, index) => {
                            const config = socialMediaConfig[platform]
                            const active = this.state.value === index
                            return (
                                <MediaTabItem
                                    key={platform}
                                    config={config}
                                    active={active}
                                    actions={actions}
                                    resetTab={() => this.setState({ value: 0 })}
                                />
                            )
                        })}
                    </Tabs>
                    <SwipeableViews
                        slideStyle={{
                            height: this.props.height - tabHeight,
                        }}
                        containerStyle={{
                            height: this.props.height - tabHeight,
                        }}
                        index={this.state.value}
                        onChangeIndex={this.handleChangeIndex}
                    >
                        {mediaKeys.map(platform =>
                            <MediaTab
                                key={platform}
                                platform={platform}
                                config={socialMediaConfig[platform]}
                                data={{
                                    twitter: this.props.twitter,
                                    facebook: this.props.facebook,
                                    instagram: this.props.instagram,
                                    youtube: this.props.youtube,
                                    linkedin: this.props.linkedin,
                                }}
                                actions={this.props.actions}
                            />
                        )}
                    </SwipeableViews>
                </div>

                <AdBanner
                    associatedPageKey={MEDIAS_PAGE_KEY}
                    adConfig={this.props.adConfig}
                    actions={this.props.actions}
                    labels={this.props.labels} />
            </div>
        );
    }

}

MediasPageContent.propTypes = {
    socialMediaConfig: PropTypes.object.isRequired,
    twitter: PropTypes.object,
    facebook: PropTypes.object,
    instagram: PropTypes.object,
    youtube: PropTypes.object,
    linkedin: PropTypes.object,
    adConfig: PropTypes.object,
    height: PropTypes.number, // prop set from AppToolbar
    actions: PropTypes.object.isRequired,
    labels: PropTypes.object.isRequired,
};

export default MediasPageContent;
