
// libs
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Page } from 'react-onsenui';

import config from 'data/config/config';

import { FAVORITES_PAGE_KEY, SEARCH_PAGE_KEY } from 'src/pages/pagesKeys';

import { isWkWebview } from 'src/core/util/browser';

import {
    STATUS as SYNCHRO_FAV_STATUS,
    getButtonIcon as getSynchroFavButtonIcon,
} from 'src/core/favorites/SynchronizedFavoritesService';

// import SearchPlaceTypes from 'src/components-standalone/search-place-dialog/SearchPlaceTypes';

import './AppToolbar.scss';


// Warning: this value is defined in ./AppToolbar.scss, but also here due to computation issues
// (sometimes offsetHeight: 0 despite a call to requestAnimationFrame after component render/update)
const TOOLBAR_HEIGHT_FALLBACK = 46;



const getButton = (iconClassName, iconStyle, action, label) => (
    <span className="app-toolbar-icon" onClick={action} title={label}>
        <span className={iconClassName} style={iconStyle} />
    </span>
);

class AppToolbar extends PureComponent {

    state = {
        childrenStyle: null,
    }

    navigateToHome = () => {
        let homePage = config.getHomePage(this.props.profile);
        this.props.actions.navigate(homePage.pageKey, homePage.props);
    }

    navigateToFavoritesPage = () => {
        this.props.actions.navigate(FAVORITES_PAGE_KEY);
    }

    nativateToSearchPage = () => {
        //this.props.actions.showSearchPlaceDialog(SearchPlaceTypes.poi);
        this.props.actions.navigate(SEARCH_PAGE_KEY);
    }

    openMenu = () => {
        this.props.actions.openMenu(this.props.pageKey);
    }

    setWrapperEl = el => {
        this.wrapperEl = el;
    }

    setToolbarEl = el => {
        this.toolbarEl = el;
    }

    updateChildrenStyle = () => {
        let style;

        if (this.wrapperEl && this.toolbarEl) {
            const
                pageTop = this.wrapperEl.parentNode.offsetTop,
                contentTop = this.toolbarEl.offsetHeight || TOOLBAR_HEIGHT_FALLBACK;

            style = {
                top: contentTop,
                height: document.documentElement.clientHeight - contentTop - pageTop + (isWkWebview() ? 20 : 0),
            };
        } else {
            style = null;
        }

        if (JSON.stringify(style) !== JSON.stringify(this.state.childrenStyle)) {
            this.setState({ childrenStyle: style, });
        }
    }

    componentDidMount() {
        window.requestAnimationFrame(this.updateChildrenStyle);
    }
    componentDidUpdate() {
        window.requestAnimationFrame(this.updateChildrenStyle);
    }


    getToolbar = () => (
        <div className="app-toolbar" ref={this.setToolbarEl}>

            <div className="left">
                { this.props.hasBackButton &&
                     getButton('zmdi zmdi-arrow-back toolbar-back-btn', null, this.props.actions.navigateBack, this.props.labels.common.goBack)
                }
            </div>

            <div className="center content-font">{this.props.contextualTitle || this.props.title}</div>

            <div className="right">
                { this.props.rootCategoryButtonInToolbar &&
                    getButton('fa fa-list', null, this.props.navigateToRootCategory, this.props.labels.common.goToRootCategory)
                }
                { this.props.hasHomeButton &&
                    getButton('fa fa-home', null, this.navigateToHome, this.props.labels.common.goToHome)
                }
                { this.props.hasFavButton === true &&
                    getButton('zmdi zmdi-star', null, this.navigateToFavoritesPage, this.props.labels.common.goToFavorites)
                }
                { this.props.hasSearchButton === true &&
                    getButton('zmdi zmdi-search', null, this.nativateToSearchPage, this.props.labels.common.goToSearch)
                }
                { this.props.synchroFavBtnStatus && this.props.synchroFavBtnStatus !== SYNCHRO_FAV_STATUS.HIDDEN &&
                    getButton(
                        `synchro-fav-btn synchro-fav-btn-${this.props.synchroFavBtnStatus}`,
                        { backgroundImage: `url(${getSynchroFavButtonIcon(this.props.synchroFavBtnStatus)})` },
                        this.props.actions.synchroFavoritesIconClicked,
                        ''
                    )
                }
                { this.props.hasMenuButton === true &&
                    getButton('zmdi zmdi-menu', null, this.openMenu, this.props.labels.common.openMenu)
                }
            </div>

        </div>
    )


    setHeightOnChild = child => {
        if (this.state.childrenStyle && this.state.childrenStyle.height) {
            return React.cloneElement(child, { height: this.state.childrenStyle.height });
        }
        return child;
    }


    render() {
        // With toolbar
        if (this.props.isDisplayed !== false) {
            return (
                <Page>
                    <div className="app-toolbar-wrapper" ref={this.setWrapperEl}>

                        { this.getToolbar() }

                        <div className="app-toolbar-children" style={this.state.childrenStyle}>
                            { React.Children.map(this.props.children, this.setHeightOnChild) }
                        </div>
                    </div>
                </Page>
            );
        }
        // without toolbar
        else {
            return (
                <Page>
                    <div className="app-toolbar-wrapper">
                        <div className="app-toolbar-children">
                            {this.props.children}
                        </div>
                    </div>
                </Page>
            );
        }
    }

}

AppToolbar.propTypes = {
    labels         : PropTypes.object.isRequired,
    actions        : PropTypes.object.isRequired,
    profile        : PropTypes.string.isRequired,
    isDisplayed    : PropTypes.bool,
    title          : PropTypes.string.isRequired,
    contextualTitle: PropTypes.string,
    pageKey        : PropTypes.string,
    hasBackButton  : PropTypes.bool,
    hasHomeButton  : PropTypes.bool,
    hasFavButton   : PropTypes.bool,
    hasSearchButton: PropTypes.bool,
    synchroFavBtnStatus: PropTypes.string,
    rootCategoryButtonInToolbar : PropTypes.bool,
    navigateToRootCategory: PropTypes.func,
};

export default AppToolbar;
