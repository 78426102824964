
import React from 'react';
import PropTypes from 'prop-types';
import Measure from 'react-measure';

import { SEARCH_PAGE_KEY } from 'src/pages/pagesKeys';
import { CATEGORIES_DATA_TYPE } from 'data/config/dataConfig';

import SearchField from 'src/components/search/SearchField';
import List        from 'src/components/list/List';
import TypeBar     from 'src/components/type-bar/TypeBar';
import NoResult    from 'src/components/no-result/NoResult';


class SearchPageContent extends React.PureComponent {

    state = {}

    getResultLists() {
        if (this.props.totalCount > 0) {
            let listComponents = [];

            Object.keys(this.props.results).forEach(dataType => {

                // Skip empty categories
                if (this.props.results[dataType].length) {

                    const listProps = {
                        dataType : dataType,
                        items    : this.props.results[dataType],
                        favorites: this.props.favorites[dataType],
                        actions  : this.props.actions,
                        labels   : this.props.labels,
                        isPending: this.props.isPending,
                        highlight: this.props.searched,
                        pageKey  : SEARCH_PAGE_KEY,
                        displayFavorites: this.props.favIconDisabled !== true,
                        //isClosed   : this.props.totalCount > SEARCH_HIDE_LIST_IF_TOTAL_ABOVE,
                    };

                    // Skip categories who have no item
                    if (CATEGORIES_DATA_TYPE.indexOf(dataType) !== -1) {
                        listProps.items = listProps.items.filter(item => item.counter > 0);
                    }

                    if (listProps.items && listProps.items.length > 0) {
                        listComponents.push(
                            <TypeBar key={dataType}
                                     label={this.props.labels.data[dataType][listProps.items.length > 1 ? 'plural' : 'singular']}
                                     count={listProps.items.length}>
                                <List {...listProps} />
                            </TypeBar>
                        );
                    }
                }
            });

            if (listComponents.length > 0) {
                return listComponents;
            }
        }
        return <NoResult labels={this.props.labels} />;
    }

    onResultsDimensionChange = dims => {
        this.setState({
            resultsStyle: { height: document.documentElement.clientHeight - dims.top },
        });
    }

    render() {
        return (
            <div id="search-page-container" className="content-font" style={{ height: this.props.height }}>

                <SearchField
                    labels={this.props.labels}
                    placeholder={this.props.placeholder}
                    clearResults={this.props.actions.clearSearchResults}
                    perform={this.props.actions.performSearch} />

                <Measure whitelist={['height', 'top']} onResize={this.onResultsDimensionChange}>
                    {() => (

                    <div className="search-results" style={this.state.resultsStyle}>
                        { this.props.searched && this.getResultLists() }
                    </div>

                    )}
                </Measure>

            </div>
        );
    }
}

SearchPageContent.propTypes = {
    isPending: PropTypes.bool,
    placeholder: PropTypes.string,
    favIconDisabled: PropTypes.bool,
    favorites: PropTypes.object,
    searched: PropTypes.string,
    results: PropTypes.object,
    totalCount: PropTypes.number,
    actions: PropTypes.object.isRequired,
    labels: PropTypes.object.isRequired,
    height: PropTypes.number, // prop set from AppToolbar
};

export default SearchPageContent;