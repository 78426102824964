
import { debounce } from 'lodash';

import {
    NAVIGATE,
    NAVIGATE_BACK,
    WINDOW_RESIZED,
} from 'src/store/actionTypes';

import { _navigate } from 'src/core/navigation/Router';
import { back } from 'src/core/navigation/History';
import TwoColumnsModeManager from 'src/core/navigation/TwoColumnsModeManager';


const handleWindowResized = debounce(function _handleWindowResized() {
    TwoColumnsModeManager.autoEnable(true);
}, 1000);


export default ({ dispatch, getState }) => next => action => {

    switch (action.type) {

        case NAVIGATE:
            _navigate(
                action.pageKey,
                action.options,
                action.transition,
                action.pushToHistory
            );
            break;


        case NAVIGATE_BACK:
            back();
            break;


        case WINDOW_RESIZED:
            // Detect when '2 columns mode' can be toggled
            handleWindowResized();
            break;

        default:
    }
    return next(action);
};
