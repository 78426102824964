
import {
    ADD_BULK_MESSAGES,
    ADD_BULK_MESSAGE_ERROR,
    // ADD_MESSAGE,
    createNotificationHandler,
    getNotifAbility,
} from './inboxReducer';

import { DB_CONFIG_ENDPOINT, DB_ENDPOINT } from './inboxReducer';

let localforage = require('localforage');


const LOG_PREF = '[InboxService] ';

export function init(store) {

    // for dev only
    // demonstrates the process of opening a subscription
    // wihtin a middleware to dispatch directly inside the store
    /*if ( process.env.NODE_ENV !== 'production' || global.isCordovaContext ) {

        const dummies = [
            null,
            'service/517',
            'aircraft/16',
            'exhibitor/372605',
            'event/382',
            'newproduct/1165',
            'media',
            'pmr',
            // 'flights_schedule/3', this case in not taken into account for now
            'flights_schedule',
            'service/514',
            'https://www.siae.fr/evenements/paris_air_lab.htm',
            'map/pois?originalId=311571&type=Exhibitor',
            'map/pois?originalId=366494&type=Exhibitor&placeId=193',
        ].map(parseAction);

        let nextActionId = 0;

        const shortNotif = 'a short notif';
        const longNotif =  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras elementum ultrices '

        global.addMessage = function addMessage () {
            return store.dispatch({
                type: ADD_MESSAGE,
                data: {
                    id: nextActionId++,
                    content: nextActionId % 2 ? longNotif : shortNotif,
                    title: 'A notification',
                    date: Date.now(),
                    read: false,
                    action: dummies[nextActionId % dummies.length]
                },
                meta: {
                    type: MESSAGES_TYPES.debug,
                    hasNotifAbility: getNotifAbility(store.getState().Cordova)
                }
            });
        }
    }*/

    // Retrieve messages from the db and returns them as an action
    localforage
        .getItem(DB_ENDPOINT)
        .then( (messages = {
            byId: {},
            byDate: []
        }) => {
            if (typeof messages !== 'object' || messages === null) {
                throw new TypeError('values stored in the DB are not valid messages');
            } else {
                store.dispatch({
                    type: ADD_BULK_MESSAGES,
                    data: messages,
                    meta: {
                        // adds a 'meta' key to give context to this action
                        // in this case whether the env can display the message itself
                        // or if it should be done within the app
                        hasNotifAbility: getNotifAbility(store.getState().Cordova)
                    }
                });
            }
        })
        .catch(err => store.dispatch({
            type: ADD_BULK_MESSAGE_ERROR,
            data: err
        }));


    // MobiGeo GeoPush: subscribe to the  notifications
    if (typeof MobiGeo !== 'undefined' && MobiGeo && MobiGeo.Location && MobiGeo.Location.GeoPush) {
        MobiGeo.Location.GeoPush.on('alert', createNotificationHandler({ store, selector: state => state.Inbox }) );
    }


    // check if config is still relevant
    localforage
        .getItem(DB_CONFIG_ENDPOINT)
        .then(db_endpoint => {

            if (db_endpoint !== DB_ENDPOINT) {

                // replace old value with new one
                localforage.setItem(DB_CONFIG_ENDPOINT, DB_ENDPOINT)
                    .then(
                        () => console.log(LOG_PREF+'Successfully deleted old config'),
                        error => console.error(LOG_PREF+'Could not delete old config due to error ' + error)
                    );
            }
        });

}