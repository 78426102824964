

import {
    AD_CONFIG_LOADED,
    LANG_CHANGED,
    PROFILE_CHANGED,
    TOGGLE_MENU,
    UPDATE_PAGE_STATE,
    SOCIAL_FEED_LOADED,
    FETCH_SOCIAL_FEED
} from 'src/store/actionTypes';

import {
    getPageCommonDefaultState,
    langChanged,
    profileChanged,
    toggleMenu,
    updateObject,
    updatePageState,
} from 'src/store/reducers/commons';

import { MEDIAS_PAGE_KEY } from 'src/pages/pagesKeys';

import {
    getAdConfig,
    getSocialMediaConfig
} from 'src/core/ads/AdManager';

import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';


const getDefaultState = () => Object.assign({}, getPageCommonDefaultState(MEDIAS_PAGE_KEY), {
    twitter: { posts: [], initialFetch: true },
    facebook: { posts: [], initialFetch: true },
    instagram: { posts: [], initialFetch: true },
    youtube: { posts: [], initialFetch: true },
    linkedin: { posts: [], initialFetch: true }
});

const _adConfigLoaded = (state, action) => (
    updateObject(state, {
        adConfig    : getAdConfig(state.profile),
        socialMediaConfig: getSocialMediaConfig(state.profile)
    })
);

function _profileChanged(state, action) {
    let newValues = profileChanged(state, action, MEDIAS_PAGE_KEY, true);
    newValues.socialMediaConfig = getSocialMediaConfig(state.profile)
    return updateObject(state, newValues);
}

const _feedLoaded = (state, action) => {
    const fetchedPosts = action.posts || []
    let feed = state[action.platform].posts

    const initialPayload = action.initialPayload

    if (initialPayload && (initialPayload.sinceId || initialPayload.maxId)) {
        if (initialPayload.sinceId) {
            feed = fetchedPosts.concat(feed)
        } else if (initialPayload.maxId) {
            feed = feed.concat(fetchedPosts)
        }

        return updateObject(state, {
            [action.platform]: {
                posts: feed,
                initialFetch: false
            }
        })
    }

    return updateObject(state, {
        [action.platform]: {
            posts: action.posts,
            initialFetch: false
        }
    })
}

const _feedLoading = (state, action) => {
    return updateObject(state, {
        [action.platform]: {
            initialFetch: action.initial
        }
    })
}

export default (state = getDefaultState(), action) => {
    switch (action.type) {

        case AD_CONFIG_LOADED : return _adConfigLoaded(state, action);
        case LANG_CHANGED     : return langChanged(state, action);
        case PROFILE_CHANGED  : return _profileChanged(state, action);
        case TOGGLE_MENU      : return toggleMenu(state, action, MEDIAS_PAGE_KEY);
        case UPDATE_PAGE_STATE: return updatePageState(state, action, MEDIAS_PAGE_KEY);
        case SOCIAL_FEED_LOADED: return _feedLoaded(state, action);
        case FETCH_SOCIAL_FEED: return _feedLoading(state, action);

        default: return state;
    }
};
