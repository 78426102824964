
import {
    DATA_TYPE_AIRCRAFT_CATEGORIES,
    DATA_TYPE_EVENT_CATEGORIES,
    DATA_TYPE_EXHIBITOR_CATEGORIES,
    DATA_TYPE_SERVICE_CATEGORIES,
    DATA_TYPE_AIRCRAFTS,
    DATA_TYPE_EVENTS,
    DATA_TYPE_EXHIBITORS,
    DATA_TYPE_SERVICES,
} from 'data/config/dataConfig';

import {
    CHOOSE_PROFILE_PAGE_KEY,
    HOME_PAGE_KEY,
    LIST_PAGE_KEY,
    MEDIAS_PAGE_KEY,
} from 'src/pages/pagesKeys.js';

import fetchHelper         from 'src/core/util/FetchHelper';
import { getUrl }          from 'src/core/data-and-assets/DataAssetsUtil';
import { adConfigLoaded }  from 'src/store/actions';


const LOG_PREF = '[AdManager] ';

export const AD_CONFIG_PATH = '/files/project/config.json';


let reduxStore,
    adConfig;

export const init = () => {

    const adConfigUrl = getUrl(AD_CONFIG_PATH);
    console.log(LOG_PREF+'About to load ad config from '+adConfigUrl);

    fetchHelper(adConfigUrl, null, true,
        (data) => {
            console.log('Ad config loaded', data);
            adConfig = data;
            reduxStore.dispatch(adConfigLoaded(adConfig));
        },
        (err) => {
            console.warn('could not get ad config', err);
            // Dispatch action even if request has failed to allow boot sequence to start
            // @see store/reducers/bootMiddleware
            reduxStore.dispatch(adConfigLoaded(adConfig));
        },
        // Do not display a modal on error
        false
    );

};


export const getAdConfig = (profile) => adConfig && adConfig.ads ? adConfig.ads[profile] : null;
export const getSocialMediaConfig = (profile) => adConfig && adConfig.socialmedia ? adConfig.socialmedia[profile] : null;


export const setReduxStore = (store) => {
    reduxStore = store;
};



export const AD_KEYS = {
    HOME       : 'home',
    HOME_HEADER: 'header',
    MEDIA      : 'media',
    AIRCRAFTS  : 'aircrafts',
    EVENTS     : 'events',
    SERVICES   : 'services',
    EXHIBITORS : 'exhibitors',
};


/**
 * NB: Can be overriden by AdBanner `forcedAdKey` prop
 * @param {string} pageKey
 * @param {array}  listInputs
 */
export const getAdKeyForPage = (pageKey, listInputs) => {

    switch (pageKey) {

        case CHOOSE_PROFILE_PAGE_KEY: return AD_KEYS.HOME_HEADER;
        case HOME_PAGE_KEY: return AD_KEYS.HOME_HEADER;

        case MEDIAS_PAGE_KEY: return AD_KEYS.MEDIA;

        case LIST_PAGE_KEY:

            if (!listInputs || listInputs.length === 0) {
                console.error(LOG_PREF+'Could not determine ad for page:', pageKey, ' as list inputs are missing:', listInputs);
                return null;
            }
            else if (listInputs.length === 1) {

                /*if (typeof listInputs[0].id !== 'undefined') {
                    // No ad on a subcategory content
                    return null;
                }
                else*/ if (listInputs[0].dataType === DATA_TYPE_AIRCRAFT_CATEGORIES
                        || listInputs[0].dataType === DATA_TYPE_AIRCRAFTS) {
                    return AD_KEYS.AIRCRAFTS;
                }
                else if (listInputs[0].dataType === DATA_TYPE_EVENT_CATEGORIES
                        || listInputs[0].dataType === DATA_TYPE_EVENTS) {
                    return AD_KEYS.EVENTS;
                }
                else if (listInputs[0].dataType === DATA_TYPE_EXHIBITOR_CATEGORIES
                        || listInputs[0].dataType === DATA_TYPE_EXHIBITORS) {
                    return AD_KEYS.EXHIBITORS;
                }
                else if (listInputs[0].dataType === DATA_TYPE_SERVICE_CATEGORIES
                        || listInputs[0].dataType === DATA_TYPE_SERVICES) {
                    return AD_KEYS.SERVICES;
                }
                else {
                    // No ad for other types
                    return null;
                }
            }
            else if (listInputs.length === 2) {

                if (typeof listInputs[0].id !== 'undefined' || typeof listInputs[1].id !== 'undefined') {
                    // No ad on a subcategory content
                    return null;
                }
                else if (listInputs[0].dataType === DATA_TYPE_EXHIBITOR_CATEGORIES
                        || listInputs[1].dataType === DATA_TYPE_EXHIBITOR_CATEGORIES) {
                    return AD_KEYS.EXHIBITORS;
                }
                else {
                    // No ad for other types
                    return null;
                }
            }
            break;

        default: console.error(LOG_PREF+'Unhandled page '+pageKey);
    }
    console.error(LOG_PREF+'Could not determine ad for page:', pageKey, ', list inputs:', listInputs);
    return null;
};


/**
 * e.g available width is 314px because of 3px horizontal margins,
 * if an ad width is 320px, it will be selected as 320 <= 314+AD_ALLOWED_OVERFLOW
 * @type {Number}
 */
const AD_ALLOWED_OVERFLOW = 6;

/**
 * Copied/pasted from old cake framework, adapted what needed to be adapted
 * @param  {object} files
 * @param  {number} width
 * @param  {number} height
 * @return {string}
 */
export const getBestRessource = (files, width, height) => {

    if (!files || typeof files !== 'object') {
        console.error(LOG_PREF+'Cannot determine best resource from files argument:', files);
        return;
    }

    let checkHeight = true,
        orientation,
        orientations = Object.keys(files);

    switch (orientations.length) {
        case 0: console.error(LOG_PREF+'Cannot determine ad because no file is defined', files);
            return;

        case 1: orientation = orientations[0];
            break;

        default: // > 1
            if (document.documentElement.clientWidth > document.documentElement.clientHeight) {
                orientation = 'l'; // landscape
            } else {
                orientation = 'p'; // portrait
            }
    }

    let _files = files[orientation];


    // get the closer format available
    var imageWidth = null;
    for (var i in _files) {
        if (_files.hasOwnProperty(i) === false) {
            continue;
        }
        i = parseInt(i, 10);

        if (imageWidth === null) {
            imageWidth = i;
        }

        if (i <= width+AD_ALLOWED_OVERFLOW) {
            imageWidth = i;
        }
    }

    var ads = _files[imageWidth];
    if (!ads) {
        console.error('No ads defined for this format', imageWidth);
        return;
    }

    var imageHeight = null;
    for (i in ads) {
        if (ads.hasOwnProperty(i) === false) {
            continue;
        }
        i = parseInt(i, 10);

        if (imageHeight === null) {
            imageHeight = i;
        }

        if (checkHeight === false) {
            if (i >= imageHeight) {
                imageHeight = i;
            }
        }
        else {
            if (i <= height) {
                imageHeight = i;
            }
        }
    }

    ads = _files[imageWidth][imageHeight];

    // get the best Device Pixel Ratio ads
    var adFile = null;
    for (i in ads) {
        if (ads.hasOwnProperty(i) === false) {
            continue;
        }
        i = parseInt(i, 10);

        if (adFile === null) {
            adFile = ads[i];
        }

        if (i === parseInt(window.devicePixelRatio, 10)) {
            adFile = ads[i];
        }
    }

    return {
        width : imageWidth,
        height: imageHeight,
        file  : adFile
    };
};