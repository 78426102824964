import React from 'react'

const MAX = 80

export default (props, state ) => {
    const { max, yRefreshing, y, phase } = state
    const color = '#00354c'
    const bgColor = '#FFF'
    const p = Math.atan(y / max)
    const refreshed = phase === 'refreshed'

    const distance = Math.max(refreshed ? Math.atan(1) : p, 0) * max - 10

    if (y <= 0.00000001) return null

    return (
        <div
            key='pull'
            className='refresh-spinner-wrapper'
            style={{
                transform: `translate(-50%, -100%) scale(${refreshed ? p : 1},${refreshed ? p : 1})`,
                backgroundColor: bgColor,
                top: distance
            }}
        >
            <div
                style={{
                    transform:`rotate(${yRefreshing}deg)`
                }}
            >
                <div className={`infinit-table-spinner ${phase === 'refreshing' ? 'rotate' : ''}`}/>
            </div>
        </div>
    )
}
