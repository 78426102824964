import _ from 'lodash';
import { fetchContributionsFeed }  from 'src/store/actions';
import { prefixWith } from 'src/store/reducers/utils';

import { get as getProfile } from 'src/core/Profile';

import * as config from 'data/config/config';

export const LOG_PREF = '[ContributionsFeedManager]';

export const prefix = prefixWith('contributions-feed');

let reduxStore,
    fetchInterval = null;

export const isEnabled = () => {
    const profile = getProfile() || 'default'
    return config.CONTRIBUTIONS_FEED.FEATURE_ENABLED && (profile === 'default')
}

export const init = () => {
    if (isEnabled()) {
        console.log(`${LOG_PREF} init`)

        const contributionsFeedUrl = config.CONTRIBUTIONS_FEED.URL;

        // every config.CONTRIBUTIONS_FEED.INTERVAL seconds
        fetchInterval = window.setInterval(() => {
            reduxStore.dispatch(fetchContributionsFeed());
        }, config.CONTRIBUTIONS_FEED.INTERVAL)

        // in cordova when app is pulled from the background
        document.addEventListener('resume', () => {
            console.log(LOG_PREF+'resume event');
            reduxStore.dispatch(fetchContributionsFeed());
        });

        // at init time
        reduxStore.dispatch(fetchContributionsFeed());
    }
};

export const stop = () => {
    if (fetchInterval) {
        window.clearInterval(fetchInterval)
    }
}

export const setReduxStore = (store) => {
    reduxStore = store;
};
