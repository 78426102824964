
import {
    SHOW_SEARCH_PLACE_DIALOG,
    SEARCHED_PLACE_SELECTED
} from 'src/store/actionTypes';

import {
    hideSearchPlaceDialog,
    setSearchPlaceDialogCancelable,
    showOnePoiOnMap,
} from 'src/store/actions';

import SearchPlaceTypes from './SearchPlaceTypes';


const CANCELABLE_DELAY = 100;

const dispatchDialogCancelable = dispatch => {
    dispatch(setSearchPlaceDialogCancelable(true));
};


export default ({ dispatch, getState }) => next => action => {
    switch (action.type) {


        case SHOW_SEARCH_PLACE_DIALOG:
            // Onsen often triggers a "onCancel" event right after dialog is opened....
            // so we open the dialog as isCancelable:false, and after a short while we set back isCancelable:true
            window.setTimeout(dispatchDialogCancelable, CANCELABLE_DELAY, dispatch);
            break;


        case SEARCHED_PLACE_SELECTED:
            if (action.searchType === SearchPlaceTypes.poi) {
                dispatch(hideSearchPlaceDialog());
                if (action.entry) {
                    dispatch(showOnePoiOnMap({
                        type      : action.entry.type,
                        id        : parseInt(action.entry.id, 10),
                        originalId: action.entry.originalId,
                        placeId   : action.entry.placeId,
                    }));
                } else {
                    console.error('Missing action.entry to show POI on map');
                }
            }
            break;


        default:
    }
    return next(action);
};
