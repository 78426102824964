
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';


/**
 * Common component to display events or flights
 */
class DetailEvents extends PureComponent {

    render() {
        let length = Array.isArray(this.props.items) ? this.props.items.length : 0;

        // Skip component display
        if (length === 0) {
            return null;
        }

        const label = this.props.labels.data.events[length > 1 ? 'plural' : 'singular']

        return (
            <div className="fiche-related-data">
                <div className="prop-img">
                    <div className="prop-left">
                        <div className="icon-font">
                            <span className="link">b</span>
                        </div>
                    </div>
                    <div className="prop-right evenements color-link-blue clickable" onClick={this.props.onClick}>
                        <div>
                            <span className="count">{length}</span>
                            <span>{label}</span>
                        </div>
                    </div>
                    <div className="prop-right-fleche">
                        <span className="fleche fa fa-chevron-right" />
                    </div>
                </div>
            </div>
        );
    }
}

DetailEvents.propTypes = {
    items  : PropTypes.array,
    labels : PropTypes.object.isRequired,
    onClick: PropTypes.func,
};
export default DetailEvents;