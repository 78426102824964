
import { CHOOSE_PROFILE_PAGE_KEY } from 'src/pages/pagesKeys';

import * as AppStatus from 'src/core/AppStatus';
import { checkProfile, get as getCurrentProfile } from 'src/core/Profile';
import { queuedNavigation, parseCurrentUrl } from 'src/core/navigation/Router';
import TwoColumnsModeManager from 'src/core/navigation/TwoColumnsModeManager';
import * as PushWooshManager from 'src/core/PushWooshManager';
import { navigate, showIntersticiel } from 'src/store/actions';
import { isAndroid, isIOS, openCurrentPageInApp } from 'src/core/util/browser';

import {
    AD_CONFIG_LOADED,
    DATA_UPDATED,
    HAS_NAVIGATED,
}  from 'src/store/actionTypes';


let adConfigLoaded = false,
    dataIsReady    = false,
    mountCount     = 0;


/**
 * Before the first page can be displayed, these are required:
 *   - ad config loaded (files/project/config.json): because the page to display could contain an ad
 *   - data loaded: because it might be needed to get item 'id' value from 'originalId' query parameter
 */
function bootIfReady(dispatch) {
    if (AppStatus.hasBooted() !== true && adConfigLoaded === true && dataIsReady === true) {
        AppStatus.setBooted(true)

        // Opening a notification from OS panel
        if (PushWooshManager.hasQueuedActions() === true) {
            // FIXME: what if `profile` is needed?
            console.info('Boot: skip default page display due to pushwoosh queued actions');
            return;
        }



        let routingData;
        if (queuedNavigation.isEmpty() !== true) {
            routingData = queuedNavigation.get();
        } else {
            routingData = parseCurrentUrl();
        }

        if (checkProfile() !== true) {
            dispatch(navigate(CHOOSE_PROFILE_PAGE_KEY, { thenNavigateTo: routingData }));
        } else {
            dispatch(
                showIntersticiel(true, function() {
                    TwoColumnsModeManager.autoEnable(mountCount !== 0);
                    dispatch(navigate(routingData.pageKey, routingData.props));
                })
            );
        }

        // iOS hack (when navigating, <body> background image is visible)
        document.getElementById('app-root').classList.add('app-ready');
    }
}


export default ({ dispatch, getState }) => next => action => {
    switch (action.type) {


        case AD_CONFIG_LOADED:
            adConfigLoaded = true;
            bootIfReady(dispatch);
            break;


        case DATA_UPDATED :
            dataIsReady = true;
            bootIfReady(dispatch);
            break;


        case HAS_NAVIGATED:
            mountCount++;
            if (mountCount < 2) {

                if (TwoColumnsModeManager.isEnabled()) {
                    TwoColumnsModeManager.showSecondPage(
                        action.pageKey,
                        action.pageProps,
                        getCurrentProfile(),
                        // Action to navigate:
                        function(pageKey, pageProps) {
                            dispatch(navigate(pageKey, pageProps));
                        }
                    );
                }
            }
            break;

        default:
    }
    return next(action);
};
