import _defineProperty from "/Users/matthieugayon/Documents/development/mobilespot/mobigeo-sdk-pisc2019/app-react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _conf;

import { DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_EXHIBITORS, DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EVENTS, DATA_TYPE_SPEAKERS } from './dataConfig'; // Perform search when user has typed at least n characters

export var SEARCH_MIN_CHARS = 2; //export const SEARCH_HIDE_LIST_IF_TOTAL_ABOVE = 10;

/**
 * Data types to look through to find POI on map or itinerary start/destination
 * @type {Array}
 */

export var DATATYPES_WITH_PLACES = [DATA_TYPE_EVENTS, DATA_TYPE_EXHIBITORS, DATA_TYPE_SPEAKERS]; // Common 'title'

var getTitleAttribute = function getTitleAttribute(item) {
  return item.title;
};
/**
 * Expose:
 *  - data types
 *  - and functions returning the value
 * on which search is performed
 */


var conf = (_conf = {}, _defineProperty(_conf, DATA_TYPE_EXHIBITOR_CATEGORIES, getTitleAttribute), _defineProperty(_conf, DATA_TYPE_EXHIBITORS, getTitleAttribute), _defineProperty(_conf, DATA_TYPE_EVENT_CATEGORIES, getTitleAttribute), _defineProperty(_conf, DATA_TYPE_EVENTS, getTitleAttribute), _defineProperty(_conf, DATA_TYPE_SPEAKERS, function (item) {
  return item.lastname;
}), _conf);
export var get = function get(profile) {
  return conf;
};