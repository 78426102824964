
// Libs
import React from 'react';
import * as localforage from 'localforage';
import './polyfills';
// import * as OfflinePluginRuntime from 'offline-plugin/runtime';

// Config
import config from 'data/config/config';

// app modules
import * as AdManager from './core/ads/AdManager';
import * as PollManager from './core/polls/PollManager';
import * as ContributionsFeedManager from './core/contributions-feed/ContributionsFeedManager';
import { addMeaningfulCssClass } from './core/util/browser';
import * as CordovaHelper from './core/cordova/CordovaHelper';
import * as DataAssetsUtil from './core/data-and-assets/DataAssetsUtil';
import * as GoogleAnalyticsHelper from './core/analytics/GoogleAnalyticsHelper';
import * as HomeTilesCssHandler from './pages/home/HomeTilesCssHandler';
import * as InboxService from './pages/inbox/InboxService';
import * as Lang from './core/Lang';
import * as LoginService from './core/login/LoginService';
import * as Profile from './core/Profile';
import * as ProfileService from './core/profile/ProfileService';
import * as PushWooshManager from './core/PushWooshManager';
import * as ResizeHandler from './core/util/ResizeHandler';
import * as Router from './core/navigation/Router';
import * as SynchronizedFavoritesService from 'src/core/favorites/SynchronizedFavoritesService';
import * as TileClickEffect from './core/util/TileClickEffect';
import mountStandaloneComponents  from './components-standalone/mountStandaloneComponents';

import createStore from './store/createStore';
import * as actions from './store/actions';


// onsenui css - https://onsen.io/v2/guide/react/
import 'onsenui/css/onsenui.css';
// import 'onsenui/css-components-src/src/theme.css';
// import 'onsenui/css/onsen-css-components.css';
import '../static-lib/onsenui/css-components-src/src/components/index.css';

// app common css
import './scss/index.scss';
import './components/components.scss';
import 'data/config-scss/fonts.scss';
import 'data/config-scss/custom.scss';

global.isCordovaContext = typeof cordova === 'object' && cordova !== null;


const initializaApp = () => {

    console.info('Initializing app '+process.env.APP_NAME+' v'+process.env.APP_VERSION+' build:'+process.env.BUILD_VERSION);

    // OfflinePluginRuntime.install();

    // Set localized labels (from persisted lang, or use default lang)
    Lang.init();

    // Detect default profile
    Profile.init();

    if (global.isCordovaContext !== true) {
        // Fetch ad config
        AdManager.init();

        PollManager.init();
    } else {
        // In cordova context, when Updater module has initialized then AdManager.init is called
        // @see adManagerMiddleware
    }

    // Initialize Redux store
    let reduxStore = createStore();

    // Listen to resize event (HomePage tiles needs to recalculate their size)
    ResizeHandler.init();

    // Get DOM root
    let rootEl = document.getElementById('app-root');
    [
        Router,
        HomeTilesCssHandler,
        TileClickEffect,
    ].forEach((module, index) => {
        module.setRootContainer(rootEl);
    });

    addMeaningfulCssClass();

    // Mount isolated components
    mountStandaloneComponents(reduxStore, rootEl);

    // Fetch data (for current language)
    DataAssetsUtil.init();

    // Init PushWoosh
    PushWooshManager.init();

    // Load and init GA API
    GoogleAnalyticsHelper.init();

    // Init synchronized favorites feature
    if (config.SYNCHRONIZED_FAVORITES.FEATURE_ENABLED === true) {
        SynchronizedFavoritesService.init();
    }

    // Init autologin (will start if a login token is found)
    if (config.LOGIN.FEATURE_ENABLED) {
        LoginService.init();
    }

    // Init profile
    if (config.PROFILE.FEATURE_ENABLED === true) {
        reduxStore.dispatch(actions.updateProfile(ProfileService.getProfile()));
    }

    // Init contributions feed
    if (ContributionsFeedManager.isEnabled()) {
        ContributionsFeedManager.init()
    }

    InboxService.init(reduxStore);

    // Specific cordova app settings :
    CordovaHelper.init(reduxStore);


    // dev helpers
    if (config.ENV === 'dev') {
        const { whyDidYouUpdate } = require('why-did-you-update');
        whyDidYouUpdate(React);

        global.appStore = reduxStore;
        global.actions = actions;
        global.localforage = localforage;
    }
};


document.addEventListener(global.isCordovaContext ? 'deviceready' : 'DOMContentLoaded', () => {

    initializaApp();

    // First page is displayed only once ad config has been loaded (for intersticiel)
    // @see store/reducers/bootMiddleware
});
