
import { DISABLE_FAVORITE_ICON } from 'data/config/listConfig';
import {
    DATA_TYPE_INFOS,
    // CATEGORIES_DATA_TYPE,
} from 'data/config/dataConfig';

import {
    AD_CONFIG_LOADED,
    POLL_CONFIG_LOADED,
    ALL_FAVORITES_DELETED,
    DATA_UPDATED,
    LANG_CHANGED,
    LISTS_FETCHED,
    PROFILE_CHANGED,
    TOGGLE_FAVORITE,
    TOGGLE_MENU,
    UPDATE_PAGE_STATE,
} from 'src/store/actionTypes';

import {
    adConfigLoaded,
    pollConfigLoaded,
    dataUpdated,
    getPageCommonDefaultState,
    langChanged,
    profileChanged,
    toggleMenu,
    updateObject,
    updatePageState,
} from 'src/store/reducers/commons';

import { LIST_PAGE_KEY } from 'src/pages/pagesKeys';


const getDefaultState = () => Object.assign({}, getPageCommonDefaultState(LIST_PAGE_KEY), {
    favorites: {},
    favIconDisabled: DISABLE_FAVORITE_ICON === true,
});


function getUsedTables(inputs) {
    if (!inputs) {
        return [];
    }
    return inputs.map(input => input.dataType)
}


/**
 * Initial action for list page
 * @param  {object} state
 * @param  {object} action
 * @return {object}
 */
function _listsFetched(state, action) {
    // GR - do not handle infos type (HoursPages)
    let hasInfosType = (action.inputs || []).filter(input => input.dataType === DATA_TYPE_INFOS).length > 0;

    if (!hasInfosType) {
        let firstDataType = Object.keys(action.items)[0];

        return updateObject(state, {
            inputs     : action.inputs,
            items      : action.items,
            status     : action.status,
            favorites  : action.favorites,
            contextualTitle: action.header ? null : action.contextualTitle,
            header     : action.header,
            shouldFetch: false,
            hasShowOnMapButton: Array.isArray(action.items[firstDataType])
                                    && action.items[firstDataType].length > 0
                                    && state.locateAll === true,
        });
    }
    return state;
}

function _toggleFavorite(state, action) {
    if (action.favListUpdated === true && getUsedTables(state.inputs).indexOf(action.dataType) !== -1) {
        return updateObject(state, { favorites: action.favorites });
    }
    return state;
};

const _allFavoritesDeleted = state => updateObject(state, { favorites: {} });


/*function _updatePageState(state, action) {
    let newState = updatePageState(state, action, LIST_PAGE_KEY);

    if (newState !== state) {
        // State has been updated. Display 'go to root category' button if:
        let firstInput = newState.inputs[0];
        newState.rootCategoryButtonInToolbar =
            // - data type is not a category type
            CATEGORIES_DATA_TYPE.indexOf(firstInput.dataType) === -1
            // - or there is a subcategory id
            || (firstInput.id !== null && typeof firstInput.id !== 'undefined' && firstInput.id !== '');
    }
    return newState
}*/


export default (state = getDefaultState(), action) => {
    switch (action.type) {

        case AD_CONFIG_LOADED : return adConfigLoaded(state, action);
        case POLL_CONFIG_LOADED: return pollConfigLoaded(state, action);
        case ALL_FAVORITES_DELETED: return _allFavoritesDeleted(state);
        case DATA_UPDATED     : return dataUpdated(state, action, getUsedTables(state.inputs));
        case LANG_CHANGED     : return langChanged(state, action);
        case LISTS_FETCHED    : return _listsFetched(state, action);
        case PROFILE_CHANGED  : return profileChanged(state, action, LIST_PAGE_KEY, true);
        case TOGGLE_FAVORITE  : return _toggleFavorite(state, action);
        case TOGGLE_MENU      : return toggleMenu(state, action, LIST_PAGE_KEY);
        case UPDATE_PAGE_STATE: return updatePageState(state, action, LIST_PAGE_KEY);

        default: return state;
    }
};
