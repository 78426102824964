
import {
    AD_CONFIG_LOADED,
    CORDOVA_RESUME,
    PROFILE_CHANGED,
    SHOW_INTERSTICIEL,
} from 'src/store/actionTypes';

import { showIntersticiel } from 'src/store/actions';

import { getAdConfig } from 'src/core/ads/AdManager';
import { get as getProfile } from 'src/core/Profile';


let resumeActionCount = 0,
    profile = getProfile(),
    n;


/**
 * Read the configuration indicating Intersticiel
 * component display frequency on BG/FG switch
 */
const setNValue = () => {
    let adConfig = getAdConfig(profile);

    if (adConfig && adConfig.interstitiels && adConfig.interstitiels.foreground) {
        n = adConfig.interstitiels.foregroundFrequency;
    }
};


export default ({ dispatch, getState }) => next => action => {
    switch (action.type) {

        case PROFILE_CHANGED:
            profile = action.profile;
            setNValue();
            break;

        case AD_CONFIG_LOADED:
            setNValue();
            break;

        case CORDOVA_RESUME:
            resumeActionCount++;
            if (typeof n === 'number' && resumeActionCount % n === 0) {
                dispatch(showIntersticiel());
            }
            break;

        case SHOW_INTERSTICIEL:
            if (typeof action.next === 'function') {
                // Delay for cleaner UX
                window.setTimeout(action.next, 200);
            }
            break;

        default:
    }
    return next(action);
};
