
import config from 'data/config/config';
import { DATA_TYPE_EXHIBITORS } from 'data/config/dataConfig';

import { get as getLabels }   from 'src/core/Lang';
import fetchHelper            from 'src/core/util/FetchHelper';
import { isProfileEnoughSet } from 'src/core/profile/ProfileService';

import NotificationLevels     from 'src/components-standalone/notifications/NotificationLevels';

import {
    showNotification,
    appointmentRequestSendResult,
} from 'src/store/actions';


let reduxStore;

export function setReduxStore(store) {
    reduxStore = store;
};


const LOCALSTORAGE_KEY = 'appointmentRequests';

/**
 * Sub-mobule responsible for persistence
 * @type {Object}
 */
const persistence = {
    get: function(){
        let value = localStorage.getItem(LOCALSTORAGE_KEY)
        return value ? JSON.parse(value) : null;
    },
    set: function(value){
        localStorage.setItem(LOCALSTORAGE_KEY, typeof value === 'object' ? JSON.stringify(value) : value);
    },
};


/**
 * Persist status for an appointment request
 * @param {String} dataOriginalId
 * @param {String} dataType
 * @param {object} status
 */
function setRequestStatus(dataOriginalId, dataType, status) {
    let requests = persistence.get();

    // Initialize if needed
    if (!requests) {
        requests = {};
    }
    if (!requests[dataType]) {
        requests[dataType] = {};
    }

    requests[dataType][dataOriginalId] = status;

    persistence.set(requests);
}


export function getRequestStatus(dataOriginalId, dataType) {
    let requests = persistence.get();

    if (requests && requests[dataType]) {

        let requestStatus = requests[dataType][dataOriginalId];
        if (requestStatus) {
            return requestStatus;
        }
    }
    return null;
};


const getErrorLabel = errorCode => getLabels().appointment.errors[errorCode];


function onRequestSuccess(json, dataOriginalId, dataType, dataId, userEmail, userPhone) {
    let status = {};
    if (userEmail) {
        status.userEmail = userEmail;
    }
    if (userPhone) {
        status.userPhone = userPhone;
    }

    // Show notification
    if (json.error) {
        let errorLabel = getErrorLabel(json.error);
        reduxStore.dispatch(showNotification({
            message: errorLabel,
            level  : NotificationLevels.WARNING,
        }));
    }

    // Failure
    if (json.error && json.error !== 'ALREADY_SENT') {
        status.error = { error: json.error };
        reduxStore.dispatch(appointmentRequestSendResult(false, dataOriginalId, dataType, dataId, status));
    }
    // Success
    else {
        // Considering 'ALREADY_SENT' as not an error allows to:
        //   - update UI with the proper label
        //   - properly store that information into localstorage

        status.date = new Date(json.date);

        // Persist
        setRequestStatus(dataOriginalId, dataType, status);

        // Dispatch
        reduxStore.dispatch(appointmentRequestSendResult(true, dataOriginalId, dataType, dataId, status));
    }
}

function onRequestFailure(failReason, dataOriginalId, dataType, dataId) {
    console.error('sendRequestFailure', arguments);

    // Show failure notification
    const errorLabel = getLabels().appointment.errors.GENERIC;
    reduxStore.dispatch(showNotification({
        message: errorLabel,
        level  : NotificationLevels.WARNING,
    }));

    // Dispatch failure
    let status = {
        error: {
            label : errorLabel,
            reason: failReason,
        }
    };
    reduxStore.dispatch(appointmentRequestSendResult(false, dataOriginalId, dataType, dataId, status));
}

export function sendRequest(dataOriginalId, itemDataType=DATA_TYPE_EXHIBITORS, dataId, profile) {

    // Best practice: check that needed `profile` values are set before calling this function
    // The 2 tests below are just security nets
    if (!profile) {
        console.error('Cannot send appointment request because profile is missing.', profile);
        return;
    }
    if (isProfileEnoughSet(profile) !== true) {
        console.error('Cannot send appointment request because profile is incomplete.', profile);
        return;
    }

    // Request options
    let body = {
        user: {
            civility : profile.title, // optional
            firstname: profile.firstName,
            lastname : profile.lastName,
            org      : profile.organisation,
            role     : profile.position,
            email    : profile.email,
            tel      : profile.phone, // optional
        },
    };

    if (itemDataType === DATA_TYPE_EXHIBITORS) {
        body.exhOriginalId = String(dataOriginalId);
    } else {
        console.error('Cannot send appointment request because of unhandled data type: '+itemDataType);
        return;
    }


    fetchHelper(
        config.APPOINTMENT_REQUEST.URL+'/send',
        {
            method: 'POST',
            body: JSON.stringify(body),
            headers: [{ name: 'Content-Type', value: 'application/json' }],
        },
        true, // is json
        json => { onRequestSuccess(json, dataOriginalId, itemDataType, dataId, profile.email, profile.phone); },
        failReason => { onRequestFailure(failReason, dataOriginalId, itemDataType, dataId); },
        false // no modal on error
    );
};
