
import React     from 'react';
import PropTypes from 'prop-types';

import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';

import './Description.scss';


const Description = props => {

    const
        hasDescription = typeof props.description === 'string' && props.description.length > 0,
        imgElement = props.logoFileName ? <img src={ getUrl('/'+props.logoFileName) } alt="logo" /> : null;

    return (
        <div className="fiche-description">
            { (props.isLogoFullWidth || !hasDescription) && imgElement && <div className="logo img-full-width">{ imgElement }</div> }

            { hasDescription &&
                <div className="prop-name">
                    <div className="prop-description">
                        { !props.isLogoFullWidth && imgElement && <div className="logo img-left">{ imgElement }</div> }

                        { hasDescription &&
                            <p dangerouslySetInnerHTML={{ __html: props.description }} />
                        }
                    </div>
                </div>
            }
        </div>
    );
};

Description.propTypes = {
    logoFileName: PropTypes.string,
    description : PropTypes.string,
    isLogoFullWidth: PropTypes.bool,
};

export default Description;
