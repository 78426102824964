
import { UPDATER_INITIALIZED, AD_CONFIG_UPDATED } from 'src/store/actionTypes';

import { init } from 'src/core/ads/AdManager';


export default ({ dispatch, getState }) => next => action => {
    switch (action.type) {


        case UPDATER_INITIALIZED:
            console.log('adManagerMiddleware UPDATER_INITIALIZED')

            // assets versions list is available
            // FALL-THROUGH
            // eslint-disable-line no-fallthrough
        case AD_CONFIG_UPDATED:
            console.log('adManagerMiddleware AD_CONFIG_UPDATED')
            init();
            break;


        default:
    }
    return next(action);
};
