
import {
    HAS_LOGGED_OUT,
    REAL_TIME_CONNECTED,
    SET_LOGIN_IDENTIFICATION,
    SYNCHRO_FAVORITES_ICON_CLICKED,
    TOGGLE_FAVORITE,
} from 'src/store/actionTypes';

// import { get as getLabels } from 'src/core/Lang';

import * as SynchronizedFavoritesService from './SynchronizedFavoritesService';


export default ({ dispatch, getState }) => next => action => {
    switch (action.type) {


        case SET_LOGIN_IDENTIFICATION:
            SynchronizedFavoritesService.setUserId(action.identification);
            break;

        case HAS_LOGGED_OUT:
            SynchronizedFavoritesService.onLogout();
            break;

        case SYNCHRO_FAVORITES_ICON_CLICKED:
            SynchronizedFavoritesService.handleClickOnIcon();
            break;

        case TOGGLE_FAVORITE:
            // Handle only if action resulted in a favorite update
            if (action.favListUpdated && action.noSync !== true) {
                // Add or remove a favorite
                SynchronizedFavoritesService.set(
                    action.id,
                    action.dataType,
                    SynchronizedFavoritesService.ACTIONS[action.isFav === true ? 'DELETE' : 'CREATE'],
                );
            }
            break;

        case REAL_TIME_CONNECTED:
            // Auto synchronization on reconnection
            SynchronizedFavoritesService.instantSynchronize();
            break;


        default:
    }

    return next(action);
};
