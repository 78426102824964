export default {
  id: 'en',
  common: {
    appTitle: 'Pisc 2O19',
    colon: ': ',
    noResult: 'No result',
    yes: 'Yes',
    no: 'No',
    ok: 'OK',
    confirm: 'Confirm',
    cancel: 'Cancel',
    close: 'Close',
    keep: 'Keep',
    delete: 'Delete',
    chooseLang: 'Choose language',
    addToFavorites: 'Add to favorites',
    defaultPlaceLabel: 'Map',
    noNetworkTitle: 'Network unavailable',
    noNetwork: 'Please try again later',
    loading: 'Please wait...',
    showAll: 'Show all on map',
    noPlacesToShowOnMap: 'No related place to show on map',
    termsOfUse: 'Terms of use',
    information: 'Information',
    contact: 'Contact',
    display: 'Display',
    switch2Columns: 'Switch to a page setup optimized for your screen width? (recommended)',
    share: 'Share',
    goBack: 'Go back',
    goToHome: 'Go to home',
    goToRootCategory: 'Go back to section home',
    login: 'Log in',
    goToFavorites: 'See your favorites',
    goToInbox: 'See messages',
    goToSearch: 'Go to search page',
    openMenu: 'Open menu',
    relatedContent: 'Related Content',
    contributions: 'Contributions',
    feedback: 'Feedback',
    rateThisSession: 'Rate this session'
  },
  disclaimer: {
    text: 'Cookies are used on this website to compute anonymous usage statistics.'
  },
  share: {
    mail: 'Share by email',
    facebook: 'Share on Facebook',
    linkedin: 'Share on LinkedIn',
    twitter: 'Share on Twitter',
    shareFail: 'Failed to share',
    linkedinShareDone: 'Page shared on your LinkedIn profile',
    linkedinShareFail: 'Failed to share on LinkedIn',
    facebookShareDone: 'Page shared on your Facebook profile',
    facebookShareFail: 'Failed to share on Facebook'
  },
  data: {
    exhibitors: {
      singular: 'Sponsor',
      plural: 'Sponsors',
      title: 'Sponsors'
    },
    exhibitor_categories: {
      singular: 'Sponsor category',
      plural: 'Sponsor categories',
      title: 'Sponsors'
    },
    services: {
      singular: 'Service',
      plural: 'Services',
      title: 'Services'
    },
    service_categories: {
      singular: 'Service category',
      plural: 'Service categories',
      title: 'Services'
    },
    events: {
      singular: 'Conference',
      plural: 'Conferences',
      title: 'Conferences',
      subevents: 'Speeches'
    },
    event_categories: {
      singular: 'Conference category',
      plural: 'Conference categories',
      title: 'Conferences'
    },
    speakers: {
      singular: 'Speaker',
      plural: 'Speakers',
      title: 'Speakers',
      moderators: 'Moderators'
    },
    newproducts: {
      singular: 'Product',
      plural: 'Products',
      title: 'Products'
    },
    newproduct_categories: {
      singular: 'Product category',
      plural: 'Product categories',
      title: 'Products'
    }
  },
  dataListDialog: {
    defaultTitle: 'todo default title'
  },
  size: {
    bytes: ' bytes',
    kilobytes: ' kB',
    megabytes: ' mB'
  },
  update: {
    modalTitle: 'Update',
    update_detected: 'Proceed to data update ?',
    cancel_update: 'Cancel update',
    update_done: 'Update completed',
    new_version_detected: 'New version detected',
    timeout: 'Timeout'
  },
  lang: {
    fr: 'french',
    en: 'english'
  },
  menu: {
    toggleProfile: 'Change profile',
    profile: 'Profile',
    home: 'Home',
    inbox: 'Inbox',
    exhibitors: 'Sponsors',
    map: 'Map',
    newproducts: 'New products',
    events: 'Programme',
    speakers: 'Speakers',
    services: 'Practical Information',
    socialmedia: 'Social Media',
    poll: 'Vote & Polls',
    language: 'Language'
  },
  home: {
    title: 'Home',
    home: 'Home',
    exhibitors: 'Sponsors',
    newproducts: 'NEW PRODUCTS',
    events: 'Programme',
    services: 'PRACTICAL INFORMATION',
    map: 'Map',
    socialmedia: 'Social Media',
    poll: 'VOTE & POLLS',
    speakers: 'Speakers'
  },
  profile: {
    title: 'Choose profile',
    mainLabel: 'Select your profile',
    pro: 'PRO',
    gp: 'PUBLIC',
    exposant: 'EXHIBITOR',
    visiteur: 'VISITOR',
    presse: 'PRESS'
  },
  brand: {},
  exhibitor: {
    contacts: 'contacts',
    entities: 'entities',
    agentCountries: 'agent countries',
    brands: 'brands',
    activities: 'activities',
    categories: 'categories'
  },
  paper: {
    categories: 'categories',
    openPdf: 'OPEN PDF',
    authors: ''
  },
  placeSearch: {
    editStart: 'Edit start',
    setDestination: 'Choose destination',
    itinerary: 'Itinerary',
    start: 'Choose your start point',
    destination: 'Destination',
    poi: 'POI'
  },
  newproducts: {
    launchDate: 'Launch date'
  },
  events: {
    to: 'to',
    access: 'Access',
    publics: 'Public'
  },
  map: {
    title: 'Map',
    goTo: 'Go there',
    isOnThisFloor: ' is on this floor',
    shouldReload: 'Map data has been updated, would you like to reload now?'
  },
  favorites: {
    title: 'Favorites'
  },
  agenda: {
    title: 'Programme'
  },
  medias: {
    title: 'Social Media',
    label: 'Follow us on'
  },
  pmr: {
    title: 'MIP'
  },
  search: {
    title: 'Search',
    placeholderSuffix: '... (min. 2 char.)',
    submitLabel: 'Ok'
  },
  hours: {
    title: 'Opening hours'
  },
  intersticiel: {
    closeLabel: 'Skip Ad'
  },
  promoMs: 'Powered by Mobile-Spot',
  inbox: {
    title: 'Inbox',
    empty: 'You have no message',
    dismiss: 'Dismiss',
    confirmNotif: 'OK'
  },
  login: {
    title: 'Delegate Login',
    fillTheFields: 'Please fill in both fields before submitting',
    badLogin: 'The login or password you entered are incorrect',
    loginText: 'Login',
    passwordText: 'Password',
    loginButton: 'Login',
    loginSuccess: 'You are logged-in',
    loginDescription: 'Enter your Login (registration number) and Password printed on the back of your badge. It will grant you access to PDFs in the Papers section',
    introductoryText: 'Welcome !',
    forgottenPassword: 'I forgot my password',
    createAccount: 'Create an account',
    tos: 'Terms of use',
    profile: 'Profile',
    logout: 'Logout',
    noNetwork: 'You are not connected to the Internet',
    loading: 'Checking...'
  },
  profileDialog: {
    title: 'Profile',
    fields: {
      title: 'Civility',
      firstName: 'First Name',
      lastName: 'Last Name',
      organisation: 'Organisation',
      position: 'Position',
      email: 'Email',
      phone: 'Phone'
    },
    confirm: 'Confirm'
  },
  appointment: {
    button: 'Appointment request',
    setProfileFirst: 'You must fill-in your profile to use this feature.',
    sent: function sent(formatedDate, email, phone) {
      return "Appointment request sent ".concat(formatedDate, ". Your recipient will answer you by email at ").concat(email) + (phone ? " or by phone ".concat(phone) : '') + '.';
    },
    errors: {
      GENERIC: 'Could not send the appointment request.',
      INVALID_REQUEST: 'An error occured while sending the appointment request.',
      EXHIBITOR_NOT_FOUND: 'An error occured while sending the appointment request.',
      EXHIBITOR_HAS_NO_CONTACT_EMAIL: 'An error occured while sending the appointment request.',
      ALREADY_SENT: 'An appointment request has already been sent to this exhibitor.'
    }
  },
  synchroFavs: {
    favoritesPageNotice: 'Once logged-in, your favourites are synchronized across your user sessions on mobile and web.',
    logoutConfirm: 'Your favourites are no longer synchronized on this terminal. Do you want to keep them locally or delete them ?',
    status: {
      // hidden: '', no icon
      // disabled: '', show login modal
      ongoing: 'Synchronizing your favourites',
      failed: 'Your favourites are not synchronized, check your data connection',
      success: 'Your favourites are synchronized'
    }
  },
  poll: {
    listTile: 'Available Polls',
    noPolls: 'No poll available',
    sessionTitle: 'Session rating',
    sessionCodeError: 'INVALID CODE',
    fillTheFields: 'Please enter a code before submitting',
    code: 'code',
    enterCode: 'Please enter the session code',
    pleaseRate: 'Please rate',
    back: 'back',
    next: 'next',
    commentPlaceholder: 'your comment',
    thank: 'Thank you',
    submit: 'Submit',
    submitted: 'Already Submitted',
    done: 'Done',
    noNetwork: 'You have no internet connexion. You can press cancel now and Submit later. Your answers have been saved.',
    willOpen: 'This poll will be opened on',
    willClose: 'This poll will be closed on',
    isClosed: 'This poll is closed',
    requireAuthentication: 'Please Log In.',
    started: 'Started',
    notStarted: 'Not started',
    serverError: 'Server error. You can press cancel now and Submit later. Your answers have been saved.'
  },
  contributions: {
    lastRefreshed: 'Last update time',
    refresh: 'Refresh',
    ps: 'Current preferential subject (PS)',
    current: 'Current contribution',
    upcoming: 'Upcoming contributions',
    past: 'Past contributions',
    cancelled: 'Cancelled contributions',
    undefined: 'Undefined contributions',
    more: 'More',
    notBefore: 'not before',
    programmeSlogan: 'To get the Contributions schedule in real time, please go to each Group Discussion Meeting page in Technical Programme / Group Discussion Meetings',
    empty: 'No contributions scheduled yet'
  }
};