
import { platform } from 'onsenui';

import config from 'data/config/config';
// import { getLocation } from 'src/core/navigation/History';

function getLocation() {
    return window.location;
}


let reduxStore;
export function setReduxStore(store) {
    reduxStore = store;
}


/**
 * Detect if app is running on iOS
 */
export const isIOS = () => platform.isIOS();


const iosVersionRegExp = / OS ([\d]*)_[\d]*/;
/**
 * Determine iOS version
 * @return {number|null} null is returned is version could not be determined (e.g device is not an iPhone/iPad)
 */
export function getIosVersion() {
    let result = iosVersionRegExp.exec(navigator.userAgent);

    if (!result || result.length < 2) return null;

    return parseInt(result[1], 10);
}


export const isWkWebview = () => !!(window.webkit && window.webkit.messageHandlers);


/**
 * Detect if app is running on Android
 */
export const isAndroid = () => platform.isAndroid();


/**
 * Detect if app is running on Internet Explorer
 */
export const isIE = () => navigator.userAgent.indexOf('MSIE ') !== -1 || navigator.userAgent.indexOf('Trident/') !== -1;


const edgeRegExp = /Edge\/(\d{2})/;
/**
 * Detect if app is running on Edge
 * Note: Since Edge 16, CSS custom properties are supported, so fixes are not needed
 */
export function isEdgeBelow16() {
    const result = edgeRegExp.exec(navigator.userAgent);

    return result && typeof result.index === 'number' && parseInt(result[0], 10) < 16;
};


export function addMeaningfulCssClass() {

    // Add some helpful css classes indicating on which environment the app is running on
    // Cordova / web
    document.body.classList.add(global.isCordovaContext ? 'is-cordova' : 'is-web');

    // iOS
    if (isIOS()) {
        document.body.classList.add('is-ios');
    } else {
        document.body.classList.add('not-ios');

        // Android
        if (isAndroid()) {
            document.body.classList.add('is-android');
        } else {
            document.body.classList.add('not-android');

            // IE
            if (isIE()) {
                document.body.classList.add('is-ms'); // Used for IE/Edge common fixes
                document.body.classList.add('is-ie');
            }
            // Edge
            else if (isEdgeBelow16()) {
                document.body.classList.add('is-ms'); // Used for IE/Edge common fixes
                document.body.classList.add('is-old-edge');
            }
        }
    }
}
