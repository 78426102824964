
// Libs
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Splitter, SplitterSide, SplitterContent, Page, List } from 'react-onsenui';

// config
import { get as getMenuConfig, ONS_MENU_OPTIONS } from 'data/config/menuConfig';

// app modules
import GenericMenuItem from './GenericMenuItem';
import getAppVersionConfigEntry from './getAppVersionConfigEntry';


import './Menu.scss';


// const LOG_PREF = '[Menu] ';


/**
 * @param {string} profile (user profile)
 * @param {object} options
 * @param {string} associatedPageKey
 * @param {Components} children (nested elements)
 * @param {object} labels (for curent lang)
 * @param {object} actions (store binded actions)
 * @param {object} adConfig (may be used in menuConfig)
 */
class Menu extends PureComponent {

    onOpen = () => {
        this.props.actions.openMenu(this.props.associatedPageKey);
    }

    onClose = () => {
        this.props.actions.closeMenu(this.props.associatedPageKey);
    }

    render() {

        // Merge default ons-menu options and optional overriding options
        const onsMenuOptions = Object.assign({}, ONS_MENU_OPTIONS, this.props.options);

        // Get items configuration, depending on profile, current page, etc
        const confEntries = getMenuConfig(
                                this.props.profile,
                                this.props.associatedPageKey,
                                this.props.actions,
                                this.props.labels,
                                this.props.adConfig,
                                this.props.twoColumns);

        /**
         * Return an array of components from menuConfig entries
         */
        let menuItems = confEntries.map((entry, index) => (
            <GenericMenuItem
                key={index}
                entry={entry}
                associatedPageKey={this.props.associatedPageKey}
                closeMenu={this.props.actions.closeMenu} />
        ));

        menuItems.push(
            <GenericMenuItem
                key={menuItems.length}
                entry={getAppVersionConfigEntry(this.props.labels)}
                associatedPageKey={this.props.associatedPageKey}
                closeMenu={this.props.actions.closeMenu}
                isDisabled={true} />
        );

        return (
            <Splitter>
                <SplitterSide
                        {...onsMenuOptions}
                        onClose={this.onClose}
                        onOpen={this.onOpen}>
                    <Page>
                        <div className="menu-items-container">
                            <List>
                                { menuItems }
                            </List>
                        </div>
                    </Page>
                </SplitterSide>

                <SplitterContent>
                    { this.props.children }
                </SplitterContent>
            </Splitter>
        );
    }

}

Menu.propTypes = {
    labels  : PropTypes.object.isRequired,
    options : PropTypes.object.isRequired,
    profile : PropTypes.string,
    children: PropTypes.oneOfType([
                    PropTypes.element,
                    PropTypes.arrayOf(PropTypes.element),
              ]).isRequired,
    // Indicate on which page the menu instance is attached
    associatedPageKey: PropTypes.string.isRequired,
};

export default Menu;
