
import React     from 'react';
import PropTypes from 'prop-types';

import { VALID_DATA_TYPES } from 'data/config/dataConfig';


/**
 * Common component to display places
 */
const DetailPlaces = ({ id, originalId, entityType, places, labels, actions }) => {
    if (Array.isArray(places) !== true || places.length === 0) {
        return null;
    }

    let placesPerLabel = {};

    places.forEach(place => {
        // Skip invalid items
        if (!place) {
            return;
        }

        let label = place.label || labels.common.defaultPlaceLabel;
        if (!placesPerLabel[label]) {
            placesPerLabel[label] = [];
        }
        placesPerLabel[label].push(place.id);
    });

    return (
        Object.keys(placesPerLabel).length === 0
        ? null
        : <div className="fiche-related-data">
            <div className="prop-img">
                <div className="prop-left">
                    <div className="icon-font">
                        <span className="link">h</span>
                    </div>
                </div>
                <div className="prop-right">
                    <div>
                        <ul className="localisations">
                            { Object.keys(placesPerLabel).map(label => (
                                <li key={label}
                                    className="clickable"
                                    onClick={() => {
                                        let POI = {
                                            id        : id,
                                            originalId: originalId,
                                            type      : entityType,
                                        };
                                        if (placesPerLabel[label].length === 1) {
                                            POI.placeId = placesPerLabel[label][0];
                                        } else {
                                            // FIXME:
                                            // generate as much POI objects as there are places
                                            // let pois = [{id: originalId, type: entityType, placeId: placeId1}, {id: originalId, type: entityType, placeId: placeId2}, ...]
                                            // finally call actions.showAllPoisOnMap(pois);
                                        }
                                        actions.showOnePoiOnMap(POI);
                                    }}>
                                    <span className="color-link-blue">{ label }</span>
                                </li>
                              ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

DetailPlaces.propTypes = {
    originalId: PropTypes.string.isRequired,
    entityType: PropTypes.oneOf(VALID_DATA_TYPES).isRequired,
    places    : PropTypes.array,
    labels    : PropTypes.object.isRequired,
    actions   : PropTypes.object.isRequired,
};

export default DetailPlaces;
