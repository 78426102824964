
import config from 'data/config/config';

import fetchHelper from 'src/core/util/FetchHelper';

import { updateProfile as updateProfileAction } from 'src/store/actions';


const LOCALSTORAGE_USER_PROFILE_KEY = 'userProfile';

const LOG_PREF = '[ProfileService] ';


let dispatch;
export function setReduxStore(store) {
    dispatch = store.dispatch;
}


export function getProfile() {
    return JSON.parse(window.localStorage.getItem(LOCALSTORAGE_USER_PROFILE_KEY));
}

export function setProfile(value) {
    window.localStorage.setItem(LOCALSTORAGE_USER_PROFILE_KEY, JSON.stringify(value));
}



export const isProfileEnoughSet = profile => !!(
    profile
    && profile.firstName
    && profile.lastName
    // && profile.title
    // && profile.organisation
    && profile.position
    && profile.email
    && profile.phone
);


export function updateProfile(newFields) {
    let currentProfile = getProfile();
    if (currentProfile === null) {
        currentProfile = {};
    }

    setProfile(Object.assign(currentProfile, newFields));
}


export function updateProfileFromApiData(data) {
    // Remove empty properties (to avoid overiding values set by the user)
    let cleanedProfile = {};

    Object.keys(data.profile).forEach(function(key) {
        let value = data.profile[key];
        if (value !== null && typeof value !== 'undefined' && value !== '') {
            cleanedProfile[key] = value;
        }
    });

    let newProfile = Object.assign({}, getProfile(), cleanedProfile);
    dispatch(updateProfileAction(newProfile));
}


/**
 * Retrieve profile information from token
 * @param  {string} token
 * @param  {function} successCb
 * @param  {function} failureCb
 */
export function getProfileFromAPI(token, successCb, failureCb) {
    if (!token) {
        console.error(LOG_PREF+'Cannot get profile information from API because \'token\' argument is missing');
        return;
    }

    let opts = {
        method: 'GET',
        headers: [{ name: 'Content-Type', value: 'application/json' }],
    };

    fetchHelper(
        `${config.LOGIN.PROFILE_URL}/${token}`,
        opts,
        true, // isJson
        successCb,
        failureCb,
        false // showModalOnError
    );
}
