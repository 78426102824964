
import React     from 'react';
import PropTypes from 'prop-types';

import {
    LIST_PAGE_KEY,
    AGENDA_PAGE_KEY,
    EVENT_PAGE_KEY,
} from 'src/pages/pagesKeys';

import './LinkButton.scss';

const dataConfig = require('data/config/dataConfig.js');

/**
 * Call-to-action button
 * @param  {object} props
 */
const LinkButton = ({ config, actions }) => {
	if (!config.id || !config.type || !config.label) return null

	const action = () => {
		const { id, type } = config
        let pageKey = LIST_PAGE_KEY
        let payload = {
          locateAll: false,
          inputs: [
            {
              id:id,
              dataType: type
            }
          ]
        }

        if (type === 'papers_list') {
          pageKey = LIST_PAGE_KEY
          payload = {
            locateAll: false,
            inputs: [{
              parentId: id,
              parentType: dataConfig.DATA_TYPE_PAPER_CATEGORIES,
              dataType : dataConfig.DATA_TYPE_PAPERS,
            }]
          }
        }

        if (type === 'event_categories') {
          pageKey = AGENDA_PAGE_KEY
          payload = {
            locateAll: false,
            input: {
              parentId: id,
              parentType: type
            }
          }
        }
        if (type === 'events') {
          pageKey = EVENT_PAGE_KEY
          payload = {
            id: id
          }
        }

		actions.navigate(
			pageKey,
			payload
		)
	}

	return (
        <div className="link-btn-container">
	        <div
	            className='link-btn'
	            onClick={action}
            >
                <span className='link-btn-label'>{config.label}</span>
                <span className="link-btn-icon fa fa-chevron-right"/>
            </div>
	    </div>
	)
}

LinkButton.propTypes = {
    config	: PropTypes.object.isRequired,
	actions : PropTypes.object.isRequired,
};

export default LinkButton;
