
/**
 * @see http://docs.pushwoosh.com/docs/cordova-phonegap
 */

import config from 'data/config/config';

import {
    ADD_MESSAGE,
    MESSAGES_TYPES
} from 'src/pages/inbox/inboxReducer';

import {
    DATA_UPDATED,
    CORDOVA_ACTIVE as ACTIVE,
    CORDOVA_PAUSE as PAUSE,
} from 'src/store/actionTypes';

import { playAction } from 'src/store/reducers/utils';
import { isIOS } from 'src/core/util/browser';


const LOG_PREF = '[PushWoosh] ';


let reduxStore;
export const setReduxStore = store => {
    reduxStore = store;
};


let isDataReady = false,
    queuedActions = [];


const determineAtion = message => {
    // custom data - Android
    if (message.userdata !== null && typeof message.userdata === 'object') {
        return message.userdata;
    }
    // custom data - iOS
    else if (typeof message.u === 'string') {
        return JSON.parse(message.u);
    }
    // default
    return {
        type: 'inbox'
    };
};


/* GR: To simulate an incoming push, execute:

    // NB:
    //  - `type` is plural
    //  - `id` holds original_id value

    // Go to the page of a POI
    var title = 'Go to exhibitor', userData = { "type":"exhibitors", "id":"hilcona-foodservice/stand-3c123" };

    // or show a POI on map
    var title = 'Show POI on map', userData = { "type": "map", "poi": { "type": "exhibitors", "id": "hilcona-foodservice/stand-3c123" }};

    // or go to inbox (a bit weird)
    var title = 'Go to inbox', userData = { "type":"inbox" };

    // or go to home
    var title = 'Go to home', userData = { "type":"home" };


    e = new Event('push-receive');
    e.notification = {
       onStart: false,
       android: {
          title: title,
          userdata: userData,
          'google.message_id': Math.floor(Math.random()*1000),
       },
       ios: {
          aps: { alert: title },
          userdata: userData,
          'google.message_id': Math.floor(Math.random()*1000),
       },
    };
    document.dispatchEvent(e);
*/


/**
 * @param  {Boolean} isIOS
 * @return {object}
 */
const handlers = (isIOS => {

    // IOS
    if ( isIOS ) {
        const commonIosHandler = e => {
            const message = e.notification.ios;
            return {
                data: {
                    title  : 'Notification',
                    content: message.aps.alert,
                    action : determineAtion(message),
                    id     : Math.floor(Math.random() * 100000),
                    date   : Date.now(),
                },
                onStart: e.notification.onStart
            };
        };

        return {
            onPushReceived: commonIosHandler,
            onNotificationReceived: commonIosHandler,
        };
    }

    // ANDROID
    else {
        const commonAndroidHandler = e => {
            const message = e.notification.android;
            return {
                data: {
                    title  : 'Notification',
                    content: message.title,
                    action : determineAtion(message),
                    id     : message['google.message_id'],
                    date   : Date.now(),
                },
                onStart: e.notification.onStart
            };
        };

        return {
            onPushReceived: commonAndroidHandler,
            onNotificationReceived: commonAndroidHandler,
        }
    }

})(isIOS());



export function init() {

    // Add event listeners
    if (global.isCordovaContext || process.env.NODE_ENV !== 'production') {

        /**
         * Triggered when receiving notification
         */
        document.addEventListener('push-receive', e => {

            console.info(LOG_PREF+'push-received', e);

            const { data, onStart } = handlers.onPushReceived(e);
            reduxStore.dispatch({
                type: ADD_MESSAGE,
                data,
                meta: {
                    hasNotifAbility: ( reduxStore.getState().Cordova.status === ACTIVE && !onStart ),
                    type: MESSAGES_TYPES.push
                }
            });

        }, false);


        /**
         * Triggered when actioned from OS notifications panel
         *    FG : always triggered
         *    BG : user interacts with local notif
         */
        document.addEventListener('push-notification', e => {

            console.info(LOG_PREF+'push-notification', e);

            const { data, onStart } = handlers.onNotificationReceived(e);

            const messageList = reduxStore.getState().Inbox.byDate;

            const isAlreadyThere = messageList.reduce((isAlreadyThere, message) => {

                if (isAlreadyThere) {
                    return true;

                } else {
                    // do a content comparison to check for presence
                    // because PW does not assign ids to its messages
                    return message.content === data.content;
                }

            }, false);

            console.debug(LOG_PREF+'already here', isAlreadyThere);
            console.debug(LOG_PREF+'is ios', isIOS());
            console.debug(LOG_PREF+'is on start', onStart);

            // we store a notification in the inbox for two reasons :
            // - if it started the app (bc then the message did not raise a *push-received* event)
            // - if we are on iOS and the message (or its content) is not already in the inbox.
            if (!isAlreadyThere) {
                reduxStore.dispatch({
                    type: ADD_MESSAGE,
                    data,
                    meta: {
                        hasNotifAbility: false,
                        type: MESSAGES_TYPES.push,
                        onStart
                    }
                })
            }

            // Anyway, execute action (?)
            if (data.action) {
                let toPerform = () => {
                    playAction(data.action, reduxStore.dispatch);
                };

                if (isDataReady) {
                    toPerform();
                } else {
                    queuedActions.push(toPerform);
                }
            }

        }, false);
    }


    // Require cordova plugin
    if (global.isCordovaContext && config.appId && config.projectId) {

        try {
            let pushwoosh = cordova.require('pushwoosh-cordova-plugin.PushNotification');

            pushwoosh.onDeviceReady({
                appid    : config.appId,
                projectid: config.projectId,
            });

            pushwoosh.registerDevice(
                status => {
                    console.debug(LOG_PREF+'Registered with push token: ' + status.pushToken);
                },
                error => {
                    console.error(LOG_PREF+'Failed to register: ' +  error);
                }
            );

            console.info(LOG_PREF+'initialized');
        }
        catch (e) {
            console.error(LOG_PREF+'Failed to load cordova plugin PushWoosh', e);
        }
    } else {
        console.info('Skipped PushWoosh registration');
    }
}

export const hasQueuedActions = () => queuedActions.length > 0;

export function middleware(store) {

    return next => action => {

        if (action.type === DATA_UPDATED) {
            isDataReady = true;

            if (queuedActions.length > 0) {
                console.info(LOG_PREF+'Emptying actions queue');

                while (queuedActions.length > 0) {
                    queuedActions.shift()();
                }
            }
        }


        const prevState = store.getState().Cordova;
        next(action);
        const state = store.getState().Cordova;

        if (prevState.status === PAUSE && state.status === ACTIVE) {
            // api android specific
            // if ( !isIOS() ) {
            //     pushwoosh.clearLocalNotification();
            // }
        }

    };
}

export function setProfileTag(value) {
    // Require cordova plugin
    if (global.isCordovaContext && config.appId && config.projectId) {
        try {
            let pushwoosh = cordova.require('pushwoosh-cordova-plugin.PushNotification');
            pushwoosh.setTags({Profile:value},
                function(status) {
                    console.info('setTags success');
                },
                function(status) {
                    console.info('setTags failed');
                }
            );
        }
        catch (e) {
            console.error(LOG_PREF+'Failed to load cordova plugin PushWoosh', e);
        }
    }
}
