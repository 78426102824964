module.exports = require('deepmerge')(require('./config.default.js'), {
  native: {
    name: {
      default: 'PISC 2019_D'
    },
    id: 'com.mobilespot.pisc.dev'
  },
  web: {
    url: 'http://pisc-dev.mobile-spot.com',
    deploy: {
      host: 'kim1',
      user: 'www',
      path: '/home/www/mobigeo/pisc'
    }
  }
});