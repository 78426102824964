
import config from 'data/config/config';

import { getDatatypeFromPage } from 'src/pages/dataToPageMapping';
import { applyCurrentUrl } from 'src/core/navigation/Router';
import { serialize } from 'src/core/util/JsTools';
import { get } from 'src/core/query/Query';
import { isIOS, getIosVersion, isWkWebview } from 'src/core/util/browser';
import { getCurrent as getCurrentLang } from 'src/core/Lang';
import { get as getCurrentProfile } from 'src/core/Profile';
import { isAndroid } from 'src/core/util/browser';

const LOG_PREF = '[History] ';


// import { createHistory } from 'history'

// HTML5 History polyfill
// let history = createHistory();
if (window.history.emulate) {
    console.log(LOG_PREF+'Polyfill in action for HTML5-History-API');
} else {
    console.log(LOG_PREF+'Native support for HTML5-History-API');
}
// let location = history.location;


export function getLocation() {
    return window.history.location || window.location;
}


/**
 * Navigate back (= pop previous history entry)
 */
export function back() {
    window.history.back();
}


/**
 * Handle when history is manipulated (navigating forward or backward)
 */
window.onpopstate = e => {
    // We define `state` when routing, so no state = no more routing history

    // On Android, if back button is pressed and history stack is empty exit then exit the app
    if (!e.state && isAndroid() && navigator.app && typeof navigator.app.exitApp === 'function') {
        navigator.app.exitApp();
        return;
    }

    // Fixme, compare history.length to determine transition type
    let transition; /* = lastHistoryLength < history.length
                        ? TRANSITION_TYPE.forward
                        : TRANSITION_TYPE.backward;*/

    applyCurrentUrl(
        transition,
        false // e.state ? false : true,
    );
};


function encodeProps(pageProps, pageKey) {
    let encoded;

    if (!pageProps || typeof pageProps !== 'object' || Object.keys(pageProps).length === 0) {
        encoded = '';
    } else {
        // Clone to avoid modifying original object
        let _props = JSON.parse(JSON.stringify(pageProps));

        // id -> originalId (allows url sharing)
        if (typeof _props.id !== 'undefined' && _props.id !== null) {

            let dataType = pageProps.dataType || getDatatypeFromPage(pageKey);
            // Can determine data type ?
            if (dataType) {

                // Get originalId value for this item
                _props.originalId = get(_props.id, dataType).original_id;
                delete _props.id;
            }
        }

        // Remove twocolumns from url
        if (typeof _props.twocolumns !== 'undefined') {
            delete _props.twocolumns;
        }

        // Add `lang` and `profile`
        _props.lang = getCurrentLang();
        _props.profile = getCurrentProfile();

        encoded = serialize(_props);
    }
    return encoded;
};

export const getNavigationIndex = () => window.history.state ? window.history.state.navigationIndex : null;

/**
 * Add an entry to browser history
 * @param  {object} page
 * @param  {object} pageProps
 */
export function push(page, pageProps) {

    // Cordova iOS 11+wkwebview: major regression on HTML5 History API support
    // @see https://bugs.webkit.org/show_bug.cgi?id=183028
    if (global.isCordovaContext && isIOS() && getIosVersion() === 11 && isWkWebview()) {
        return;
    }

    console.log(LOG_PREF+'pushToHistory', page.path, pageProps);

    // Push state only if 'pageKey' or 'page arguments' has changed (good for page refreshs)
    if (!window.history.state
            || page.key !== window.history.state.pageKey
            || JSON.stringify(pageProps) !== JSON.stringify(window.history.state.pageProps)) {

        // Encode pageProps
        let encodedOptions = encodeProps(pageProps, page.key);

        let queryString = config.ROUTE_SEPARATOR + page.path + encodedOptions;

        let navigationIndex = getNavigationIndex();
        if (typeof navigationIndex === 'number') {
            navigationIndex += 1;
        } else {
            navigationIndex = 0;
        }

        let state = {
            pageKey        : page.key,
            pageProps      : pageProps,
            navigationIndex: navigationIndex,
            queryString    : queryString,
        };
        window.history.pushState(state, `${page.key} page`, queryString);
    }
};
