import React from 'react';
import PropTypes from 'prop-types';
import { TwitterTweetEmbed } from 'react-twitter-embed';

class Tweet extends React.PureComponent {
    render() {
        const { id } = this.props

        return (
            <div className='media-twitter-post'>
                <TwitterTweetEmbed tweetId={id} />
            </div>
        );
    }

}

Tweet.propTypes = {
    id: PropTypes.string.isRequired
};

export default Tweet;
