'use strict';

var _defineProperty = require("/Users/matthieugayon/Documents/development/mobilespot/mobigeo-sdk-pisc2019/app-react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _CATEGORIES_MAPPING;

var DATA_TYPE_COUNTRIES = 'countries';
var DATA_TYPE_EVENT_CATEGORIES = 'event_categories';
var DATA_TYPE_EVENTS = 'events';
var DATA_TYPE_SPEAKERS = 'speakers';
var DATA_TYPE_EXHIBITOR_CATEGORIES = 'exhibitor_categories';
var DATA_TYPE_EXHIBITORS = 'exhibitors';
var DATA_TYPE_ICONS = 'icons';
var DATA_TYPE_ICON_CATEGORIES = 'icon_categories';
var DATA_TYPE_ICONS_CATEGORIES = 'icons_categories';
var DATA_TYPE_PLACES = 'places';
var DATA_TYPE_PLACE_MEMBERS = 'place_members'; // const DATA_TYPE_SERVICE_CATEGORIES = 'service_categories';
// const DATA_TYPE_SERVICES = 'services';

/**
 * NB: `CATEGORIES_DATA_TYPE` and `NON_CATEGORIES_DATA_TYPE` are used to
 * define lists displayed in app as WHOLE PAGES. @see ListPage, listPageReducer
 * @type {Array}
 */

var CATEGORIES_DATA_TYPE = [DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EXHIBITOR_CATEGORIES];
var NON_CATEGORIES_DATA_TYPE = [DATA_TYPE_EVENTS, DATA_TYPE_EXHIBITORS, DATA_TYPE_SPEAKERS];
var CATEGORIES_MAPPING = (_CATEGORIES_MAPPING = {}, _defineProperty(_CATEGORIES_MAPPING, DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EVENTS), _defineProperty(_CATEGORIES_MAPPING, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_EXHIBITORS), _CATEGORIES_MAPPING);
/**
 * NB: PROJECT RELATED
 */

var VALID_DATA_TYPES = [// DATA_TYPE_BRAND_CATEGORIES,
// DATA_TYPE_BRANDS,
//    DATA_TYPE_COUNTRIES,
DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EVENTS, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_EXHIBITORS, DATA_TYPE_ICONS, // DATA_TYPE_INFOS,
DATA_TYPE_PLACES, // internal
// DATA_TYPE_RESTAURANTS,
// DATA_TYPE_SERVICE_CATEGORIES,
// DATA_TYPE_SERVICES,
//    DATA_TYPE_NEWPRODUCT_CATEGORIES,
//    DATA_TYPE_NEWPRODUCTS
DATA_TYPE_SPEAKERS];
/**
 * Data types for whose `core/query/Query`.getAdditionalData() must always be called
 * @type {Array}
 *
 * NB: PROJECT RELATED
 */

var ALWAYS_QUERY_FULL = [// DATA_TYPE_EVENTS,
  // DATA_TYPE_EXHIBITORS,
  // DATA_TYPE_SERVICES,
  // DATA_TYPE_SPEAKERS
];
/**
 * NB: PROJECT RELATED
 */

var MOBIGEO_USED_TABLES = [// DATA_TYPE_AIRCRAFTS,
// DATA_TYPE_BRANDS,
DATA_TYPE_EVENTS, DATA_TYPE_EXHIBITORS, DATA_TYPE_ICONS, DATA_TYPE_PLACES];
/**
 * e.g input `DATA_TYPE_EVENTS` returns `DATA_TYPE_EVENT_CATEGORIES`
 *     input `DATA_TYPE_EVENT_CATEGORIES` returns `DATA_TYPE_EVENT_CATEGORIES`
 * @param  {string} dataType
 * @return {string}
 */

function getCategoryDatatype(dataType) {
  var categoryDataType;

  if (dataType) {
    if (CATEGORIES_DATA_TYPE.indexOf(dataType) !== -1) {
      // Current dataType is already a category type
      categoryDataType = dataType;
    } else {
      // Get category dataType from current dataType
      Object.keys(CATEGORIES_MAPPING).forEach(function (categoryType) {
        if (CATEGORIES_MAPPING[categoryType] === dataType) {
          categoryDataType = categoryType;
        }
      });
    }
  }

  if (!categoryDataType) {
    // Should not happen
    console.warn('Cannot determine category data type for: ' + dataType);
  }

  return categoryDataType;
}

;
/**
 * Convert app-react dataType to MobiGeo dataType (capitalized singular)
 * @param  {string} dataType
 * @return {string}
 */

function convertDataTypeToMobigeoType(dataType) {
  return dataType.slice(0, 1).toUpperCase() + dataType.slice(1, dataType.length - 1);
}
/**
 * Convert MobiGeo dataType to app-react dataType (lowercased pluralized)
 * @param  {string} dataType
 * @return {string}
 */


function convertMobigeoType(type) {
  return type.toLowerCase().concat('s');
}

function getSpeakerTitle(speaker) {
  var value = '';

  if (speaker) {
    if (speaker.civility) {
      value += speaker.civility + ' ';
    }

    if (speaker.firstname) {
      value += speaker.firstname + ' ';
    }

    if (speaker.lastname) {
      value += speaker.lastname;
    }
  }

  return value;
}

function getSpeakerSubtitle(speaker) {
  var value = '';

  if (speaker) {
    if (speaker.organisation) {
      value += speaker.organisation + '<br />';
    }

    if (speaker.position) {
      value += speaker.position + '<br />';
    }

    if (speaker.lump && speaker.lump.type) {
      value += speaker.lump.type;
    }
  }

  return value;
}

module.exports = {
  DATA_TYPE_COUNTRIES: DATA_TYPE_COUNTRIES,
  DATA_TYPE_EVENT_CATEGORIES: DATA_TYPE_EVENT_CATEGORIES,
  DATA_TYPE_EVENTS: DATA_TYPE_EVENTS,
  DATA_TYPE_SPEAKERS: DATA_TYPE_SPEAKERS,
  DATA_TYPE_EXHIBITOR_CATEGORIES: DATA_TYPE_EXHIBITOR_CATEGORIES,
  DATA_TYPE_EXHIBITORS: DATA_TYPE_EXHIBITORS,
  DATA_TYPE_ICONS: DATA_TYPE_ICONS,
  DATA_TYPE_PLACES: DATA_TYPE_PLACES,
  // DATA_TYPE_SERVICE_CATEGORIES,
  // DATA_TYPE_SERVICES,
  VALID_DATA_TYPES: VALID_DATA_TYPES,
  CATEGORIES_DATA_TYPE: CATEGORIES_DATA_TYPE,
  NON_CATEGORIES_DATA_TYPE: NON_CATEGORIES_DATA_TYPE,
  CATEGORIES_MAPPING: CATEGORIES_MAPPING,
  ALWAYS_QUERY_FULL: ALWAYS_QUERY_FULL,
  MOBIGEO_USED_TABLES: MOBIGEO_USED_TABLES,
  convertDataTypeToMobigeoType: convertDataTypeToMobigeoType,
  convertMobigeoType: convertMobigeoType,
  getCategoryDatatype: getCategoryDatatype,
  getSpeakerTitle: getSpeakerTitle,
  getSpeakerSubtitle: getSpeakerSubtitle
};